import {
  Box,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TextField,
  makeStyles,
  withStyles
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getListVehicle,
  getListVehicleTracking,
  getListVehicleTrackingById,
  getListVehicleTrackingByUserId,
  getVehicleLicensePlate,
  resetListVehicle
} from 'src/features/vehicleSlice';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { roles } from 'src/app/constant/roles';
import { getListAgencies } from 'src/features/agencySlice';
import { getListDeviceType } from 'src/features/deviceTypeSlice';
import { useQuery } from '../hooks/useQuery';
import { Navigate, useNavigate } from 'react-router';
import { _convertObjectToQuery } from '../utils/apiService';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    width: 365,
    boxShadow: 'none',
    padding: '0 16px 0 0'
  },
  input: {
    flex: 1,
    fontSize: '10px'
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  titleLotrinh: {
    fontSize: '18px',
    color: '#D9042F',
    fontWeight: 700,
    marginBottom: '10px'
  },
  titleTime: {
    fontSize: '18px',
    color: '#0C1132',
    fontWeight: 700,
    marginBottom: '10px'
  },
  formControl: {
    height: '60px',
    marginTop: '10px',
    width: '360px',
    marginBottom: '10px'
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectDropdown: {
    padding: '5 5 5 5 '
  },
  dropDown: {
    width: '98%'
  },

  option: {
    fontSize: '14px'
  }
}));

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: '#D6D9DB'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D6D9DB'
      },
      '&:hover fieldset': {
        borderColor: '#D9042F'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#D9042F'
      }
    }
  }
})(TextField);

export default function ToolBar({ setShowMenu, setDeviceType, deviceType }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState({
    license_plate: '',
    // device_type: deviceType || '',
    // agency_id: ''
  });

  const defaultFilter = {
    device_type_id : query.device_type || '',
    agency_id : query.agency_id || '',
  };

  const [filterFormValue, setFilterFormValue] = useState(defaultFilter);

  const listDeviceType = useSelector(
    state => state.deviceTypeSlice.listDeviceType
  );
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const listLicensePlate = useSelector(
    state => state.vehicleSlice.listLicensePlate
  );

  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.AGENCY;
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  useEffect(() => {
    if (isAdmin) {
      dispatch(getVehicleLicensePlate(''));
    } else if (isAgency) {
      dispatch(getVehicleLicensePlate(`/${agencyID}`));
    }
  }, [isAdmin, isAgency]);

  // Get list device type
  useEffect(() => {
    dispatch(getListDeviceType());
  }, []);

  useEffect(() => {
    if (isAdmin) dispatch(getListAgencies({page_size: 1000}));
  }, [isAdmin]);

  useEffect(() => {
    return () => {
      dispatch(resetListVehicle());
    };
  }, []);

  const handleSearchDevice = (e, value) => {
    const queryValue = Object.assign({}, searchQuery, {
      license_plate: value ? value : e.target.value
    });
    setSearchQuery(queryValue);
    if (isAdmin) {
      dispatch(
        getListVehicle({ license_plate: value ? value : e.target.value })
      );
    } else if (isAgency) {
      dispatch(
        getListVehicle({
          license_plate: value ? value : e.target.value,
          agency_id: agencyID
        })
      );
    }
  };

  const handleChangeInput = (e, value) => {
    e.preventDefault();
    const queryValue = Object.assign({}, searchQuery, {
      license_plate: value ? value : e.target.value
    });
    setSearchQuery(queryValue);
  };

  const keyPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const queryValue = Object.assign({}, searchQuery, {
        license_plate: e.target.value
      });
      setSearchQuery(queryValue);
      if (isAdmin) {
        dispatch(getListVehicle({ license_plate: e.target.value }));
      } else if (isAgency) {
        dispatch(
          getListVehicle({ license_plate: e.target.value, agency_id: agencyID })
        );
      }
    }
  };

  useEffect(() => {
    const {agency_id, device_type_id} = filterFormValue;
  
    if(device_type_id && !agency_id){
      navigate(`/app/tracking?device_type_id=${device_type_id}`);
    }
    if(agency_id && !device_type_id){
      navigate(`/app/tracking?agency_id=${agency_id}`);
    }
    if(!agency_id && !device_type_id){
      navigate(`/app/tracking`);
    }

    if(device_type_id && agency_id){
      navigate(`/app/tracking?${_convertObjectToQuery(filterFormValue)}`);
    }
  }, [filterFormValue]);

  const handleChangeAgency = e => {
    const filterValue = Object.assign({}, filterFormValue, {
      agency_id: e.target.value
    });
    dispatch(getListVehicleTracking({agency_id: e.target.value}))
    setFilterFormValue(filterValue);
  };

  const handleChangeDeviceType = e => {
    const filterValue = Object.assign({}, filterFormValue, {
      device_type_id: e.target.value
    });

    if(isAdmin){
      dispatch(getListVehicleTracking({device_type_id: e.target.value}));
    }else if(isAgency){
      dispatch(getListVehicleTrackingById({
        id: agencyID,
        data : {
          device_type_id: e.target.value
        }
      }));
    }else{
      dispatch(getListVehicleTrackingByUserId({
        id: dataLogin.endUser.id,
        data : {
          device_type_id: e.target.value
        }
      }));
    }
    setFilterFormValue(filterValue);
  };

  return (
    <Paper component="form" className={classes.root} style={{ marginTop: 0 }}>
      <Box
        style={{
          borderRadius: '4px',
          marginBottom: '8px'
        }}
      >
        {listLicensePlate && (
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            size="small"
            disableClearable
            options={listLicensePlate}
            inputValue={searchQuery.license_plate}
            onChange={(e, value) => handleChangeInput(e, value)}
            classes={{ option: classes.option }}
            renderInput={params => (
              <CssTextField
                {...params}
                margin="dense"
                className="input-no-fieldset"
                variant="outlined"
                placeholder="Tìm kiếm thiết bị"
                onChange={handleChangeInput}
                onKeyDown={keyPress}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: '14px'
                  },
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon onClick={handleSearchDevice} />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        )}
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '8px'
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            borderColor: '#E5E5E8',
            borderWidth: '1px',
            borderStyle: 'solid',
            padding: '0.2em 0.7em',
            borderRadius: '5px'
          }}
        >
          <Select
            style={{ width: '100%' }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={filterFormValue.device_type_id || ''}
            onChange={e => handleChangeDeviceType(e)}
            displayEmpty
            disableUnderline
          >
            <MenuItem value=''>
              <small> Tất cả thiết bị </small>
            </MenuItem>
            {listDeviceType &&
              listDeviceType.map(deviceType => (
                <MenuItem value={deviceType.id}>
                  <small>{deviceType.name}</small>
                </MenuItem>
              ))}
          </Select>
        </Box>
        {isAdmin && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              borderColor: '#E5E5E8',
              borderWidth: '1px',
              borderStyle: 'solid',
              padding: '0.2em 0.7em',
              borderRadius: '5px'
            }}
          >
            <Select
              style={{ width: '100%' }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={''}
              value={filterFormValue.agency_id || ''}
              onChange={e => handleChangeAgency(e)}
              displayEmpty
              disableUnderline
            >
              <MenuItem value=''>
                <small> Chọn đại lý </small>
              </MenuItem>
              {listAgencies &&
                listAgencies.map(agency => (
                  <MenuItem value={agency.id}>
                    <small>{agency.name}</small>
                  </MenuItem>
                ))}
            </Select>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
