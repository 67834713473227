import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { STATUS_API } from 'src/app/constant/config';
import { showLoading } from 'src/features/videoSlice';
import VehicleTablecell from '../tables/VehicleTablecell';
import './style.css';
import { useQuery } from 'src/app/hooks/useQuery';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    cursor: 'pointer'
  }
}))(TableRow);

// Man streaming

const VehicleTable = ({ getVehicle, getDriverInfo, deviceType, carStatus, listVehicle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const [rowActive, setRowActive] = useState(query.get('device_serial') || null);
  const statusGetListVehicle = useSelector(state => state.vehicleSlice.statusGetListVehicle);
  
  const handleRowClick = vehicle => {
    getVehicle(vehicle);
    getDriverInfo(vehicle)
    setRowActive(vehicle.device_serial);
    dispatch(showLoading());
    navigate(`/app/camera/streaming?device_serial=${vehicle.device_serial}&show_menu=true`);
  };

  const renderBody = listVehicle?.length > 0 ? 
  listVehicle?.map((row, index) => {
    const renderRow = () => (
      <StyledTableRow
        className={row.device_serial === rowActive ? 'row-actived' : ''}
        key={row.vehicle_id ?? row.id ?? index + 1}
        onClick={() => handleRowClick(row)}
      >
        <VehicleTablecell row={row} index={index} rowActive={rowActive}/>
        <TableCell align="center">{Math.floor(row.speed_gps) || 0}</TableCell>
        <TableCell align="center">
          {row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}
        </TableCell>
      </StyledTableRow>
    );
  
    const shouldRenderRow = () => {
      if (carStatus && !deviceType) return carStatus === row.device_status;
      if (!carStatus && deviceType) return deviceType === row.device_type?.name;
      if (carStatus && deviceType)
        return carStatus === row.device_status && deviceType === row.device_type?.name;
      return true;
    };
  
    if (shouldRenderRow()) {
      return <TableBody>{renderRow()}</TableBody>;
    }
    return null; 
  })
  
  : <caption> Không có thiết bị </caption>

  return (
    <div>
      <TableContainer component={Paper} className="vehicle_table height_table">
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            style={{ background: '#D9042F !important', color: 'white !important' }}
          >
            <TableRow>
              <TableCell align="center">Biển số</TableCell>
              <TableCell align="center">Vận tốc (Km/h) </TableCell>
              <TableCell align="center">Thời gian</TableCell>
            </TableRow>
          </TableHead>
        
         {
            statusGetListVehicle === STATUS_API.PENDING ? 
            <caption >
              <div style={{ textAlign: 'center', padding: 20 }}> <CircularProgress /></div>
            </caption>
            :
            renderBody 
          }
        </Table>
      </TableContainer >
    </div>
  );
};

export default React.memo(VehicleTable);
