import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  SvgIcon,
  TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'src/app/theme';

const Toolbar = ({
  className,
  querySearch,
  searchRef,
  clearSearchRef,
  createNewDeviceRef,
  isLoading,
  setShowResult,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    license_plate: querySearch.name || '',
    start_date:
      querySearch.start_date ||
      moment()
        .subtract(7, 'd')
        .format('YYYY-MM-DDTHH:mm'),
    end_date: querySearch.end_date || moment().format('YYYY-MM-DDTHH:mm')
    // status: ''
  });

  const listLicensePlateCamera = useSelector(
    state => state.vehicleSlice.listLicensePlateCamera
  );

  const onEnterSearchInput = (event, value) => {
    if (!searchRef || query?.license_plate === '') return;
    if (event.keyCode === 13) {
      const queryValue = Object.assign({}, query, {
        license_plate: value ? value : event.target.value,
        page: 1
      });
      setQuery(queryValue);
      searchRef(queryValue);
      setShowResult(true);
    }
  };

  const changeTextInputSearch = (event, value) => {
    const queryValue = Object.assign({}, query, {
      license_plate: value ? value : event.target.value,
      page: 1
    });
    setQuery(queryValue);
  };

  const changeStartDate = event => {
    const queryValue = Object.assign({}, query, {
      start_date: event.target.value,
      page: 1
    });
    setQuery(queryValue);
  };

  const changeEndDate = event => {
    const queryValue = Object.assign({}, query, {
      end_date: event.target.value,
      page: 1
    });
    setQuery(queryValue);
  };

  const searchDevice = () => {
    if (!searchRef) {
      return;
    }
    searchRef(query);
    setShowResult(true);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box className={classes.groupSearch}>
        <div className={classes.groupSearchLeft}>
          <div className={classes.wrapper}>
            <div style={{ marginBottom: '10px', fontSize: '14px' }}>
              Chọn phương tiện
            </div>
            {listLicensePlateCamera && (
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                size="small"
                disableClearable
                options={listLicensePlateCamera}
                inputValue={query?.license_plate}
                onChange={changeTextInputSearch}
                classes={{ option: classes.option }}
                renderInput={params => (
                  <TextField
                    {...params}
                    // margin="dense"
                    required={true}
                    className={classes.searchInput}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: '14px'
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                    placeholder="Nhập tên để tìm kiếm ..."
                    onKeyDown={onEnterSearchInput}
                    onChange={changeTextInputSearch}
                  />
                )}
              />
            )}
          </div>
          <div className={classes.wrapper}>
            <div style={{ marginBottom: '10px', fontSize: '14px' }}>
              Từ ngày
            </div>
            <TextField
              size="small"
              style={{ margin: 0 }}
              fullWidth
              margin="normal"
              name="effective_date"
              onChange={changeStartDate}
              value={query.start_date}
              type="datetime-local"
              variant="outlined"
              InputProps={{
                style: {
                  fontSize: '14px'
                }
              }}
            />
          </div>
          <div className={classes.wrapper}>
            <div style={{ marginBottom: '10px', fontSize: '14px' }}>
              Đến ngày
            </div>
            <TextField
              size="small"
              style={{ margin: 0 }}
              fullWidth
              margin="normal"
              name="effective_date"
              onChange={changeEndDate}
              value={query.end_date}
              type="datetime-local"
              variant="outlined"
              InputProps={{
                style: {
                  fontSize: '14px'
                }
              }}
            />
          </div>
          <div className={classes.wrapper}>
            <Button
              className={classes.styleInputSearch}
              style={{
                background: theme.palette.primary.main,
                color: '#fff',
                paddingLeft: '30px',
                paddingRight: '30px',
                textTransform: 'inherit',
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              variant="contained"
              size="small"
              onClick={() => searchDevice()}
            >
              {!isLoading ? <span>Tìm kiếm ảnh </span> : 'Đang tìm kiếm'}
            </Button>
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  root: { marginTop: '20px', marginBottom: '20px' },
  importButton: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  groupSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflowX: 'scroll',
    minWidth: '50%'
  },
  groupSearchLeft: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  searchInput: {
    width: '400px'
  },
  styleInputSearch: {
    height: '40px'
  },
  exportBtn: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  shadowBox: {
    boxShadow: '0 2px 5px rgba(0,0,0,.18)'
  },
  option: {
    fontSize: '14px'
  }
}));

export default Toolbar;
