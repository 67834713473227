import { Box, Drawer, Hidden, List, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NavItem from './NavItem';
import { roles } from 'src/app/constant/roles';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 280
  },
  desktopDrawer: {
    width: 256,
    top: 46,
    height: 'calc(100% - 46px)',
    marginTop: '20px'
  },
  avatar: {
    cursor: 'pointer',
    width: 46,
    height: 46
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Helvetica',
    fontSize: '14px',
    color: '#45485E',
    marginBottom: '10px'
  },
  nested: {
    paddingLeft: 25
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.AGENCY;
  const isUser =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ENDUSER;
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const menuStatistics = [
    {
      href: `/app/manage/statistics/warehouse-transfer`,
      title: 'Thống kê chuyển kho'
    },
    {
      href: `/app/manage/statistics/active`,
      title: 'Thống kê kích hoạt'
    },
    {
      href: `/app/manage/statistics/service`,
      title: 'Thống kê dịch vụ'
    }
  ];

  const menuDevices = isAdmin
    ? [
        {
          href: `/app/manage/service-packages`,
          title: 'Quản lý gói dịch vụ'
        },
        {
          href: `/app/manage/vehicle-types`,
          title: 'Quản lý loại phương tiện'
        },
        {
          href: `/app/manage/devices`,
          title: 'Quản lý thiết bị'
        },
        {
          href: `/app/manage/versions`,
          title: 'Quản lý phiên bản thiết bị'
        },
        {
          href: `/app/manage/sim`,
          title: 'Quản lý sim'
        }
      ]
    : [
        {
          href: `/app/manage/devices`,
          title: 'Quản lý thiết bị'
        }
      ];
  const menuAdvertisement = [
    {
      href: `/app/manage/broadcasts-schedules`,
      title: 'Quản lý lịch phát'
    },
    {
      href: `/app/manage/files-audio`,
      title: 'Quản lý file-audio'
    },
    {
      href: `/app/manage/files-video`,
      title: 'Quản lý file-video'
    }
  ];

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box style={{ paddingTop: '16px' }}>
        <List>
          {!isUser && (
            <NavItem
              icon={`/static/images/bcdn/baocao_dn_tonghop_theonhom.svg`}
              title="Thống kê"
              arrayNested={menuStatistics}
            />
          )}
          {isAdmin && (
            <Box className={classes.menuItem}>
              <NavItem
                title="Quản trị người dùng"
                href="/app/manage/users"
                icon={'/static/images/administration/quan_tri_nguoi_dung.svg'}
              />
            </Box>
          )}
          {isAdmin && (
            <Box className={classes.menuItem}>
              <NavItem
                title="Quản trị đại lý"
                href="/app/manage/agencies"
                icon={'/static/images/administration/quan_tri_dai_ly.svg'}
              />
            </Box>
          )}
          {isAdmin || isAgency && (
            <Box className={classes.menuItem}>
              <NavItem
                title="Quản trị nhân viên"
                href="/app/manage/service-staffs"
                icon={'/static/images/administration/quan_tri_nhan_vien.svg'}
              />
            </Box>
          )}
          <NavItem
            icon={'/static/images/administration/quan_tri_thiet_bi.svg'}
            title="Quản trị thiết bị"
            arrayNested={menuDevices}
          />

          {!isAdmin && (
            <NavItem
              icon={'/static/images/nav-baocaolaixequa4h.svg'}
              title="Quản trị lái xe"
              arrayNested={[
                {
                  href: `/app/manage/driver`,
                  title: 'Danh sách lái xe'
                }
              ]}
            />
          )}
          {isAdmin && (
            <NavItem
              icon={'/static/images/administration/quan_tri_quang_cao.svg'}
              title="Quản trị quảng cáo"
              arrayNested={menuAdvertisement}
            />
          )}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
