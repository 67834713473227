import { makeStyles, Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import ChartStatistic from 'src/app/components/ChartStatistic/ChartStatistic';
import ToolBarStatistic from 'src/app/components/ToolBarStatistic/ToolBarStatistic';
import StatisticWarehouseTable from './StatisticTable';
import { getStatisticWarehouseTransfer, postExportStatisticTransfer } from 'src/features/statisticSlice';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { useQuery } from 'src/app/hooks/useQuery';
import { _convertObjectToQuery } from 'src/app/utils/apiService';

const StatisticWarehouseTransfer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const query = useQuery();
  const [params, setParams] = useState({
    page: 1,
    page_size: PAGE_SIZE_LIST
  });

  const listDeviceWarehouseTransfer = useSelector(
    state => state.statisticSlice.listDeviceWarehouseTransfer
  );
  const monthlyStatisticsTrans = useSelector(
    state => state.statisticSlice.monthlyStatisticsTrans
  );
  const yearlyStatisticsTrans = useSelector(
    state => state.statisticSlice.yearlyStatisticsTrans
  );
  const totalTransfer = useSelector(
    state => state.statisticSlice.totalTransfer
  );

  
  const page = query.get('page') || 1;
  const agencyId = query.get('agency_id') || undefined;
  const deviceTypeId = query.get('device_type_id') || undefined;
  const timeToChart = query.get('time_to_chart') || undefined;
  const timeToSearch = query.get('time_to_search') || undefined;
  
  const dataStatistics = timeToChart ? monthlyStatisticsTrans : yearlyStatisticsTrans;

  useEffect(() => {
    const queryValue = {
      ...params,
      page: page ? page : undefined,
      agency_id: agencyId ? agencyId : undefined,
      device_type_id: deviceTypeId ? deviceTypeId : undefined,
      time_to_chart: timeToChart ? timeToChart : undefined,
      time_to_search: timeToSearch ? timeToSearch : undefined
    };
    setParams(queryValue);
    dispatch(getStatisticWarehouseTransfer(queryValue));
    console.log('queryValue', queryValue);
  }, [query]);

  const getListDeviceTransferWithParams = data => {
    navigate('/app/manage/statistics/warehouse-transfer?' + _convertObjectToQuery(data));
    dispatch(getStatisticWarehouseTransfer(data));
  };

  const exportStatistic = (data) => {
    dispatch(postExportStatisticTransfer(data));
  };

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: '98%', margin: '0 auto' }}>
          <Box
            style={{
              backgroundColor: '#ffffff',
              borderRadius: '5px',
              padding: '0px 10px 5px'
            }}
          >
            <ChartStatistic
              dataStatistics={dataStatistics}
              chartTitle={'Thống kê mua bán'}
              labelChart={'Thiết bị'}
              searchRef={getListDeviceTransferWithParams}
            />
          </Box>
          <Box marginTop={'20px'}>
            <ToolBarStatistic
              devicesCount={`${totalTransfer || 0} thiết bị`}
              getListDeviceRef={getListDeviceTransferWithParams}
              exportStatistic = {exportStatistic}
              isTransfer={true}
            />
          </Box>
          <Box marginTop={'10px'}>
            <StatisticWarehouseTable
              listDeviceTransfer={listDeviceWarehouseTransfer}
              totalTransfer={totalTransfer}
              getListDeviceTransferRef={getListDeviceTransferWithParams}
              params={params}
              setParams={setParams}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    width: '100%',
    marginBottom: 30
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    //height: '100%',
    overflow: 'auto'
  }
}));

export default StatisticWarehouseTransfer;
