export const MESSAGE = {
  //user
  CHANGE_STATUS_USER_SUCCESS: 'Thay đổi trạng thái tài khoản thành công!',
  CHANGE_STATUS_USER_FAIL: 'Thay đổi trạng thái tài khoản thất bại!',
  CHANGE_ROLE_USER_SUCCESS: 'Thay đổi quyền hạn tài khoản thành công!',
  CHANGE_ROLE_USER_FAIL: 'Thay đổi quyền hạn tài khoản thất bại!',

  //UPDATE_USER_SUCCESS: 'Cập nhật tài khoản thành công!',
  UPDATE_USER_FAIL: 'Cập nhật tài khoản thất bại!',
  CREATE_USER_FAIL: 'Tạo mới tài khoản thất bại!',
  //CREATE_USER_SUCCESS: 'Tạo mới tài khoản thành công!',

  // #region device
  CONFIRM_DELETE_DEVICE: 'Xác nhận xóa thiết bị',
  CONTENT_DELETE_DEVICE: 'Bạn có chắc chắn muốn xóa thiết bị này không?',
  DELETE_DEVICE_SUCCESS: 'Xóa thiết bị thành công.',
  DELETE_DEVICE_FAIL: 'Xóa thiết bị thất bại',
  UPDATE_DEVICE_SUCCESS: 'Cập nhật thiết bị thành công',
  CREATE_DEVICE_SUCCESS: 'Tạo mới thiết bị thành công',
  ACTIVE_DEVICE_SUCCESS: 'Kích hoạt thiết bị thành công',
  RECALL_DEVICE_SUCCESS: 'Thu hồi thiết bị thành công',
  RECALL_DEVICE_SUCCESS: 'Thu hồi thiết bị thành công',
  EXTEND_DEVICE_SUCCESS: 'Gia hạn gói cước thành công',
  EXTEND_DEVICE_FAIL: 'Gia hạn gói cước thất bại',
  TRANSFER_DEVICE_SUCCESS: 'Chuyển thiết bị xuống đại lý thành công',
  IMPORT_DEVICE_SUCCESS: 'Thêm thiết bị từ File thành công',

  // commom
  BTN_YES: 'Đồng ý',
  BTN_CANCEL: 'Hủy',

  // #region vehicle
  CONFIRM_DELETE_VEHICLE: 'Bạn có muốn xóa phương tiện này?',
  DELETE_VEHICLE_SUCCESS: 'Xóa phương tiện thành công.',
  DELETE_VEHICLE_FAIL: 'Xóa phương tiện thất bại',
  UPDATE_VEHICLE_SECCESS: 'Cập nhật phương tiện thành công',
  CREATE_VEHICLE_SECCESS: 'Tạo mới phương tiện thành công',

  // #region agency
  CREATE_AGENCY_SUCCESS: 'Tạo mới đại lý thành công!',
  UPDATE_AGENCY_SUCCESS: 'Cập nhật đại lý thành công!',
  WAREHOUSE_TRANSFER_SUCCESS: 'Chuyển tất cả thiết bị xuống đại lý thành công!',
  CONFIRM_DELETE_AGENCY: 'Xác nhận xóa đại lý',
  DELETE_AGENCY_FAIL: 'Xóa đại lý thất bại!',
  DELETE_AGENCY_SUCCESS: 'Xóa đại lý thành công.',
  CONTENT_DELETE_AGENCY: 'Bạn có chắc chắn muốn xóa đại lý này không ?',

  // #region staff
  UPDATE_STAFF_SUCCESS: 'Cập nhật nhân viên thành công',
  CREATE_STAFF_SUCCESS: 'Tạo mới nhân viên thành công',
  CONFIRM_DELETE_STAFF: 'Xác nhận xóa nhân viên',
  DELETE_STAFF_SUCCESS: 'Xóa nhân viên thành công.',
  DELETE_STAFF_FAIL: 'Xóa nhân viên thất bại',
  CONTENT_DELETE_STAFF: 'Bạn có chắc chắn muốn xóa nhân viên này không ?',

  // #region user
  UPDATE_USER_SUCCESS: 'Cập nhật người dùng thành công',
  CREATE_USER_SUCCESS: 'Tạo mới người dùng thành công',
  CONFIRM_DELETE_USER: 'Xác nhận xóa người dùng',
  DELETE_USER_SUCCESS: 'Xóa người dùng thành công.',
  DELETE_USER_FAIL: 'Xóa người dùng thất bại',
  CONFIRM_DELETE_GROUP_DEVICE: 'Bạn có muốn xóa nhóm thiết bị không',
  CONTENT_DELETE_USER: 'Bạn có chắc chắn muốn xóa người dùng này không ?',

  // #region drivers
  UPDATE_DRIVER_SUCCESS: 'Cập nhật lái xe thành công',
  CREATE_DRIVER_SUCCESS: 'Tạo mới lái xe thành công',
  CONFIRM_DELETE_DRIVER: 'Xác nhận xóa lái xe',
  DELETE_DRIVER_SUCCESS: 'Xóa lái xe thành công.',
  DELETE_DRIVER_FAIL: 'Xóa lái xe thất bại',
  CONTENT_DELETE_DRIVER: 'Bạn có chắc chắn muốn xóa lái xe này không',

  // #region vehicle type
  UPDATE_VEHICLETYPE_SUCCESS: 'Cập nhật loại phương tiện thành công',
  CREATE_VEHICLETYPE_SUCCESS: 'Tạo mới loại phương tiện thành công',
  CONFIRM_DELETE_VEHICLETYPE: 'Xác nhận xóa loại phương tiện',
  DELETE_VEHICLETYPE_SUCCESS: 'Xóa loại phương tiện thành công.',
  DELETE_VEHICLETYPE_FAIL: 'Xóa loại phương tiện thất bại',
  CONTENT_DELETE_VEHICLETYPE: 'Bạn có chắc chắn muốn xóa loại phương tiện này không ?',

  // #region version device
  UPDATE_VERSIONDEVICE_SUCCESS: 'Cập nhật phiên bản thiết bị thành công',
  CREATE_VERSIONDEVICE_SUCCESS: 'Tạo mới phiên bản thiết bị thành công',
  CONFIRM_DELETE_VERSIONDEVICE: 'Xóa phiên bản thiết bị ?',
  DELETE_VERSIONDEVICE_SUCCESS: 'Xóa phiên bản thiết bị thành công.',
  DELETE_VERSIONDEVICE_FAIL: 'Xóa phiên bản thiết bị thất bại',
  CONTENT_DELETE_VERSIONDEVICE: 'Bạn có chắc chắn muốn xóa phiên bản thiết bị này không ?',

  // #region service package
  UPDATE_SERVICEPACKAGE_SUCCESS: 'Cập nhật gói dịch vụ thành công',
  CREATE_SERVICEPACKAGE_SUCCESS: 'Tạo mới gói dịch vụ thành công',
  CONFIRM_DELETE_SERVICEPACKAGE: 'Xác nhận xóa gói dịch vụ',
  DELETE_SERVICEPACKAGE_SUCCESS: 'Xóa gói dịch vụ thành công.',
  DELETE_SERVICEPACKAGE_FAIL: 'Xóa gói dịch vụ thất bại',
  CONTENT_DELETE_SERVICEPACKAGE: 'Bạn có chắc chắn muốn xóa gói dịch vụ này không ?',

  // #region sim
  UPDATE_SIM_SUCCESS: 'Cập nhật sim thành công',
  CREATE_SIM_SUCCESS: 'Tạo mới sim thành công',
  CONFIRM_DELETE_SIM: 'Xác nhận xóa sim',
  DELETE_SIM_SUCCESS: 'Xóa sim thành công.',
  DELETE_SIM_FAIL: 'Xóa sim thất bại',
  CONTENT_DELETE_SIM: 'Bạn có chắc chắn muốn xóa sim này không',
  IMPORT_SIM_SUCCESS: 'import thành công',
  IMPORT_SIM_FAIL:  'import thất bại'
};
