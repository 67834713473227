import { createSlice } from '@reduxjs/toolkit';
import { HTTP_GETTYPE, STATUS_API } from 'src/app/constant/config';
import AxiosAdapter from './AxiosAdapter';

export const getStatisticDeviceActive = AxiosAdapter.GetHttp(
  'statisticSlice/getStatisticDeviceActive',
  '/devices/statistic/active',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const getStatisticServices = AxiosAdapter.GetHttp(
  'statisticSlice/getStatisticServices',
  '/devices/statistic/service_fee',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const getStatisticWarehouseTransfer = AxiosAdapter.GetHttp(
  'statisticSlice/getStatisticWarehouseTransfer',
  '/devices/statistic/transfer',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const postExportStatisticTransfer = AxiosAdapter.HttpPostExportFile(
  'statisticSlice/postExportStatisticTransfer',
  '/excel/statistic/transfer/export'
);

export const postExportStatisticActive = AxiosAdapter.HttpPostExportFile(
  'statisticSlice/postExportStatisticActive',
  '/excel/statistic/active/export'
);

export const postExportStatisticService = AxiosAdapter.HttpPostExportFile(
  'statisticSlice/postExportStatisticService',
  '/excel/statistic/service/export'
);

export const statisticSlice = createSlice({
  name: 'statisticSlice',
  initialState: {
    listDeviceActive: [],
    listDeviceWarehouseTransfer: [],
    listServiceStatistic: [],

    monthlyStatisticsTrans: [],
    yearlyStatisticsTrans: [],

    monthlyStatisticsActive: [],
    yearlyStatisticsActive: [],

    monthlyStatisticsService: [],
    yearlyStatisticsService: [],

    statusGetStatisticDeviceActive: null,
    errorsStatisticActive: null,
    isLoading: false,

    statusGetWarehouseTransfer: null,

    statusGetServiceStatistic: null,
    errorGetServiceStatistic: null,
    errorGetDeviceWarehouseTransfer: null,

    totalTransfer: 0,
    totalActive: 0,
    totalService: 0,
    totalPaidCost: 0,
    

    statusExport: null,
    errorExport: null
  },

  reducers: {
    resetChange: state => {
      state.statusGetWarehouseTransfer = null;
    }
  },

  extraReducers: {
    [getStatisticDeviceActive.pending]: state => {
      state.statusGetStatisticDeviceActive = STATUS_API.PENDING;
      state.isLoading = true;
    },

    [getStatisticDeviceActive.fulfilled]: (state, action) => {
      const deviceOfYears = (action.payload.payload.yearlyStatistics || []).map(item => item?.count);
      state.yearlyStatisticsActive = deviceOfYears;
      state.statusGetStatisticDeviceActive = STATUS_API.SUCCESS;
      state.listDeviceActive = action.payload.payload.result.data;
      state.totalActive = action.payload.payload.total;
      state.monthlyStatisticsActive = action.payload.payload.result.monthlyStatistics;
      state.isLoading = false;
    },

    [getStatisticDeviceActive.rejected]: (state, action) => {
      state.statusGetStatisticDeviceActive = STATUS_API.ERROR;
      state.errorsStatisticActive = action.payload?.message || action.error;
      state.isLoading = false;
    },

    [getStatisticServices.pending]: state => {
      state.statusGetServiceStatistic = STATUS_API.PENDING;
      state.isLoading = true;
    },

    [getStatisticServices.fulfilled]: (state, action) => {
      const moneyOfYears = (action.payload.yearlyStatistics || []).map(item => item?.sum);
      state.yearlyStatisticsService = moneyOfYears;
      state.statusGetServiceStatistic = STATUS_API.SUCCESS;
      state.listServiceStatistic = action.payload.payload.data;
      state.totalService = action.payload.total;
      state.totalPaidCost = action.payload.totalPaidCost;
      state.monthlyStatisticsService =
        action.payload.payload.monthlyStatistics;
      state.isLoading = false;
    },
    [getStatisticServices.rejected]: (state, action) => {
      state.statusGetServiceStatistic = STATUS_API.ERROR;
      state.errorGetServiceStatistic = action.payload?.message || action.error;
      state.isLoading = false;
    },

    [getStatisticWarehouseTransfer.pending]: state => {
      state.statusGetWarehouseTransfer = STATUS_API.PENDING;
      state.isLoading = true;
    },

    [getStatisticWarehouseTransfer.fulfilled]: (state, action) => {
      const deviceOfYears = (action.payload.yearlyStatistics || []).map(item => item?.count);
      state.statusGetWarehouseTransfer = STATUS_API.SUCCESS;
      state.listDeviceWarehouseTransfer = action.payload.payload.data;
      state.monthlyStatisticsTrans =
        action.payload.payload.monthlyStatistics;
      state.totalTransfer = action.payload.total;
      state.yearlyStatisticsTrans = deviceOfYears;
      state.totalTransfer = action.payload.total;
      state.isLoading = false;
    },

    [getStatisticWarehouseTransfer.rejected]: (state, action) => {
      state.statusGetWarehouseTransfer = STATUS_API.ERROR;
      state.errorGetDeviceWarehouseTransfer =
        action.payload?.message || action.error;
      state.isLoading = false;
    },

    [postExportStatisticTransfer.pending]: state => {
      state.statusExport = STATUS_API.PENDING;
    },

    [postExportStatisticTransfer.fulfilled]: (state, action) => {
      state.statusExport = STATUS_API.SUCCESS;
    },

    [postExportStatisticTransfer.rejected]: (state, action) => {
      state.statusExport = STATUS_API.ERROR;
      state.errorExport = action.payload?.message || action.error;
    }
  }
});
export const { resetChange } = statisticSlice.actions;

export default statisticSlice.reducer;
