import { makeStyles } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../NavBar';
import { useDispatch, useSelector } from 'react-redux';
import { getListAgencies } from 'src/features/agencySlice';

const MainControlStatistic = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const isAdmin = useSelector(state => state.authSlice.isAdmin);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getListAgencies());
    }
  }, [isAdmin]);

  return (
    <div className={classes.root}>
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height:'100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    minWidth: '100%',
    position: 'relative',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingTop: 18,
      paddingBottom:18
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  }
}));


export default MainControlStatistic;
