import { createSlice } from '@reduxjs/toolkit';
import { HTTP_GETTYPE, STATUS_API } from 'src/app/constant/config';
import AxiosAdapter from './AxiosAdapter';
import { MESSAGE } from 'src/app/constant/message';

export const getListUsers = AxiosAdapter.GetHttp(
  'deviceSlice/getListUsers',
  '/users',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const createUser = AxiosAdapter.HttpPost(
  'deviceSlice/createUser',
  '/users/',
  MESSAGE.CREATE_USER_SUCCESS,
  'Tạo mới người dùng thất bại'
);

export const updateUser = AxiosAdapter.HttpUpdateById(
  'userSlice/updateUser',
  '/users/info',
  MESSAGE.UPDATE_USER_SUCCESS,
  'Cập nhật người dùng thất bại'
);

export const deleteUser = AxiosAdapter.HttpDelete(
  'userSlice/deleteUser',
  'users',
  MESSAGE.DELETE_USER_SUCCESS,
  'Xóa người dùng thất bại'
);

export const deleteMultiUsers = AxiosAdapter.HttpDeleteMulti(
  'userSlice/deleteMultiUser',
  'users',
  MESSAGE.DELETE_USER_SUCCESS,
  'Xóa người dùng thất bại'
);

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    listUsers: [],
    statusCreate: null,
    statusGet: null,
    statusUpdate: null,
    statusDelete: null,
    statusDeleteMultiUsers: null,
    errors: null,
    totalUsers: 0,
    isLoading: false,
  },
  reducers: {
    resetChange: state => {
      state.statusGet = null;
      state.statusCreate = null;
      state.statusDelete = null;
      state.statusDeleteMultiUsers = null;
      state.statusUpdate = null;
    }
  },
  extraReducers: {
    [getListUsers.pending]: state => {
      state.statusGet = STATUS_API.PENDING; 
      state.isLoading = true;
    },
    [getListUsers.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.isLoading = false;
      state.listUsers = action.payload.payload.users;
      state.totalUsers = action.payload.payload.total;
    },
    [getListUsers.rejected]: (state, action) => {
      state.statusGet = STATUS_API.ERROR;
      state.isLoading = false;
      state.errors = action.payload?.message || action.error;
    },

    [updateUser.pending]: state => {
      state.statusUpdate = STATUS_API.PENDING;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.statusUpdate = STATUS_API.SUCCESS;
    },
    [updateUser.rejected]: (state, action) => {
      state.statusUpdate = STATUS_API.ERROR;
      state.errors = action.payload?.message || action.error;
    },

    [createUser.pending]: state => {
      state.statusCreate = STATUS_API.PENDING;
    },
    [createUser.fulfilled]: (state, action) => {
      state.statusCreate = STATUS_API.SUCCESS;
    },
    [createUser.rejected]: (state, action) => {
      state.statusCreate = STATUS_API.ERROR;
      state.errors = action.payload?.message || action.error;
    },

    [deleteUser.pending]: state => {
      state.statusDelete = STATUS_API.PENDING;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.statusDelete = STATUS_API.SUCCESS;
    },
    [deleteUser.rejected]: (state, action) => {
      state.statusDelete = STATUS_API.ERROR;
      state.errors = action.payload?.message || action.error;
    },

    [deleteMultiUsers.pending]: state => {
      state.statusDeleteMultiUsers = STATUS_API.PENDING;
    },
    [deleteMultiUsers.fulfilled]: (state, action) => {
      state.statusDeleteMultiUsers = STATUS_API.SUCCESS;
    },
    [deleteMultiUsers.rejected]: (state, action) => {
      state.statusDeleteMultiUsers = STATUS_API.ERROR;
      state.errors = action.payload?.message || action.error;
    },
  }
});
export const { resetChange } = userSlice.actions;

export default userSlice.reducer;
