import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  SvgIcon,
  TextField,
  Typography
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Search as SearchIcon } from 'react-feather';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import CreateStaff from './CreateStaff';

const Toolbar = ({
  className,
  searchRef,
  clearSearchRef,
  isLoading,
  ...rest
}) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    keywords: undefined,
    page: 1,
    page_size: PAGE_SIZE_LIST
  });
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const onEnterSearchInput = event => {
    if (!searchRef) return;
    if (event.keyCode === 13) {
      const queryValue = Object.assign({}, query, {
        keywords: event.target.value,
        page: 1
      });
      setQuery(queryValue);
      searchRef(queryValue);
    }
  };

  const changeTextInputSearch = event => {
    const queryValue = Object.assign({}, query, {
      keywords: event.target.value,
      page: 1
    });
    setQuery(queryValue);
  };

  const closeModal = () => {
    setOpenCreateModal(false);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {openCreateModal && (<CreateStaff open={openCreateModal} onClose={closeModal} />)}
      
      <Box>
        <Box className={classes.groupSearch}>
          <div className={classes.groupSearchLeft}>
            <Typography
              style={{ fontSize: '24px', fontWeight: '600', color: '#0C1132' }}
            >
              Quản trị nhân viên
            </Typography>
          </div>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15px'
            }}
          >
            <Box>
              <TextField
                onKeyDown={onEnterSearchInput}
                onChange={changeTextInputSearch}
                className={'searchInputDevice'}
                //value={query.keyword}
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  style: {
                    fontSize: '14px'
                  }
                }}
                placeholder="Tên, số điện thoại hoặc email"
              />
            </Box>
            <Button
              style={{ color: '#ffff' }}
              className={classes.styleInputSearch}
              color="secondary"
              variant="contained"
              onClick={() => setOpenCreateModal(true)}
            >
              Thêm nhân viên
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  groupSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // overflowX: 'scroll',
    minWidth: '50%'
  },
  groupSearchLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  searchInput: {
    width: '250px'
  },
  styleInputSearch: {
    height: '40px',
    textTransform: 'none',
    padding: '0px 1em'
  },
  exportBtn: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  shadowBox: {
    boxShadow: '0 2px 5px rgba(0,0,0,.18)'
  }
}));

export default Toolbar;
