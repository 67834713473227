const convertTimestampToDate = (timestamp) =>{
    const date = new Date(timestamp * 1000);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month} ${hours}:${minutes}`;
}

export const vehiclesData = [{
        "airConditionStatus": 0,
        "battery": 0,
        "chargeStatus": "undefined",
        "created_at": convertTimestampToDate(1730250424),
        "doorStatus": 0,
        "engineStatus": 1,
        "lat": 20.989761,
        "lng": 105.940691,
        "olat": 20.989761,
        "olng": 105.940691,
        "originalIndex": 331,
        "placeId": "",
        "speed": 0,
        "speedGps": 34
      },
      {
        "airConditionStatus": 0,
        "battery": 0,
        "chargeStatus": "undefined",
        "created_at": convertTimestampToDate(1730250434),
        "doorStatus": 0,
        "engineStatus": 1,
        "lat": 20.989006,
        "lng": 105.941363,
        "olat": 20.989006,
        "olng": 105.941363,
        "originalIndex": 332,
        "placeId": "",
        "speed": 0,
        "speedGps": 14
      }]


export const carSpeed = [
  {
    icon: 1,
    speed: 1,
    time: 1000
  },
  {
    icon: 2,
    speed: 2,
    time: 500
  },
  {
    icon: 4,
    speed: 4,
    time: 250
  },
  {
    icon: 8,
    speed: 8,
    time: 125
  },
]