import React from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/app/components/Page';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    marginTop: 50,
    padding: '10px 20px', 
    fontSize: '16px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff', 
    border: 'none', 
    borderRadius: '5px', 
    cursor: 'pointer', 
    transition: 'background-color 0.3s ease', 
    '&:hover': {
      backgroundColor: theme.palette.primary.dark, 
    },
  },
}));

const ForbiddenPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Page className={classes.root} title="Forbidden">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            403: Forbidden
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            Bạn không có quyền truy cập vào trang này. Vui lòng kiểm tra lại quyền của bạn hoặc liên hệ với quản trị viên.
          </Typography>
          <Box textAlign="center" >
            <button className={classes.button} onClick={() => navigate("/")}>
              Quay về trang chủ
            </button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ForbiddenPage;
