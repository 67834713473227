import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  IconButton,
  InputLabel,
  makeStyles,
  TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { green } from '@material-ui/core/colors';
import { STATUS_API } from 'src/app/constant/config';

import { Formik } from 'formik';
import { getListAgencies } from 'src/features/agencySlice';
import { createStaff } from 'src/features/staffSlice';
import * as Yup from 'yup';
import { roles } from 'src/app/constant/roles';
import CustomDialog from 'src/app/components/CustomDialog';

const initStateFromValue = {
  email: '',
  password: '',
  confirm_password: '',
  phone: '',
  full_name: '',
  address: ''
};

function CreateStaff({ onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusCreate = useSelector(state => state.staffSlice.statusCreate);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.AGENCY;
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;
  const agencyID = isAgency && dataLogin.agency ? dataLogin.id : undefined;
  const [initValue, setInitValue] = useState(initStateFromValue);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleSubmit = data => {
    const newStaff = {
      username: 'null',
      full_name: data.full_name,
      phone: data.phone,
      email: data.email || '',
      password: data.password,
      confirm_password: data.confirm_password,
      address: data.address || '',
      agency_id: agencyID
    };

    setInitValue(newStaff);
    dispatch(createStaff(newStaff));
    onClose();
  };

  const handleClose = () => {
    onClose();
    setInitValue(initStateFromValue);
  };

  useEffect(() => {
    if (isAdmin) dispatch(getListAgencies());
  }, [dispatch]);

  return (
    <CustomDialog title="Thêm mới nhân viên" open={open} onClose={handleClose}>
      <div>
        <Formik
          initialValues={initValue}
          validationSchema={Yup.object().shape({
            full_name: Yup.string()
              .max(100)
              .required('Tên không được để trống'),
            // email: Yup.string()
            //   .email('Email chưa đúng định dạng')
            //   .required('email không được để trống'),
            phone: Yup.string()
              .matches(
                /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
                'Số điện thoại chưa đúng định dạng'
              )
              .required('Số điện thoại không được để trống'),
            // address: Yup.string()
            //     .max(100)
            //     .required('Địa chỉ không được để trống'),
            password: Yup.string()
              .max(100)
              .required('Mật khẩu không được để trống'),
            confirm_password: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Mật khẩu chưa khớp')
              .required('Xác nhận lại mật khẩu')
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Số điện thoại (tên đăng nhập){' '}
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    className="input-no-fieldset"
                    helperText={touched.phone && errors.phone}
                    margin="normal"
                    size="small"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <Phone /> */}
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Tên nhân viên <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.full_name && errors.full_name)}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.full_name && errors.full_name}
                    margin="normal"
                    name="full_name"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.full_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <AccountCircle /> */}
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Địa chỉ <span style={{ color: 'red' }}></span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.address && errors.address)}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.address && errors.address}
                    margin="normal"
                    name="address"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <AccountCircle /> */}
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Email <span style={{ color: 'red' }}></span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    margin="normal"
                    className="input-no-fieldset"
                    name="email"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* <Email /> */}
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>{' '}
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Mật khẩu <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    margin="normal"
                    className="input-no-fieldset"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(prev => !prev)}
                          >
                            {showPassword ? (
                              <VisibilityOff style={{ color: '#D9042F' }} />
                            ) : (
                              <Visibility style={{ color: '#D9042F' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Nhập lại mật khẩu <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(
                      touched.confirm_password && errors.confirm_password
                    )}
                    fullWidth
                    helperText={
                      touched.confirm_password && errors.confirm_password
                    }
                    margin="normal"
                    className="input-no-fieldset"
                    name="confirm_password"
                    size="small"
                    type={showConfirmPassword ? 'text' : 'password'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirm_password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowConfirmPassword(prev => !prev)
                            }
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff style={{ color: '#D9042F' }} />
                            ) : (
                              <Visibility style={{ color: '#D9042F' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      onClick={() => onClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Thêm mới
                    </Button>
                    {statusCreate === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export default React.memo(CreateStaff);
