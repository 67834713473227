import React from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  List,
  makeStyles,
  withStyles,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import MuiListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 16px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: '#313131'
  },
  title: {
    marginRight: 'auto',
    fontWeight: 400,
    fontSize: 14,
    color: '#313131',
  },
  subTitle: {
    // fontWeight: 400
    paddingLeft: '15px'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
    '& $icon': {
      color: theme.palette.primary.dark
    },

    backgroundColor:'#fff2f2',
    borderRadius:'none',
    borderRight:'4px solid #D9042F',
  },
  nested: {
    paddingLeft: 0,
    '& a:hover': {
      color: theme.palette.primary.main,
    },
  },

}));

const ListItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#fff2f3",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
  },
  selected: {}
})(MuiListItem);

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  arrayNested,
  ...rest
}) => {
  const classes = useStyles();
  const [openTab, setOpenTab] = React.useState(false);

    const location = useLocation();
    const pathName = location.pathname;
    React.useEffect(() => {
      if (pathName === '/app/manage/statistics/warehouse-transfer' || pathName === '/app/manage/devices' || pathName === '/app/manage/broadcasts-schedules' || pathName === '/app/manage/driver') {
        setOpenTab(true);
      }
    }, [pathName]);


  return (
    <>
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
        onClick={() => {
          if (!arrayNested) return;
          setOpenTab(!openTab);
        }}
      >
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={href ? RouterLink : Button}
          to={href}
        >

          <Typography style={{marginRight:10}}>
            {Icon && <img src={`${Icon}`} />}
          </Typography>
          <Typography style={{marginTop:2}} className={classes.title}>{title}</Typography>
          {arrayNested && (openTab ? <ExpandLess /> : <ExpandMore />)}
        </Button>
      </ListItem>
      <List component={'div'}>
        {arrayNested &&
          arrayNested.map(item => (
            <Collapse
              in={openTab}
              timeout="auto"
              unmountOnExit
              key={item.title}
            >
              <ListItem
                style={{padding:'0'}}
                className={clsx(classes.nested)}
                disableGutters = {true}
                {...rest}
              >
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={RouterLink}
                  to={item.href}
                >
                  <span className={classes.subTitle}>{item.title}</span>
                </Button>
              </ListItem>
            </Collapse>
          ))}
      </List>
    </>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string,
  arrayNested: PropTypes.array
};

export default NavItem;
