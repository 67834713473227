import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  HTTP_GETTYPE,
  MSG_TIMEOUT_REQUEST,
  STATUS_API,
  TIMEOUT_DEFAULT,
  messageToastType_const
} from 'src/app/constant/config';
import AxiosAdapter from './AxiosAdapter';
import axios from 'axios';
import Cookie from 'js-cookie';
import { MESSAGE } from 'src/app/constant/message';
import { showGlobalToast } from './uiSlice';

export const getListDevice = AxiosAdapter.GetHttp(
  'deviceSlice/GetListDevices',
  '/devices',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const getListDeviceAgency = AxiosAdapter.HttpGetById(
  'deviceSlice/GetListDevicesAgency',
  '/devices/agency',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const activeDevice = AxiosAdapter.HttpPost(
  'deviceSlice/activeSlice',
  '/devices/active',
  MESSAGE.ACTIVE_DEVICE_SUCCESS,
  'Kích hoạt thiết bị thất bại'
);

export const getDetailDevice = AxiosAdapter.GetHttp(
  'deviceSlice/GetDetailDevice',
  '/devices/',
  HTTP_GETTYPE.DETAIL
);

export const createDevice = AxiosAdapter.HttpPost(
  'deviceSlice/createDevice',
  '/devices/',
  MESSAGE.CREATE_DEVICE_SUCCESS,
  'Tạo mới thiết bị thất bại'
);

export const updateDevice = AxiosAdapter.HttpUpdateById(
  'deviceSlice/updateDevice',
  '/devices/update/info',
  MESSAGE.UPDATE_DEVICE_SUCCESS,
  'Chỉnh sửa thiết bị thất bại'
);

export const updateDeviceTransfer = AxiosAdapter.HttpUpdate(
  'agencySlice/updateWarehouseTransfer',
  '/devices/assign/agency',
  MESSAGE.TRANSFER_DEVICE_SUCCESS,
  'Chuyển kho thiết bị thất bại'
);

export const updateMultiDevices = AxiosAdapter.HttpUpdate(
  'deviceSlice/updateMultiDevices',
  '/devices/assign/sales',
  MESSAGE.TRANSFER_DEVICE_SUCCESS,
  'Chuyển kho thiết bị thất bại'
);

export const importDevice = AxiosAdapter.HttpPostUploadFile(
  'deviceSlice/importDevice',
  '/devices/import',
  MESSAGE.IMPORT_DEVICE_SUCCESS,
  'Import device file thất bại'
);

export const deleteDevices = AxiosAdapter.HttpDeleteMulti(
  'deviceSlice/deleteDevices',
  'devices',
  MESSAGE.DELETE_DEVICE_SUCCESS,
  'Xóa thiết bị thất bại'
);

export const deleteDevice = AxiosAdapter.HttpDelete(
  'deviceSlice/DeleteDevice',
  'devices',
  MESSAGE.DELETE_DEVICE_SUCCESS,
  'TXóa thiết bị thất bại'
)

export const recallDevices = AxiosAdapter.HttpUpdate(
  'deviceSlice/recallDevices',
  '/devices/recall/vehicle',
  MESSAGE.RECALL_DEVICE_SUCCESS,
  'Thu hồi thiết bị thất bại'
)

export const recallDevicesByAgency = AxiosAdapter.HttpUpdateById(
  'deviceSlice/recallDevicesByAgency',
  '/devices/recall/vehicle',
  MESSAGE.RECALL_DEVICE_SUCCESS,
  'Thu hồi thiết bị thất bại'
)

// #region get data khi search lộ trình
export const getDetailDevicePosition = createAsyncThunk(
  'deviceSlice/getDetailDevicePosition',
  (payload, { rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/devices/${payload.id}/position?first_time=${payload.first_time}&last_time=${payload.last_time}`,
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token'),
          timeout: TIMEOUT_DEFAULT
        }
      })
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const postExtendDevice = createAsyncThunk(
  'deviceSlice/postExtendDevice',
  (payload, { dispatch, rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/devices/extend/${payload.id}`,
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token'),
          timeout: TIMEOUT_DEFAULT
        }
      })
        .then(res => {
          if (MESSAGE.EXTEND_DEVICE_SUCCESS) {
            dispatch(
              showGlobalToast({
                message: MESSAGE.EXTEND_DEVICE_SUCCESS,
                type: messageToastType_const.success
              })
            );
          }
          resolve(res.data);
        })
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          if (MESSAGE.EXTEND_DEVICE_FAIL) {
            dispatch(
              showGlobalToast({
                message: err?.response?.data?.message || MESSAGE.EXTEND_DEVICE_FAIL,
                type: messageToastType_const.error
              })
            );
          }
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const getUsernameByPhoneNumber = createAsyncThunk(
  'deviceSlice/getUsernameByPhoneNumber',
  (payload, { rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/users/username/?phone=${payload.phoneNumber}`,
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token'),
          timeout: TIMEOUT_DEFAULT
        }
      })
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const getServicePackage = createAsyncThunk(
  'deviceSlice/getServicePackage',
  (payload, { rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/service_package/packages?device_type_id=${payload.device_type_id}&version=${payload.version}`,
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token'),
          timeout: TIMEOUT_DEFAULT
        }
      })
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const deviceSlice = createSlice({
  name: 'deviceSlice',
  initialState: {
    listDevice: [],
    listPackage: [],
    totalDevice: 0,
    username: null,
    deviceType: null,

    statusGet: null,
    statusGetPackage: null,
    statusGetPositions: null,
    statusCreate: null,
    statusActive: null,
    statusUpdate: null,
    statusTransfer: null,
    statusDelete: null,
    statusDeleteMulti: null,
    statusImport: null,
    statusRecall: null,
    statusExtend: null,

    resultImport: null,
    resultDelete: null,
    error: null,
    detailDevice: null,

    positionsDevice: null,
    numberOfItemPosition: null,
    isLoading: false
  },
  reducers: {
    resetChange: state => {
      state.statusGet = null;
      state.statusCreate = null;
      state.statusDelete = null;
      state.statusDeleteMulti = null;
      state.statusUpdate = null;
      state.statusActive = null;
      state.statusRecall = null;
      state.statusExtend = null;
      state.statusTransfer = null;
      state.isLoading = false;
      state.username = '';
      state.deviceType = null;
    },
  
    resetSearchUsernameByPhone: state => {
      state.username = '';
    }
  },
  extraReducers: {
    // #region get device
    [getListDevice.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getListDevice.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.listDevice = action.payload.payload.devices;
      state.totalDevice = action.payload.payload.numberOfDevices;
      state.isLoading = false;
    },
    [getListDevice.rejected]: (state, action) => {
      state.statusGet = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
      state.isLoading = false;
    },
    [getListDeviceAgency.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getListDeviceAgency.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.listDevice = action.payload.payload.devices;
      state.totalDevice = action.payload.payload.numberOfDevices;
      state.isLoading = false;
    },
    [getListDeviceAgency.rejected]: (state, action) => {
      state.statusGet = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
      state.isLoading = false;
    },
    // #region get username by phone number
    [getUsernameByPhoneNumber.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getUsernameByPhoneNumber.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.username = action.payload.payload;
      state.isLoading = false;
    },
    [getUsernameByPhoneNumber.rejected]: (state, action) => {
      state.statusGet = STATUS_API.ERROR;
      state.isLoading = false;
    },

    [getDetailDevice.pending]: state => {
      state.isLoading = true;
      state.statusGet = STATUS_API.PENDING;
      state.detailDevice = null;
    },
    [getDetailDevice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.statusGet = STATUS_API.SUCCESS;
      state.detailDevice = action.payload.payload.device;
    },
    [getDetailDevice.rejected]: (state, action) => {
      state.isLoading = false;
      state.statusGet = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    // #region get device position
    [getDetailDevicePosition.pending]: state => {
      state.isLoading = true;
      state.statusGetPositions = STATUS_API.PENDING;
      state.positionsDevice = null;
    },
    [getDetailDevicePosition.fulfilled]: (state, action) => {
      state.statusGetPositions = STATUS_API.SUCCESS;
      state.positionsDevice = action.payload.payload.path;
      state.deviceType = action.payload.payload.device_type;
      state.numberOfItemPosition = action.payload.payload.number_of_items;
      state.isLoading = false;
    },
    [getDetailDevicePosition.rejected]: (state, action) => {
      state.statusGetPositions = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
      state.isLoading = false;
    },

    // #region get service package
    [getServicePackage.pending]: state => {
      state.isLoading = true;
      state.statusGetPackage = STATUS_API.PENDING;
    },
    [getServicePackage.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.statusGetPackage = STATUS_API.SUCCESS;
      state.listPackage = action.payload.payload.result;
    },
    [getServicePackage.rejected]: (state, action) => {
      state.isLoading = false;
      state.statusGetPackage = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    //#region create device
    [createDevice.pending]: state => {
      state.statusCreate = STATUS_API.PENDING;
    },
    [createDevice.fulfilled]: (state, action) => {
      state.statusCreate = STATUS_API.SUCCESS;
    },
    [createDevice.rejected]: (state, action) => {
      state.statusCreate = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    //#region active device
    [activeDevice.pending]: state => {
      state.statusActive = STATUS_API.PENDING;
    },
    [activeDevice.fulfilled]: (state, action) => {
      state.statusActive = STATUS_API.SUCCESS;
    },
    [activeDevice.rejected]: (state, action) => {
      state.statusActive = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    //#region recall device
    [recallDevicesByAgency.pending]: state => {
      state.statusRecall = STATUS_API.PENDING;
    },
    [recallDevicesByAgency.fulfilled]: (state, action) => {
      state.statusRecall = STATUS_API.SUCCESS;
    },
    [recallDevicesByAgency.rejected]: (state, action) => {
      state.statusRecall = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    //#region recall device
    [recallDevices.pending]: state => {
      state.statusRecall = STATUS_API.PENDING;
    },
    [recallDevices.fulfilled]: (state, action) => {
      state.statusRecall = STATUS_API.SUCCESS;
    },
    [recallDevices.rejected]: (state, action) => {
      state.statusRecall = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    //#region extend device
    [postExtendDevice.pending]: state => {
      state.statusExtend = STATUS_API.PENDING;
    },
    [postExtendDevice.fulfilled]: (state, action) => {
      state.statusExtend = STATUS_API.SUCCESS;
    },
    [postExtendDevice.rejected]: (state, action) => {
      state.statusExtend = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    //#region delete device
    [deleteDevice.pending]: state => {
      state.statusDelete = STATUS_API.PENDING;
    },
    [deleteDevice.fulfilled]: (state, action) => {
      state.statusDelete = STATUS_API.SUCCESS;
    },
    [deleteDevice.rejected]: (state, action) => {
      state.statusDelete = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    [updateDevice.pending]: state => {
      state.statusUpdate = STATUS_API.PENDING;
    },
    [updateDevice.fulfilled]: (state, action) => {
      state.statusUpdate = STATUS_API.SUCCESS;
    },
    [updateDevice.rejected]: (state, action) => {
      state.statusUpdate = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    //#region Transfer device
    [updateDeviceTransfer.pending]: state => {
      state.statusTransfer = STATUS_API.PENDING;
    },
    [updateDeviceTransfer.fulfilled]: (state, action) => {
      state.statusTransfer = STATUS_API.SUCCESS;
    },
    [updateDeviceTransfer.rejected]: (state, action) => {
      state.statusTransfer = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    [importDevice.pending]: state => {
      state.statusImport = STATUS_API.PENDING;
    },
    [importDevice.fulfilled]: (state, action) => {
      state.statusImport = STATUS_API.SUCCESS;
      state.resultImport = action.payload.payload;
    },
    [importDevice.rejected]: (state, action) => {
      state.statusImport = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    [deleteDevices.pending]: state => {
      state.statusDeleteMulti = STATUS_API.PENDING;
    },
    [deleteDevices.fulfilled]: (state, action) => {
      state.statusDeleteMulti = STATUS_API.SUCCESS;
      state.resultDelete = action.payload.payload;
    },
    [deleteDevices.rejected]: (state, action) => {
      state.statusDeleteMulti = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    [updateMultiDevices.pending]: state => {
      state.statusUpdate = STATUS_API.PENDING;
    },
    [updateMultiDevices.fulfilled]: (state, action) => {
      state.statusUpdate = STATUS_API.SUCCESS;
    },
    [updateMultiDevices.rejected]: (state, action) => {
      state.statusUpdate = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    }
  }
});
export const {
  resetChange,
  setActiveStep,
  setObjectCreating,
  resetSearchUsernameByPhone
} = deviceSlice.actions;

export default deviceSlice.reducer;
