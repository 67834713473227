import {
  Button,
  makeStyles,
  TextField,
  InputLabel,
  Box
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { STATUS_API } from 'src/app/constant/config';
import theme from '../theme';

const useStyles = makeStyles(theme => ({
  root: {
    //padding: '10px',
    width: 352,
    boxShadow: 'none'
  },
  inputRoot: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: '4px'
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  searchInput: {
    width: '100%'
  },
  option: {
    fontSize: '14px'
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  titleLotrinh: {
    fontSize: '18px',
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginBottom: '10px'
  },
  titleTime: {
    fontSize: '16px',
    color: '#0C1132',
    fontWeight: 600
  },
  formControl: {
    width: 352,
    marginTop: '10px',
    marginBottom: '10px'
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textRed: {
    color: 'red'
  }
}));

export default function ToolBarTracking({ GetVehiclePosition, resetTracking }) {
  const statusGetPositions = useSelector(
    state => state.deviceSlice.statusGetPositions
  );
  const listVehicleTracking = useSelector(
    state => state.vehicleSlice.listVehicleTracking
  );
  const listVehicleSearch = listVehicleTracking?.map(item => {
    return {
      device_id: item.device_id,
      license_plate: item.license_plate
    };
  });
  const classes = useStyles();
  const [schedule, setSchedule] = React.useState('');
  const [startTime, setStartTime] = React.useState('');
  const [endTime, setEndTime] = React.useState('');

  const [queryTracking, setQueryTracking] = useState({
    license_plate: '',
    start_date: moment()
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm'),
    end_date: moment().format('YYYY-MM-DDTHH:mm')
  });

  const ConvertTime = date => {
    return Math.floor(
      moment(date)
        .utcOffset(0)
        .unix()
    );
  };

  const handleSearchDevice = (schedule, startTime, endTime) => {
    resetTracking();
    const startTimeZone0 = ConvertTime(startTime);
    const endTimeZone0 = ConvertTime(endTime);

    const data = {
      id: +schedule,
      first_time: startTimeZone0
        ? startTimeZone0
        : ConvertTime(queryTracking.start_date),
      last_time: endTimeZone0
        ? endTimeZone0
        : ConvertTime(queryTracking.end_date)
      // id: 246,
      // first_time: '1740729840',
      // last_time: '1740816240',
    };

    GetVehiclePosition(data);
  };

  const handleChangeSchedule = (event, value) => {
    const newSchedule = value ? value.device_id : event.target.value;
    setSchedule(newSchedule);
  };

  const handleChangeStartTime = event => {
    setStartTime(event.target.value);
  };

  const handleChangeEndTime = event => {
    setEndTime(event.target.value);
  };

  const disabledLoadRoadMap = () => {
    if (!schedule) {
      return true;
    }
    return (
      statusGetPositions !== STATUS_API.SUCCESS && statusGetPositions !== null
    );
  };

  return (
    <Paper component="form" className={classes.root}>
      <FormControl size="small" className={classes.formControl}>
        <div>
          {listVehicleSearch && (
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              size="small"
              disableClearable
              options={listVehicleSearch}
              getOptionLabel={option => option.license_plate}
              onChange={handleChangeSchedule}
              classes={{ option: classes.option }}
              className={classes.inputRoot}
              renderInput={params => (
                <TextField
                  {...params}
                  //margin="dense"
                  required={true}
                  className={classes.searchInput}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      fontSize: '14px'
                    }
                  }}
                  variant="outlined"
                  placeholder="Nhập biển số để tìm kiếm ..."
                  onChange={handleChangeSchedule}
                />
              )}
            />
          )}
        </div>
      </FormControl>

      <div>
        <span className={classes.titleTime}>Chọn khoảng thời gian</span>
        <FormControl variant="outlined" className={classes.formControl}>
          <form noValidate>
            <Box style={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
              <InputLabel
                style={{
                  fontSize: '14px',
                  position: 'relative',
                  top: -30,
                  left: -13,
                }}
              >
                Từ ngày
              </InputLabel>
              <TextField
                style={{ width: '100%' }}
                id="datetime-local"
                type="datetime-local"
                variant="outlined"
                size="small"
                value={startTime || queryTracking.start_date}
                className={classes.textField}
                onChange={handleChangeStartTime}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  style: {
                    fontSize: '14px'
                  }
                }}
              />
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column',  marginTop: '12px'  }}>
              <InputLabel
                style={{
                  fontSize: '14px',
                  position: 'relative',
                  top: -15,
                  left: -13,
                }}
              >
                Đến ngày
              </InputLabel>
              <TextField
                style={{ width: '100%', marginTop: '15px' }}
                id="datetime-local"
                type="datetime-local"
                variant="outlined"
                size="small"
                value={endTime || queryTracking.end_date}
                className={classes.textField}
                onChange={handleChangeEndTime}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  style: {
                    fontSize: '14px'
                  }
                }}
              />
            </Box>
          </form>
        </FormControl>
      </div>
      <Button
        style={{
          background: theme.palette.primary.main,
          color: '#fff',
          textTransform: 'inherit',
          fontSize: '16px',
          width: 352,
          marginTop: '12px'
        }}
        disabled={disabledLoadRoadMap()}
        variant="contained"
        onClick={e => {
          handleSearchDevice(schedule, startTime, endTime);
        }}
      >
        {statusGetPositions !== STATUS_API.SUCCESS &&
        statusGetPositions !== null ? (
          <>
            <CircularProgress size={24} style={{ color: 'white' }} />
            Vui lòng đợi ...
          </>
        ) : (
          <span> Tải lộ trình </span>
        )}
      </Button>
    </Paper>
  );
}
