import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import {
  STATUS_API
} from 'src/app/constant/config';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { deviceType } from 'src/app/constant/deviceType';
import { createServicePackage } from 'src/features/servicePackageSlice';
import CustomDialog from 'src/app/components/CustomDialog';

const initStateFromValue = {
  device_type_id : '',
  version : '',
  cost : '',
  day_limit : '',
  name : '',
  description : ''
};

const CreateServicePackage = ({ open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const statusCreate = useSelector(state => state.servicePackageSlice.statusCreate);
    const [initValue, setInitValue] = useState(initStateFromValue);
    
    const handleSubmit = data => {
        setInitValue(data);
        dispatch(createServicePackage(data));
        onClose();
    }

    const handleClose = () => {
      onClose();
      setInitValue(initStateFromValue);
    };
    
    return ( 
      <CustomDialog title="Thêm mới gói dịch vụ" open={open} onClose={handleClose} >
        <div>
          <Formik
            initialValues={initValue}
            validationSchema={Yup.object().shape({
                device_type_id: Yup.string()
                .max(100)
                .required('Tên loại thiết bị không được để trống'),
                name: Yup.string()
                .max(100)
                .required('Tên gói cước không được để trống'),
                version: Yup.string()
                .max(100)
                .required('Phiên bản không được để trống'),
                cost: Yup.number()
                .required('Giá gói cước không được để trống'),
                day_limit: Yup.number()
                .required('Hạn gói cước không được để trống'),
                description: Yup.string()
                .max(100)
                .required('Mô tả không được để trống'),
            })}
            onSubmit={handleSubmit}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>              
                  <Grid item md={6} xs={12}>
                      <InputLabel>Tên loại thiết bị <span className="text-danger">*</span></InputLabel>
                      <TextField
                      className="input-no-fieldset"
                      size="small"
                      fullWidth
                      select
                      error={Boolean(
                        touched.device_type_id && errors.device_type_id
                      )}
                      helperText={touched.device_type_id && errors.device_type_id}
                      margin="normal"
                      name="device_type_id"
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue('device_type_id', Number(e.target.value))}
                      SelectProps={{
                          native: true
                      }}
                      //value={provinceSelected}
                      variant="outlined"
                      >
                      <option>Chọn loại thiết bị </option>
                      {deviceType && deviceType.map((option) => (
                          <option key={option.id} value={option.id}>
                              {option.name}
                          </option>
                      ))}
                      </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Tên gói dịch vụ <span className="text-danger">*</span></InputLabel>
                    <TextField
                      number
                      error={Boolean(
                        touched.name && errors.name
                      )}
                      className="input-no-fieldset"
                      fullWidth
                      helperText={touched.name && errors.name}
                      margin="normal"
                      name="name"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Phiên bản <span className="text-danger">*</span></InputLabel>
                    <TextField
                      number
                      error={Boolean(
                        touched.version && errors.version
                      )}
                      className="input-no-fieldset"
                      fullWidth
                      helperText={touched.version && errors.version}
                      margin="normal"
                      name="version"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.version}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Giá gói cước <span className="text-danger">*</span></InputLabel>
                    <TextField
                      error={Boolean(
                        touched.cost && errors.cost
                      )}
                      className="input-no-fieldset"
                      fullWidth
                      helperText={touched.cost && errors.cost}
                      margin="normal"
                      name="cost"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.cost}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Hạn gói cước (Ngày) <span className="text-danger">*</span></InputLabel>
                    <TextField
                      error={Boolean(
                        touched.day_limit && errors.day_limit
                      )}
                      className="input-no-fieldset"
                      fullWidth
                      helperText={touched.day_limit && errors.day_limit}
                      margin="normal"
                      name="day_limit"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.day_limit}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Mô tả <span className="text-danger">*</span></InputLabel>
                    <TextField
                      error={Boolean(
                        touched.description && errors.description
                      )}
                      className="input-no-fieldset"
                      fullWidth
                      helperText={touched.description && errors.description}
                      margin="normal"
                      name="description"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Box my={3}>
                  <div className={classes.groupButtonSubmit}>
                    <div className={classes.wrapper}>
                      <Button
                        style={{ marginRight: '10px', textTranform: 'none !important' }}
                        onClick={() => onClose()}
                        className="btn-main btn-plain mx-3"
                        color="primary"
                        size="large"
                        variant="contained"
                      >
                        Thoát
                      </Button>
                      <Button
                        style={{ marginRight: '10px', textTranform: 'none !important' }}
                        className="btn-main mx-3"
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {'Thêm mới'}
                      </Button>
                      {statusCreate === STATUS_API.PENDING && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                </Box>
              </form>
            )}
          </Formik>
        </div>
      </CustomDialog>
     );
}

const useStyles = makeStyles(theme => ({
    root: {
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    groupButtonSubmit: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '15px',
  
      '& .left-button': {
        display: 'flex'
      }
    },
    wrapper: {
      position: 'relative'
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
  }));
 
export default CreateServicePackage;