import {
  Box,
  Card,
  Checkbox,
  List,
  ListItem,
  makeStyles,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Fade
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import DialogConfirm from 'src/app/components/DialogConfirm';
import { MESSAGE } from 'src/app/constant/message';
import { deleteMultiSim } from 'src/features/simSlice';
import { closeDialogConfirm, showDialogConfirm } from 'src/features/uiSlice';

import { useDispatch } from 'react-redux';
import LoadingComponent from 'src/app/components/Loading';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { EditSim } from './EditSim';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    whiteSpace: 'nowrap'
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap'
  },
  root: {
    padding: '6px'
  }
}))(TableCell);

const Results = ({
  className,
  listSims,
  getListSimRef,
  actionDetailsUserRef,
  totalSim,
  actionDeleteUserRef,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.simSlice.isLoading);
  const totalSims = useSelector(state => state.simSlice.totalSims);
  const classes = useStyles();
  const [limit, setLimit] = useState(PAGE_SIZE_LIST);
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState();
  const [itemCurrent, setItemCurrent] = useState();
  const [rowsSelected, setRowsSelected] = useState([]);

  const [params, setParams] = useState({
    page: page,
    page_size: limit
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickMenu = (event, sim) => {
    setAnchorEl(event.currentTarget);
    setItemCurrent({
      phone: sim.phone,
      imei_sim: sim.imei_sim,
      active_at: sim.active_at,
      id: sim.id,
      status: sim.status
    });
    setSelectedItem(sim.id);
  };
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleEditSim = () => {
    setOpenEditModal(true);
    handleCloseMenu();
  };

  const closeModal = () => {
    setOpenEditModal(false);
  };

  const handleDeleteSim = () => {
    dispatch(showDialogConfirm());
    handleCloseMenu();
  };

  const handleDeleteMultiSim = () => {
    dispatch(showDialogConfirm());
    handleCloseMenu();
  };

  const confirmDelete = () => {
    const listId = rowsSelected?.length > 0 ? rowsSelected : [selectedItem];
    setRowsSelected([]);
    dispatch(closeDialogConfirm());
    dispatch(deleteMultiSim({ list_sim_ids: listId }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (!getListSimRef) return;
    const newparams = Object.assign({}, params, { page: newPage });
    setParams(newparams);
    getListSimRef(newparams);
  };

  const checked = id => {
    return rowsSelected.indexOf(id) !== -1;
  };
  const select = sim => {
    if (rowsSelected.indexOf(sim) === -1) {
      const newSelected = [sim, ...rowsSelected];
      setRowsSelected(newSelected);
    } else {
      const newSelected = rowsSelected.filter(i => i !== sim);
      setRowsSelected(newSelected);
    }
  };

  const checkAll = () => {
    if (rowsSelected.length > 0) {
      setRowsSelected([]);
    }

    if (listSims.length > 0 && rowsSelected.length !== listSims.length) {
      const all = listSims.map(item => {
        return item.id;
      });
      setRowsSelected(all);
    }
  };
  return (
    <>
      {openEditModal && (
        <EditSim open selectedSim={itemCurrent} onClose={closeModal} />
      )}

      <DialogConfirm
        title={MESSAGE.CONFIRM_DELETE_SIM}
        textOk={MESSAGE.BTN_YES}
        textCancel={MESSAGE.BTN_CANCEL}
        content={MESSAGE.CONTENT_DELETE_SIM}
        callbackOk={() => confirmDelete()}
        callbackCancel={() => dispatch(closeDialogConfirm())}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div>
            {rowsSelected.length > 0 && (
              <Fade in={rowsSelected.length > 0}>
                <Box
                  minWidth={1050}
                  style={{ fontSize: '14px', padding: '6px 4px' }}
                >
                  <span>
                    <Checkbox
                      checked={rowsSelected.length > 0}
                      onChange={() => checkAll()}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </span>

                  <span
                    onClick={handleDeleteMultiSim}
                    className="mx-2 btnDevice"
                    color="primary"
                    variant="outlined"
                    size="small"
                  >
                    <img
                      src={`/static/iconSvg/delete-icon.svg`}
                      alt="delete"
                      style={{ paddingRight: '5px', paddingBottom: '4px' }}
                    />
                    <span className="pt-1"> Xóa </span>
                  </span>
                </Box>
              </Fade>
            )}
            <PerfectScrollbar>
              {listSims && listSims.length ? (
                <Box minWidth={1050}>
                  <Table>
                    {rowsSelected.length === 0 && listSims?.length > 0 && (
                      <TableHead>
                        <TableRow>
                          <StyledTableCell width={50}>
                            <Checkbox
                              checked={rowsSelected.length > 0}
                              onChange={() => checkAll()}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>Gói Sim</StyledTableCell>
                          <StyledTableCell>Imei sim</StyledTableCell>
                          <StyledTableCell>Số điện thoại</StyledTableCell>
                          <StyledTableCell>Ngày kích hoạt</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>
                    )}
                    <TableBody>
                      {listSims.map((sim, index) => (
                        <TableRow hover key={sim.id}>
                          <StyledTableCell
                            style={{ maxWidth: '20px' }}
                            onClick={() => select(sim?.id)}
                          >
                            <Checkbox
                              checked={checked(sim.id)}
                              onChange={() => select(sim.id)}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {sim.package || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {sim.imei_sim || '-'}
                          </StyledTableCell>
                          <StyledTableCell>{sim.phone || '-'}</StyledTableCell>
                          <StyledTableCell>
                            {new Date(sim.active_at).toLocaleDateString(
                              'vi-VN'
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            <span style={{ color: '#AEB1C5' }}>
                              <MoreVertIcon
                                aria-describedby={`menu-device-${sim.cusotmer_care_id}`}
                                className={`cursor-pointer hover-red`}
                                onClick={e => handleClickMenu(e, sim)}
                              />
                            </span>

                            <Popover
                              id={`menu-device-${sim.id}`}
                              className="popover-device"
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <Typography>
                                <List>
                                  <ListItem
                                    onClick={handleEditSim}
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Chỉnh sửa
                                  </ListItem>
                                  <ListItem
                                    onClick={handleDeleteSim}
                                    className="cursor-pointer list-menu-device"
                                  >
                                    <span class="text-danger"> Xóa </span>
                                  </ListItem>
                                </List>
                              </Typography>
                            </Popover>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <div className="text-center pb-5">
                  <img src="/static/empty.png" class="justify-content-center" />
                  <h4> Danh sách trống </h4>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Card>
      {listSims && listSims.length > 0 && (
        <div class="mt-3 mr-4 float-right">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15px'
            }}
          >
            <Typography style={{ fontSize: '14px' }}>
              Tổng: {totalSims}
            </Typography>
            <Pagination
              count={Math.ceil(totalSims / limit)}
              color='primary'
              size="small"
              onChange={handlePageChange}
              page={Number(params.page)}
              showFirstButton
              showLastButton
            />
          </Box>
        </div>
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  listSims: PropTypes.array.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  groupAction: {
    display: 'flex',
    alignItems: 'center',
    '& .action': {
      cursor: 'pointer',
      padding: '0 5px',
      '&:hover': {
        color: '#3f51b5'
      }
    }
  },
  minWithColumn: {
    minWidth: '150px'
  }
}));

export default Results;
