import React, { useState, useEffect } from 'react';
import {
  Box,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';
import { Typography } from 'antd';

const ToolBarStatistic = ({
  devicesCount,
  exportStatistic
}) => {
  const classes = useStyles();

  const  handleExportFile = () => {
    if(exportStatistic) exportStatistic();
  };

  return (
    <>
      <div className={classes.root}>
        <Box>
          <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Typography style={{fontSize:'18px', fontWeight:'600'}}>
                {devicesCount}
            </Typography>
            <Box style={{display:'flex', gap:'15px', alignItems:'center', justifyContent:'center'}}>
                <Box 
                  style={{
                    display:'flex', 
                    justifyContent:'center', 
                    alignItems:'center', 
                    gap:'5px', 
                    cursor:'pointer'
                    }}
                    onClick={() =>  handleExportFile()}
                  >
                  <img alt="icon" src={`/static/iconSvg/upload_excel.svg`}/>
                  <Typography style={{color:'#D9042F', fontSize:'14px', fontWeight:'500'}}>Xuất excel</Typography>
                </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  groupSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // overflowX: 'scroll',
    minWidth: '50%'
  },
  groupSearchLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  searchInput: {
    width: '250px'
  },
  styleInputSearch: {
    height: '40px',
    textTransform: 'none',
    padding: '0px 1em'
  },
  exportBtn: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  shadowBox: {
    boxShadow: '0 2px 5px rgba(0,0,0,.18)'
  }
}));

export default ToolBarStatistic;
