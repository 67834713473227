import { makeStyles, Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ChartStatistic from 'src/app/components/ChartStatistic/ChartStatistic';
import ToolBarStatistic from 'src/app/components/ToolBarStatistic/ToolBarStatistic';
import { getStatisticServices, postExportStatisticService } from 'src/features/statisticSlice';
import { roles } from 'src/app/constant/roles';
import StatisticServiceTable from './StatisticServiceTable';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { useQuery } from 'src/app/hooks/useQuery';
import { _convertObjectToQuery } from 'src/app/utils/apiService';
const StatisticServices = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const query = useQuery();
  const [params, setParams] = useState({
    page: 1,
    page_size: PAGE_SIZE_LIST
  });

  const listServiceStatistic = useSelector(
    state => state.statisticSlice.listServiceStatistic
  );
  const monthlyStatisticsService = useSelector(
    state => state.statisticSlice.monthlyStatisticsService
  );
  const yearlyStatisticsService = useSelector(
    state => state.statisticSlice.yearlyStatisticsService
  );

  const totalService = useSelector(state => state.statisticSlice.totalService);
  const totalPaidCost = useSelector(state => state.statisticSlice.totalPaidCost);

  const formatCurrency = (amount, locale = 'vi-VN', currency = 'VND') => {
    return amount.toLocaleString(locale, {
      style: 'currency',
      currency: currency
    });
  };

  const devicesCount = formatCurrency(totalPaidCost);
  const page = query.get('page') || 1;
  const agencyId = query.get('agency_id') || undefined;
  const deviceTypeId = query.get('device_type_id') || undefined;
  const timeToChart = query.get('time_to_chart') || undefined;
  const timeToSearch = query.get('time_to_search') || undefined;

  const dataStatistics = timeToChart ? monthlyStatisticsService : yearlyStatisticsService;

  useEffect(() => {
    const queryValue = {
      ...params,
      page: page ? page : undefined,
      agency_id: agencyId ? agencyId : undefined,
      device_type_id: deviceTypeId ? deviceTypeId : undefined,
      time_to_chart: timeToChart ? timeToChart : undefined,
      time_to_search: timeToSearch ? timeToSearch : undefined
    };
    setParams(queryValue);
    dispatch(getStatisticServices(queryValue));
  }, [query]);

  const getListStatisticServicesWithParams = data => {
    navigate('/app/manage/statistics/service?' + _convertObjectToQuery(data));
    dispatch(getStatisticServices(data));
  };

  const exportStatistic = data => {
    dispatch(postExportStatisticService(data));
  };

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: '98%', margin: '0 auto' }}>
          <Box
            style={{
              backgroundColor: '#ffffff',
              borderRadius: '4px',
              padding: '0px 10px 5px'
            }}
          >
            <ChartStatistic
              dataStatistics={dataStatistics}
              chartTitle={'Thống kê dịch vụ'}
              labelChart={'Số tiền'}
              searchRef={getListStatisticServicesWithParams}
            />
          </Box>
          <Box marginTop={'20px'}>
            <ToolBarStatistic
              devicesCount={`Tổng doanh thu ${devicesCount}`}
              getStatisticServicesRef={getListStatisticServicesWithParams}
              isTransfer={false}
              exportStatistic = {exportStatistic}
            />
          </Box>
          <Box marginTop={'10px'}>
            <StatisticServiceTable
              listService={listServiceStatistic}
              getStatisticServicesRef={getListStatisticServicesWithParams}
              totalService={totalService}
              params={params}
              setParams={setParams}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    width: '100%',
    marginBottom: 30
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

export default StatisticServices;
