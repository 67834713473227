import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {
  PAGE_SIZE_LIST,
  STATUS_API
} from 'src/app/constant/config';
import { useQuery } from 'src/app/hooks/useQuery.js';
import { _convertObjectToQuery } from 'src/app/utils/apiService.js';
import {
  getListVehicleTypes
} from 'src/features/vehicleTypeSlice';
import Results from './Results.js';
import ToolBar from './ToolBar.js';

const VehicleType = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const [params, setParams] = useState({
    page: 1,
    page_size: PAGE_SIZE_LIST
  });

  const statusCreate = useSelector(
    state => state.vehicleTypeSlice.statusCreate
  );
  const statusDelete = useSelector(
    state => state.vehicleTypeSlice.statusDelete
  );
  const statusMultiDelete = useSelector(
    state => state.vehicleTypeSlice.statusMultiDelete
  );
  const statusUpdate = useSelector(
    state => state.vehicleTypeSlice.statusUpdate
  );
  const listVehicleTypes = useSelector(
    state => state.vehicleTypeSlice.listVehicleTypes
  );

  const page = query.get('page') || 1;
  const keywords = query.get('keywords');

  useEffect(() => {
    const queryValue = {
      ...params,
      page: page ? page : undefined,
      keywords: keywords ? keywords : undefined
    };
    setParams(queryValue);
  }, [query]);

  useEffect(() => {
    dispatch(getListVehicleTypes(params))
  }, []);

  const getListVehicleTypesWithParams = query => {
    navigate('/app/manage/vehicle-types?' + _convertObjectToQuery(query));
    dispatch(getListVehicleTypes(query));
  };

  useEffect(() => {
    if (
      statusCreate === STATUS_API.SUCCESS ||
      statusDelete === STATUS_API.SUCCESS ||
      statusMultiDelete === STATUS_API.SUCCESS ||
      statusUpdate === STATUS_API.SUCCESS
    ) {
      getListVehicleTypesWithParams(params);
    }
  }, [statusCreate, statusDelete, statusUpdate, statusMultiDelete]);

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: '98%', margin: '0 auto' }}>
          <ToolBar searchRef={getListVehicleTypesWithParams} />
          <Results
            className="mt-3"
            listVehicleTypes={listVehicleTypes}
            getListVehicleTypesWithParams={getListVehicleTypesWithParams}
            params={params}
            setParams={setParams}
          />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(2)
  },
}));

export default VehicleType;
