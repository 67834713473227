import { Box, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  PAGE_SIZE_LIST,
  STATUS_API
} from 'src/app/constant/config';
import { roles } from 'src/app/constant/roles';
import { getListAgencies } from 'src/features/agencySlice';
import {
  getListDevice,
  getListDeviceAgency
} from 'src/features/deviceSlice';
import Results from './Results';
import Toolbar from './ToolBar';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DeviceListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();

  const totalDevice = useSelector(state => state.deviceSlice.totalDevice);
  const isLoading = useSelector(state => state.deviceSlice.isLoading);

  const statusCreate = useSelector(state => state.deviceSlice.statusCreate);
  const statusDelete = useSelector(state => state.deviceSlice.statusDelete);
  const statusDeleteMulti = useSelector(state => state.deviceSlice.statusDeleteMulti);
  const statusUpdate = useSelector(state => state.deviceSlice.statusUpdate);

  const statusActive = useSelector(state => state.deviceSlice.statusActive);
  const statusRecall = useSelector(state => state.deviceSlice.statusRecall);
  const statusTransfer = useSelector(state => state.deviceSlice.statusTransfer);
  const statusImport = useSelector(state => state.deviceSlice.statusImport);
  const statusExtend = useSelector(state => state.deviceSlice.statusExtend);

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.AGENCY;
  const isEndUser =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ENDUSER;
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  const listDevice = useSelector(state => state.deviceSlice.listDevice);
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);

  useEffect(() => {
      if (isAdmin) {
        dispatch(getListDevice(params));
        dispatch(getListAgencies());
      } else if (isAgency) {
        const dataRq = Object.assign({}, params);
        dispatch(
          getListDeviceAgency({
            data: dataRq,
            id: agencyID
          })
        );
      } else if (isEndUser) {
        dispatch(getListDevice(params));
      }
  }, [isAdmin, isAgency, isEndUser]);

  const [params, setParams] = useState({
    page: query.get('page') || 1,
    page_size: query.get('page_size') || PAGE_SIZE_LIST
  });

  const statuses = [
    statusCreate,
    statusDelete,
    statusDeleteMulti,
    statusUpdate,
    statusActive,
    statusRecall,
    statusTransfer,
    statusExtend,
    statusImport
  ];

  useEffect(() => {

    if (statuses.some(status => status === STATUS_API.SUCCESS)) {
      const action = isAgency
        ? getListDeviceAgency({ data: params, id: agencyID })
        : getListDevice(params);

      dispatch(action);
    }
  }, [
    statusCreate,
    statusDelete,
    statusDeleteMulti,
    statusUpdate,
    statusActive,
    statusRecall,
    statusTransfer,
    statusExtend,
    statusImport
  ]);

  const getListDeviceWithParams = (data) => {
      if (isAgency) {
        const paramValue = Object.assign({}, params, data);
        setParams(paramValue);
        dispatch(
          getListDeviceAgency({
            data: paramValue,
            id: agencyID
          })
        );
      } else {
        const paramValue = Object.assign({}, params, data);
        setParams(paramValue);
        dispatch(getListDevice(paramValue));
      }
  };
  return (
    <>
      <div className={classes.root}>
            <div style={{ width: '98%', margin: '0 auto' }}>
          <Toolbar
            isLoading={isLoading}
            listAgencies={listAgencies}
            searchRef={getListDeviceWithParams}
          />
          <Box mt={2}>
            <Results
              listDevice={listDevice}
              totalDevice={totalDevice}
              isLoading={isLoading}
              getListDeviceRef={getListDeviceWithParams}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(2)
  }
}));

export default DeviceListView;
