import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { STATUS_API } from 'src/app/constant/config';
import { getListSim } from 'src/features/simSlice.js';
import Results from './Results.js';
import ToolBar from './ToolBar.js';

const ServiceSim = () => {
  const dispatch = useDispatch();

  const statusCreate = useSelector(state => state.simSlice.statusCreate);
  const statusDelete = useSelector(state => state.simSlice.statusDelete);
  const statusUpdate = useSelector(state => state.simSlice.statusUpdate);
  const statusDeleteMultiSim = useSelector(
    state => state.simSlice.statusDeleteMultiSim
  );
  const statusImport = useSelector(state => state.simSlice.statusImport);
  const listSims = useSelector(state => state.simSlice.listSims);

  const classes = useStyles();

  const getListSimsWithParams = data => {
    dispatch(getListSim(data));
  };

  useEffect(() => {
    getListSimsWithParams();
  }, [dispatch]);

  useEffect(() => {
    if (
      statusCreate === STATUS_API.SUCCESS ||
      statusDelete === STATUS_API.SUCCESS ||
      statusUpdate === STATUS_API.SUCCESS ||
      statusDeleteMultiSim === STATUS_API.SUCCESS ||
      statusImport === STATUS_API.SUCCESS
    ) {
      dispatch(getListSim());
    }
  }, [
    statusCreate,
    statusDelete,
    statusUpdate,
    statusDeleteMultiSim,
    statusImport
  ]);

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: '98%', margin: '0 auto' }}>
          <ToolBar searchRef={getListSimsWithParams} />
          <Results
            className="mt-3"
            listSims={listSims}
            getListSimRef={getListSimsWithParams}
          />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    //height: '100%',
    width: '100%',
    marginTop: '17px'
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 auto',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

export default ServiceSim;
