import {
  Box,
  Button,
  makeStyles,
  Popover,
  Typography,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Search as SearchIcon } from 'react-feather';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterBox from 'src/app/components/FilterBox';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { _convertObjectToQuery } from 'src/app/utils/apiService';
import { getListDevice, importDevice } from 'src/features/deviceSlice';
import { roles } from 'src/app/constant/roles';
import CreateDevice from './CreateDevice';
import { getListDeviceVersion } from 'src/features/versionDeviceSlice';

const Toolbar = ({ searchRef, isLoading, listAgencies }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;

  const fileInput = useRef(null);

  const [query, setQuery] = useState({
    agency_id: undefined,
    device_type: undefined,
    status: undefined,
    keywords: undefined,
    page: 1,
    page_size: PAGE_SIZE_LIST
  });

  const getListDeviceVersionWithParams = (data) => {
    dispatch(getListDeviceVersion(data))
 };

  const handleClickFilter = event => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    setOpenCreateModal(false);
  };

  const [file, setFile] = useState(null);


  const onEnterSearchInput = event => {
    if (!searchRef) return;
    if (event.keyCode === 13) {
      const queryValue = Object.assign({}, query, {
        keywords: event.target.value,
        page: 1
      });
      setQuery(queryValue);
      searchRef(queryValue);
    }
  };

  const changeTextInputSearch = event => {
    const queryValue = Object.assign({}, query, {
      keywords: event.target.value,
      page: 1
    });
    setQuery(queryValue);
  };

  const onFileChange = event => {
    upload(event.target.files[0]);
  };

  const upload = file => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      dispatch(importDevice(formData));
      setFile(null);
      document.getElementById('importDevice').value = '';
      dispatch(getListDevice(query));
    }
  };

  return (
    <div className={classes.root}>
      {openCreateModal && (<CreateDevice open={openCreateModal} onClose={closeModal} getListVersion = {getListDeviceVersionWithParams} />)}
      <Box>
        <Box className={classes.groupSearch}>
          <div className="col-3">
            <Typography
              style={{ fontSize: '24px', fontWeight: '600', color: '#0C1132' }}
            >
              Quản trị thiết bị
            </Typography>
          </div>
          <div className="col-9 d-flex justify-content-end">
            <Box>
              <TextField
                onKeyDown={onEnterSearchInput}
                onChange={changeTextInputSearch}
                className={'searchInputDevice'}
                //value={query.keyword}
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  style: {
                    fontSize: '14px'
                  }
                }}
                placeholder="Serial, biển số hoặc đại lý"
              />
            </Box>
            {isAdmin && 
              <>
                <Typography
                  color="secondary"
                  size="small"
                  variant="contained"
                  className="pt-2 mx-3 cursor-pointer"
                >
                  <span
                    className="cursor-pointer"
                    aria-describedby="filter-module"
                    onClick={handleClickFilter}
                    style={{ fontSize: '15px', fontWeight: 'bold' }}
                  >
                    <img
                      src={`/static/iconSvg/filter.svg`}
                      style={{ paddingRight: '5px', paddingBottom: '4px' }}
                    />
                    &nbsp; Lọc
                  </span>

                  <Popover
                    id="filter-module"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <FilterBox listAgencies={listAgencies} searchRef={searchRef} />
                  </Popover>
                </Typography>
                <input
                  name="file"
                  ref={fileInput}
                  id="importDevice"
                  onChange={onFileChange}
                  type="file"
                  hidden
                />
                <Typography
                  onClick={() => fileInput.current.click()}
                  color="secondary"
                  size="small"
                  variant="contained"
                  className="pt-2 mx-3 cursor-pointer"
                >
                  <span style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    <img
                      src={`/static/iconSvg/import.svg`}
                      style={{ paddingRight: '5px', paddingBottom: '4px' }}
                    />
                    &nbsp; Thêm từ file
                  </span>
                </Typography>
                <Button
                  onClick={() => setOpenCreateModal(true)}
                  style={{
                    marginLeft: '10px',
                    color: '#ffff',
                    height: '40px',
                    textTransform: 'none !important',
                    textTransform: 'none'
                  }}
                  color="secondary"
                  variant="contained"
                >
                  Thêm mới thiết bị
                </Button>
              </>
            }

          </div>
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  groupSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // overflowX: 'scroll',
    minWidth: '50%'
  },
  groupSearchLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  searchInput: {
    width: '353px'
  },
  exportBtn: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  shadowBox: {
    boxShadow: '0 2px 5px rgba(0,0,0,.18)'
  }
}));

export default Toolbar;
