import 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import ToolBar from 'src/app/components/ToolBarPlayback';

import { STATUS_API } from 'src/app/constant/config';


const PlaybackMenu = ({
  listVehicle
}) => {
  
  return (
        <ToolBar
          listVehicle={listVehicle}
        />
  );
};

export default React.memo(PlaybackMenu);
