import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  makeStyles,
  TextField
} from '@material-ui/core';
import React,{ useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { green } from '@material-ui/core/colors';
import { Formik } from 'formik';
import { STATUS_API } from 'src/app/constant/config';
import { getListAgencies } from 'src/features/agencySlice';
import { updateDeviceTransfer } from 'src/features/deviceSlice';
import * as Yup from 'yup';
import CustomDialog from 'src/app/components/CustomDialog';

const TransferDevice = ({ open, onClose, rowsSelected }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const statusTransfer = useSelector(state => state.agencySlice.statusTransfer);
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin = dataLogin && dataLogin.role && dataLogin.role.title === 'admin';
  const [agencySelected, setAgencySelected] = useState(0);

  useEffect(() => {
    if(isAdmin) dispatch(getListAgencies({page_size: 1000}));
  }, []);

  const handleSubmit = () => {
    if (agencySelected) {
      dispatch(
        updateDeviceTransfer({
          agency_id: agencySelected,
          list_device_id: rowsSelected
        })
      );
    }
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSelectAgency = e => {
    setAgencySelected(Number(e.target.value));
  };

  return (
    <CustomDialog title="Chuyển kho thiết bị" open={open} onClose={handleClose} sizeSmall={true}>
      <div>
        <Formik
          initialValues={{ agency_id: '0' }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            // agency_id: Yup.number()
            //   .max(100)
            //   .required('Tên đại lý không được để trống'),
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <InputLabel>
                    Chọn đại lý <span className="text-danger">*</span>
                  </InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    helperText={touched.agency_id && errors.agency_id}
                    margin="normal"
                    name="agency_id"
                    onBlur={handleBlur}
                    onChange={e => handleSelectAgency(e)}
                    SelectProps={{
                      native: true
                    }}
                    value={agencySelected}
                    variant="outlined"
                  >
                    <option>Chọn đại lý</option>
                    {listAgencies &&
                      listAgencies.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
              <Box my={3}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '15px',
                    '& .left-button': {
                      display: 'flex'
                    }
                  }}
                >
                  <Button
                    style={{
                      marginRight: '10px',
                      textTranform: 'none !important'
                    }}
                    onClick={() => onClose()}
                    className="btn-main btn-plain mx-3"
                    color="primary"
                    size="large"
                    variant="contained"
                  >
                    Thoát
                  </Button>
                  <Button
                    style={{
                      marginRight: '10px',
                      textTranform: 'none !important'
                    }}
                    className="btn-main mx-3"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Chuyển xuống
                  </Button>
                  {statusTransfer === STATUS_API.PENDING && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
}));

export default React.memo(TransferDevice);
