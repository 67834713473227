import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Popover,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import React, { useState } from 'react';
import { CAR_STATUS } from 'src/app/constant/config';
import CarStatusBar from '../maps/CarStatusBar';
import ToolBarMenu from './ToolBarMenu';
import VehicleTable from './VehicleTable';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
    width: '100%'
  },
  listInformation: {
    width: '100%',
    maxWidth: 375,
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper
  },
  popoverHeader: {
    textAlign: 'center'
  },
  textInforCar: {
    color: '#0C1132 !important',
    fontSize: '14px !important',
    lineHeight: '19.6px',
    fontWeight: 400,
    padding: '15px 0'
  }
}));

const MenuVideo = ({ vehiclesCamera4G, getVehicle, getDriverInfo }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statisticTracking, setStatisticTracking] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const iconInfo = open ? 'notice' : 'notice_gray';
  const id = open ? 'simple-popover' : undefined;

  const [deviceType, setDeviceType] = React.useState('');
  const [carStatus, setCarStatus] = useState('');

  const getStatusTracking = status => {
    const vehiclesStatus = vehiclesCamera4G.filter(
      vehicle => vehicle?.device_status === status
    );
    return vehiclesStatus;
  };

  React.useEffect(() => {
    setStatisticTracking({
      lost_gps_vehicles: getStatusTracking('lost_gps'),
      lost_gsm_vehicles: getStatusTracking('lost_gms'),
      running_vehicles: getStatusTracking('moving'),
      stop_vehicles: getStatusTracking('stopped'),
      over_speed_vehicles: getStatusTracking('over_speed')
    });
  }, [vehiclesCamera4G]);

  return (
    <Box
      style={{
        height: 'calc(100vh - 66px)',
        padding: 0
      }}
    >
      <ToolBarMenu
        vehicles={vehiclesCamera4G}
        deviceType={deviceType}
        setDeviceType={setDeviceType}
      />

      <Box
        style={{
          padding: '0 12px',
          borderRadius: '5px',
          boxShadow: 'unset',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '14px',
            marginBottom: '0px'
          }}
        >
          <Typography
            style={{ color: '#0C1132', font: '16px', fontWeight: 600 }}
          >
            Danh sách thiết bị
          </Typography>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              style={{
                paddingRight: '8px',
                color: '#D9042F',
                fontSize: '14px',
                fontWeight: '500'
              }}
            >
              Tổng: {vehiclesCamera4G?.length}/{vehiclesCamera4G?.length} thiết bị
            </Typography>

            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <span className="list_tracking_action">
                <img alt="icon" src="/static/iconSvg/sync.svg" />{' '}
              </span>
              <span
                className="list_tracking_action"
                aria-describedby={id}
                onClick={handleClick}
              >
                <img alt="icon" src={`/static/iconSvg/${iconInfo}.svg`} />
              </span>
            </Box>

            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <Typography className={classes.typography}>
                  <div className={classes.popoverHeader}>
                    <b> Chú thích </b>
                    <span className="float-right">
                      <img
                        alt="button_close"
                        className="list_tracking_action"
                        onClick={handleClose}
                        width="12px"
                        height="12px"
                        src="/static/iconSvg/close-btn.svg"
                      />
                    </span>
                  </div>

                  <List className={classes.listInformation}>
                    {CAR_STATUS &&
                      CAR_STATUS.map((car, index) => (
                        <>
                          <ListItem>
                            <ListItemAvatar>
                              <img
                                alt="icon"
                                src={`/static/iconSvg/carsv2/${car?.icon}.svg`}
                                className="imageCarInfo"
                              />
                            </ListItemAvatar>
                            <span className={classes.textInforCar}>
                              {car?.info}
                            </span>
                          </ListItem>
                          {CAR_STATUS.length !== index + 1 ? (
                            <Divider variant="inset" component="li" />
                          ) : null}
                        </>
                      ))}
                  </List>
                </Typography>
              </Popover>
            </Box>
          </Box>
        </Box>

        <VehicleTable
          getVehicle={getVehicle}
          getDriverInfo={getDriverInfo}
          deviceType={deviceType}
          carStatus={carStatus}
          listVehicle={vehiclesCamera4G}
        />

        <Box style={{ bottom: 30, position: 'absolute', width:'360px'}}>
          <CarStatusBar
            statusActive={carStatus}
            setCarStatus={setCarStatus}
            statisticVehicleTracking={statisticTracking}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(MenuVideo);
