import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AxiosAdapter from './AxiosAdapter';
import axios from 'axios';
import Cookie from 'js-cookie';
import {
  HTTP_GETTYPE,
  MSG_TIMEOUT_REQUEST,
  TIMEOUT_DEFAULT,
  STATUS_API
} from '../app/constant/config';
import { _convertObjectToQuery } from 'src/app/utils/apiService';

export const getListNotifications = createAsyncThunk(
  'notificationSlice/getListNotifications',
  (payload, { dispatch, rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      let fullUrl = `${process.env.REACT_APP_BACKEND_URL}/notifications?${_convertObjectToQuery(payload.params)}`
      axios({
        url: fullUrl,
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token')
        },
        timeout: TIMEOUT_DEFAULT
      })
        .then(res => {
          dispatch(getNumberNotificationUnRead())
          resolve({data: res.data, isRefresh: payload.isRefresh})
        })
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const getListAlerts = createAsyncThunk(
  'notificationSlice/getListAlerts',
  (payload, { dispatch, rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.REACT_APP_BACKEND_URL + `/notifications/alert?` +`${_convertObjectToQuery(payload.params)}`,
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token')
        },
        timeout: TIMEOUT_DEFAULT
      })
        .then(res => {
          resolve({data: res.data, isRefresh: payload.isRefresh})
        })
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const getNumberNotificationUnRead = AxiosAdapter.GetHttp(
  'notificationSlice/getNumberNotificationUnRead',
  '/notifications/not-read',
  HTTP_GETTYPE.ALL
);

export const subNotification = AxiosAdapter.HttpPost(
  'notificationSlice/subNotification',
  '/fcm_tokens'
);

export const readNotificationById = createAsyncThunk(
  'notificationSlice/readNotificationById',
  (payload, { dispatch, rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.REACT_APP_BACKEND_URL + `/notifications/read/${payload.id}`,
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token')
        },
        timeout: TIMEOUT_DEFAULT
      })
        .then(res => {
          dispatch(getListNotifications({params:{page:1, page_size:15}, isRefresh: true}))
          resolve(res.data)
        })
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const readAllNotificationById = createAsyncThunk(
  'notificationSlice/readAllNotificationById',
  (payload, { dispatch, rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.REACT_APP_BACKEND_URL + `/notifications/readAll/${payload.id}`,
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token')
        },
        timeout: TIMEOUT_DEFAULT
      })
        .then(res => {
          dispatch(getListNotifications({params:{page:1, page_size:15}, isRefresh: true}))
          resolve(res.data)
        })
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const unSubNotification = createAsyncThunk(
  'notificationSlice/unSubNotification',
  (payload, { rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.REACT_APP_BACKEND_URL + `/fcm_tokens/delete`,
        method: 'DELETE',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token')
        },
        data: payload.data,
        timeout: TIMEOUT_DEFAULT
      })
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState: {
    error: null,
    statusGet: null,
    isLoading: null,
    listNotification: [],
    numUnreadNotifications: null,
    listAlert: [],
  },

  reducers: {
    resetChange: state => {
      state.statusGet = null;
      state.isLoading = false;
      state.error = null;
    },
  },

  extraReducers: {
    [getListNotifications.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getListNotifications.fulfilled]: (state, action) => {
      const isRefresh = action.payload.isRefresh;
      state.statusGet = STATUS_API.SUCCESS;
      if(isRefresh) {
        state.listNotification = action.payload.data.payload.notification;
      } else {
        state.listNotification = [
          ...state.listNotification,
          ...action.payload.data.payload.notification
        ];
      }
      state.isLoading = false;
    },
    [getListNotifications.rejected]: (state, action) => {
      state.error = action.payload?.message || action.error;
      state.isLoading = false;
    },
    [getListAlerts.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getListAlerts.fulfilled]: (state, action) => {
      const isRefresh = action.payload.isRefresh;
      state.statusGet = STATUS_API.SUCCESS;
      if(isRefresh) {
        state.listAlert = action.payload.data.payload.alert;
      } else {
        state.listNotification = [
          ...state.listAlert,
          ...action.payload.data.payload.alert
        ];
      }
      state.isLoading = false;
    },
    [getListAlerts.rejected]: (state, action) => {
      state.error = action.payload?.message || action.error;
      state.isLoading = false;
    },
    [getNumberNotificationUnRead.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getNumberNotificationUnRead.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.numUnreadNotifications = action.payload.payload.total;
      state.isLoading = false;
    },
    [getNumberNotificationUnRead.rejected]: (state, action) => {
      state.error = action.payload?.message || action.error;
      state.isLoading = false;
    },
    [readNotificationById.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [readNotificationById.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.isLoading = false;
    },
    [readNotificationById.rejected]: (state, action) => {
      state.error = action.payload?.message || action.error;
      state.isLoading = false;
    }
  }
});

export const { resetChange } = notificationSlice.actions;

export default notificationSlice.reducer;
