import {CircularProgress, MenuItem, FormControl, Paper, Select, TextField, InputLabel, makeStyles, Box, Button} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS_API } from 'src/app/constant/config';
import { getListVod, setVehicleSelected } from 'src/features/playback';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px',
    width: 365,
    boxShadow: 'unset'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  titleLotrinh: {
    fontSize: '18px',
    color: '#D9042F',
    fontWeight: 700,
    marginBottom: '10px'
  },
  titleTime: {
    fontSize: '18px',
    color: '#0C1132',
    fontWeight: 600
  },
  formControl: {
    width: 340,
    marginTop: '10px',
    marginBottom: '10px'
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textRed: {
    color: 'red'
  }
}));

export default function ToolBarTracking({ listVehicle }) {
  const statusGetPositions = useSelector(
    state => state.deviceSlice.statusGetPositions
  );

  const classes = useStyles();
  const [license, setLicense] = React.useState('');
  const [startTime, setStartTime] = React.useState('');
  const [endTime, setEndTime] = React.useState('');
  const [playbackDate, setPlaybackDate] = React.useState('');
  const dispatch = useDispatch();
  const [vehicleItem, setVehicleItem] = useState('');

  const handleSearchDevice = (
    license,
    playbackDate,
    startTime,
    endTime,
    vehicleItem
  ) => {
    const startTimeZone = Math.floor(
      moment(`${playbackDate} ${startTime}`)
        .utcOffset(0)
        .unix()
    );
    const endTimeZone = Math.floor(
      moment(`${playbackDate} ${endTime}`)
        .utcOffset(0)
        .unix()
    );

    const channelId = `${vehicleItem?.device_serial}_01`;
    const data = {
      id: license,
      first_time: startTimeZone,
      last_time: endTimeZone,
      deviceSerial: vehicleItem?.device_serial,
      channelId: channelId,
      channelName: 'CH01',
      vehicleId: license
    };

    //GetVehiclePosition(data);
    //Lấy thông tin file playback

    dispatch(setVehicleSelected(data));
    dispatch(
      getListVod({
        channelId: data.channelId,
        start_time: data.first_time,
        end_time: data.last_time
        // channelId: '861245069435427_01',
        // start_time: '1732579980',
        // end_time: '1732662780'
      })
    );
  };

  const handleChangeLicense = event => {
    setLicense(event.target.value);
  };
  const handleChangePlaybackDate = event => {
    setPlaybackDate(event.target.value);
  };
  const handleChangeStartTime = event => {
    setStartTime(event.target.value);
  };

  const handleChangeEndTime = event => {
    setEndTime(event.target.value);
  };

  const handleSelectVehicle = vehicle => {
    setVehicleItem(vehicle);
  };

  const disabledLoadRoadMap = () => {
    if (startTime === '' || endTime === '' || !license) {
      return true;
    }
    return (
      statusGetPositions !== STATUS_API.SUCCESS && statusGetPositions !== null
    );
  };

  return (
    <Paper
      component="form"
      style={{
        padding: '0px 0px 20px 20px',
        width: 365,
        boxShadow: 'unset'
      }}
    >
      <Box>
        <FormControl
          size="small"
          className={classes.formControl}
          style={{ width: '100%' }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column ',
              width: '100%',
              borderColor: '#E5E5E8',
              borderWidth: '1px',
              borderStyle: 'solid',
              padding: '0.3em',
              borderRadius: '5px'
            }}
          >
            <Select
              style={{ width: '100%' }}
              value={license || ''}
              defaultValue={''}
              onChange={handleChangeLicense}
              displayEmpty
              disableUnderline
            >
              <MenuItem value="">
                <small style={{ fontSize: '14px' }}> Chọn phương tiện </small>
              </MenuItem>
              {listVehicle &&
                listVehicle.map(vehicle => (
                  <MenuItem
                    value={vehicle.device_id}
                    onClick={() => handleSelectVehicle(vehicle)}
                  >
                    <small style={{ fontSize: '14px' }}>
                      {vehicle.license_plate}
                    </small>
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </FormControl>
      </Box>
      <div>
        <span
          style={{
            fontSize: '16px',
            color: '#0C1132',
            fontWeight: 600
          }}
        >
          Chọn thời gian
        </span>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: '100%' }}
        >
          <form noValidate>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '12px'
              }}
            >
              <InputLabel
                style={{
                  fontSize: '14px',
                  position: 'relative',
                  top: -30,
                  left: -13
                }}
              >
                Từ ngày
              </InputLabel>
              <TextField
                style={{ width: '100%' }}
                id="date"
                type="date"
                variant="outlined"
                size='small'
                value={playbackDate || ''}
                className={classes.textField}
                onChange={handleChangePlaybackDate}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  style: {
                    fontSize: '14px'
                  }
                }}
              />
            </Box>
            <Box
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '10px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column ',
                  width: '50%',
                  borderColor: '#E5E5E8',
                  paddingRight: '0.2em'
                }}
              >
                <TextField
                  style={{ width: '100%' }}
                  id="start-time"
                  label="Thời gian bắt đầu"
                  type="time"
                  value={startTime || ''}
                  className={classes.textField}
                  onChange={handleChangeStartTime}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    style: {
                      fontSize: '14px'
                    }
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column ',
                  width: '50%',
                  borderColor: '#E5E5E8',
                  paddingLeft: '0.2em'
                }}
              >
                <TextField
                  style={{ width: '100%' }}
                  id="end-time"
                  label="Thời gian kết thúc"
                  type="time"
                  value={endTime || ''}
                  className={classes.textField}
                  onChange={handleChangeEndTime}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    style: {
                      fontSize: '14px'
                    }
                  }}
                />
              </div>
            </Box>
          </form>
        </FormControl>
      </div>

      <Button
        style={{
          background: '#D9042F',
          color: '#fff',
          paddingLeft: '30px',
          paddingRight: '30px',
          textTransform: 'inherit',
          fontSize: '16px',
          width: '100%',
          marginTop: '15px'
        }}
        disabled={disabledLoadRoadMap()}
        variant="contained"
        onClick={() => {
          handleSearchDevice(
            license,
            playbackDate,
            startTime,
            endTime,
            vehicleItem
          );
        }}
      >
        {statusGetPositions !== STATUS_API.SUCCESS &&
        statusGetPositions !== null ? (
          <>
            <CircularProgress size={24} style={{ color: 'white' }} />
            Vui lòng đợi ...
          </>
        ) : (
          <span> Tìm kiếm theo video </span>
        )}
      </Button>
    </Paper>
  );
}
