import { Box, Container, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Page from 'src/app/components/Page';

import { Typography } from 'antd';
import moment from 'moment';
import LoadingComponent from 'src/app/components/Loading';
import { PAGE_SIZE_IMAGE } from 'src/app/constant/config';
import { _convertObjectToQuery } from 'src/app/utils/apiService';
import { GetUserInfo } from 'src/features/authSlice';
import Result from './Result';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VideoPlaybackList = () => {
  const query = useQuery();
  const page = query.get('page');
  const name = query.get('license_plate');
  const start_date = query.get('start_date');
  const end_date = query.get('end_date');

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const roleUser = dataLogin && dataLogin.role?.title;
  const agencyId = dataLogin && dataLogin.agency?.id;
  const isLoading = useSelector(state => state.vodSlice.isLoading);

  const [currentPage, setCurrentPage] = React.useState(
    page ? Number.parseInt(page) : 1
  );

  //----------------- Reload page ---------------------
  const [params, setParams] = useState({
    page: page || 1,
    page_size: PAGE_SIZE_IMAGE,
    license_plate: name || '',
    start_date:
      Math.floor(
        moment(start_date)
          .utcOffset(0)
          .unix()
      ) ||
      moment()
        .subtract(7, 'd')
        .utcOffset(0)
        .unix() ||
      '',
    end_date:
      Math.floor(
        moment(end_date)
          .utcOffset(0)
          .unix()
      ) ||
      moment()
        .subtract(7, 'd')
        .utcOffset(0)
        .unix() ||
      ''
  });

  useEffect(() => {
    dispatch(GetUserInfo());
  }, [dispatch]);

    // if (true) {
    //   return (
    //     <Page className={classes.root} title="Video">
    //       <Container maxWidth={false}>
    //         <NotFoundView />
    //       </Container>
    //     </Page>
    //   );
    // }
  return (
   
      <Page className={classes.root}>
        <Container maxWidth={true} >
          <React.Fragment>
            <Typography
              style={{
                width: '100%',
                height: '36px',
                color: '#0C1132',
                display: 'flex',
                alignItems: 'center',
                // paddingLeft: '20px',
                fontWeight: 'bold',
                marginTop: '16px',
                fontSize: '20px'
              }}
            >
              Danh sách video phương tiện
            </Typography>
            <Box mt={3}>
              <React.Fragment>
                {isLoading ? (
                  <LoadingComponent />
                ) : (
                    <Result />
                )}
              </React.Fragment>
            </Box>
          
          </React.Fragment>
        </Container>
      </Page>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2)
  },
}));

export default VideoPlaybackList;
