import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import {
  STATUS_API
} from 'src/app/constant/config';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { updateServicePackage } from 'src/features/servicePackageSlice';
import CustomDialog from 'src/app/components/CustomDialog';

const EditServicePackage = ({open, onClose, servicePackage}) => {
      const classes = useStyles();
      const dispatch = useDispatch();
    
      const statusCreate = useSelector(state => state.servicePackageSlice.statusCreate);
      
      const handleSubmit = data => {
        if (!servicePackage || !servicePackage?.id) return;
        const newData = {
          name: data.name,
          version: data.version,
          cost: Number(data.cost),
          day_limit: Number(data.day_limit),
          description: data.description
        };

        dispatch(updateServicePackage({ id: servicePackage.id , data: newData }));
        onClose(); 
      };

      const handleClose = () => {
        onClose();
      };
    return ( 
      <CustomDialog title="Chỉnh sửa người dùng" open={open} onClose={handleClose} >
        <div>
                <Formik
                  initialValues={{
                    name: servicePackage.name || '',
                    version: servicePackage.version || '',
                    cost: servicePackage.cost || '',
                    day_limit: servicePackage.day_limit || '',
                    description: servicePackage.description || '',
                    device_type_name: servicePackage.device_type_name || '',
                  }}
                  validationSchema={Yup.object().shape({
                      device_type_id: Yup.number(),
                      version: Yup.string(),
                      name: Yup.string()
                      .max(100)
                      .required('Phiên bản không được để trống'),
                      cost: Yup.number(),
                      day_limit: Yup.number(),
                  })}
                  onSubmit={handleSubmit}
                >
                  {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values
                    }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>              
                      <Grid item md={6} xs={12}>
                          <InputLabel>Tên loại thiết bị </InputLabel>
                          <TextField
                            number
                            error={Boolean(
                              touched.version && errors.version
                            )}
                            disabled
                            className="input-no-fieldset"
                            fullWidth
                            helperText={touched.version && errors.version}
                            margin="normal"
                            name="version"
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.device_type_name || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel>Tên gói dịch vụ </InputLabel>
                          <TextField
                            number
                            error={Boolean(
                              touched.name && errors.name
                            )}
                            className="input-no-fieldset"
                            fullWidth
                            helperText={touched.name && errors.name}
                            margin="normal"
                            name="name"
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel>Phiên bản </InputLabel>
                          <TextField
                            number
                            error={Boolean(
                              touched.version && errors.version
                            )}
                            className="input-no-fieldset"
                            fullWidth
                            helperText={touched.version && errors.version}
                            margin="normal"
                            name="version"
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.version}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel>Giá gói cước </InputLabel>
                          <TextField
                            error={Boolean(
                              touched.cost && errors.cost
                            )}
                            className="input-no-fieldset"
                            fullWidth
                            helperText={touched.cost && errors.cost}
                            margin="normal"
                            name="cost"
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.cost}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel>Hạn gói cước (Ngày)</InputLabel>
                          <TextField
                            error={Boolean(
                              touched.day_limit && errors.day_limit
                            )}
                            className="input-no-fieldset"
                            fullWidth
                            helperText={touched.day_limit && errors.day_limit}
                            margin="normal"
                            name="day_limit"
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.day_limit}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <InputLabel>Mô tả</InputLabel>
                          <TextField
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            className="input-no-fieldset"
                            fullWidth
                            helperText={touched.description && errors.description}
                            margin="normal"
                            name="description"
                            size="small"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Box my={3}>
                        <div className={classes.groupButtonSubmit}>
                          <div className={classes.wrapper}>
                            <Button
                              style={{ marginRight: '10px', textTranform: 'none !important' }}
                              onClick={() => onClose()}
                              className="btn-main btn-plain mx-3"
                              color="primary"
                              size="large"
                              variant="contained"
                            >
                              Thoát
                            </Button>
                            <Button
                              style={{ marginRight: '10px', textTranform: 'none !important' }}
                              className="btn-main mx-3"
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              {'Chỉnh sửa'}
                            </Button>
                            {statusCreate === STATUS_API.PENDING && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </div>
                        </div>
                      </Box>
                    </form>
                  )}
                </Formik>
        </div>
      </CustomDialog>
     );
}

const useStyles = makeStyles(theme => ({
    
    root: {
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    groupButtonSubmit: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '15px',
  
      '& .left-button': {
        display: 'flex'
      }
    },
    wrapper: {
      position: 'relative'
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
  }));
 
export default EditServicePackage;