import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import {
  STATUS_API
} from 'src/app/constant/config';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getListProvinces } from 'src/features/provinceSlice';
import { getListAgencies } from 'src/features/agencySlice';
import { deviceType } from 'src/app/constant/deviceType';
import moment from 'moment';
import { createDevice } from 'src/features/deviceSlice';
import CustomDialog from 'src/app/components/CustomDialog';

const initStateFromValue = {
  serial : '',
  device_type_id : '',
  hardware_version : '',
  date_of_manufacture : ''
};

const CreateDevice = React.memo(({open, onClose, getListVersion}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const statusCreate = useSelector(state => state.staffSlice.statusCreate);
  const listDeviceVersion = useSelector(state => state.versionDeviceSlice.listDeviceVersion);

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === 'admin';

  const versionOption = listDeviceVersion?.map(item => {
    return {
      id: Number(item.id),
      version: item.version,
    };
  })

  const [initValue, setInitValue] = useState(initStateFromValue);

  const handleSubmit = data => {
    const newDevice = {
      ...data,
      date_of_manufacture: moment(data?.date_of_manufacture).format('yyyy-MM-DD'),
    }
    setInitValue(newDevice)
    dispatch(createDevice(newDevice));
    onClose();
  };

  const handleClose = () => {
    onClose();
    setInitValue(initStateFromValue);
  };

  const handleChoose = (e, setFieldValue) => {
    setFieldValue("device_type_id", Number(e.target.value));
    getListVersion({device_type_id : e.target.value});
  }

  useEffect(() => {
    dispatch(getListProvinces());
    if(isAdmin) dispatch(getListAgencies());
  }, [dispatch]);

  return (
    <CustomDialog title="Thêm mới thiết bị" open={open} onClose={handleClose} >
      <div>
        <Formik
          initialValues={initValue}
          validationSchema={Yup.object().shape({
            serial: Yup.string()
              .max(100)
              .required('Serial không được để trống'),
            device_type_id: Yup.string()
              .max(100)
              .required('Loại thiết bị không được để trống'),
            hardware_version: Yup.string()
                .max(100)
                .required('Phiên bản không được để trống'),
            date_of_manufacture: Yup.string()
                .max(100)
                .required('Ngày sản xuất không được để trống'),
          })}
          onSubmit={handleSubmit}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values
            }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <InputLabel>Serial <span style={{ color: 'red' }}>*</span></InputLabel>
                  <TextField
                    error={Boolean(
                      touched.serial && errors.serial
                    )}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.serial && errors.serial}
                    margin="normal"
                    name="serial"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.serial}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                
              
                <Grid item md={6} xs={12}>
                  <InputLabel>Loại thiết bị <span className="text-danger">*</span></InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    error={Boolean(
                      touched.device_type_id && errors.device_type_id
                    )}
                    helperText={touched.device_type_id && errors.device_type_id}
                    margin="normal"
                    name="device_type_id"
                    onBlur={handleBlur}
                    onChange={(e) => handleChoose(e, setFieldValue)}
                    SelectProps={{
                      native: true
                    }}
                    value={values.device_type_id}
                    variant="outlined"
                  >
                    <option>Chọn loại thiết bị</option>
                    {deviceType && deviceType.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Phiên bản <span style={{ color: 'red' }}>*</span></InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    error={Boolean(
                      touched.hardware_version && errors.hardware_version
                    )}
                    helperText={touched.hardware_version && errors.hardware_version}
                    margin="normal"
                    name="hardware_version"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.hardware_version}
                    SelectProps={{
                      native: true
                    }}
                    variant="outlined"
                    placeholder="Chọn phiên bản"
                  >
                    <option>Chọn phiên bản</option>
                    {versionOption &&
                      versionOption.map(option => (
                        <option key={option.id} value={option.version}>
                          {option.version}
                        </option>
                      ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Ngày sản xuất <span style={{ color: 'red' }}>*</span></InputLabel>
                  <TextField
                  size="small"
                  fullWidth
                  error={Boolean(
                      touched.date_of_manufacture && errors.date_of_manufacture
                  )}
                  helperText={touched.date_of_manufacture && errors.date_of_manufacture}
                  className="input-no-fieldset"
                  margin="normal"
                  name="date_of_manufacture"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.date_of_manufacture}
                  type="datetime-local"
                  variant="outlined"
                />
                </Grid>
                
              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{ marginRight: '10px', textTranform: 'none !important' }}
                      onClick={() => onClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{ marginRight: '10px', textTranform: 'none !important' }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Thêm mới
                    </Button>
                    {statusCreate === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
})

const useStyles = makeStyles(theme => ({
    root: {
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    groupButtonSubmit: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '15px',
  
      '& .left-button': {
        display: 'flex'
      }
    },
    wrapper: {
      position: 'relative'
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
  }));
 
export default CreateDevice;