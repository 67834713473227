import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { CreateSim } from './CreateSim';

const Toolbar = ({
  className,
  searchRef,
  clearSearchRef,
  isLoading,
  ...rest
}) => {
  const classes = useStyles();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const closeModal = () => {
    setOpenCreateModal(false);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {openCreateModal && <CreateSim open onClose={closeModal} />}
      <Box>
        <Box className={classes.groupSearch}>
          <div className={classes.groupSearchLeft}>
            <Typography
              style={{ fontSize: '24px', fontWeight: '600', color: '#0C1132' }}
            >
              Quản trị sim
            </Typography>
          </div>
          <Button
            style={{
              marginLeft: '10px',
              color: '#ffff',
            }}
            className={classes.styleInputSearch}
            color="secondary"
            variant="contained"
            onClick={() => setOpenCreateModal(true)}
          >
            Thêm Sim
          </Button>
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  groupSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // overflowX: 'scroll',
    minWidth: '50%'
  },
  groupSearchLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  searchInput: {
    width: '250px'
  },
  styleInputSearch: {
    height: '40px',
    textTransform: 'none',
    padding: '0px 1em'
  },
  exportBtn: {
    justifyContent: 'flex-end',
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  shadowBox: {
    boxShadow: '0 2px 5px rgba(0,0,0,.18)'
  }
}));

export default Toolbar;
