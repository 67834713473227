import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import LoadingComponent from 'src/app/components/Loading';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { useState } from 'react';

const StatisticWarehouseTable = ({listDeviceTransfer, totalTransfer, getListDeviceTransferRef, params, setParams}) => {
  const isLoading = useSelector(state => state.servicePackageSlice.isLoading);
  const [limit, setLimit] = useState(PAGE_SIZE_LIST);
  const [page, setPage] = useState(1);
  const classes = useStyles();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (!getListDeviceTransferRef) return;
    const newparams = Object.assign({}, params, { page: newPage });
    setParams(newparams);
    getListDeviceTransferRef(newparams);
  };

  return (
    <>
      <Card className={classes.root}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div>
            <PerfectScrollbar>
              {listDeviceTransfer && listDeviceTransfer.length ? (
                <Box minWidth={1050}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Ngày chuyển kho</TableCell>
                        <TableCell>Mã thiết bị</TableCell>
                        <TableCell>Serial</TableCell>
                        <TableCell>Phiên bản</TableCell>
                        <TableCell>Đại lý</TableCell>
                        <TableCell>Loại đại lý</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listDeviceTransfer.map((data, index) => (
                        <TableRow hover key={data?.id}>
                          <TableCell>{data?.timeTransfer ? moment(data?.timeTransfer).format('DD-MM-yyyy') : '-'}</TableCell>
                          <TableCell>{data?.device?.deviceType || '-'}</TableCell>
                          <TableCell>{data?.device?.serial || '-'}</TableCell>
                          <TableCell>{data?.device?.hardware_version || '-'}</TableCell>
                          <TableCell>{data?.device?.agency?.name || '-'}</TableCell>
                          <TableCell>{data?.device?.agency?.business?.title || '-'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <div className="text-center pb-5">
                  <img
                    src="/static/empty.png"
                    className="justify-content-center"
                  />
                  <h4> Danh sách trống </h4>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Card>
      {listDeviceTransfer && listDeviceTransfer?.length > 0 && (
        <div class="mt-3 mr-4 float-right">
          <Box style={{display:'flex', justifyContent:'center', alignItems:'center', gap:'15px'}}>
            <Typography style={{ fontSize:'14px'}}>Tổng: {totalTransfer}</Typography>
          <Pagination
            count={Math.ceil(totalTransfer / limit)}
            color='primary'
            size="small"
            onChange={handlePageChange}
            //page={Number(params.page)}
            showFirstButton
            showLastButton
          />
          </Box>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  groupAction: {
    display: 'flex',
    alignItems: 'center',
    '& .action': {
      cursor: 'pointer',
      padding: '0 5px',
      '&:hover': {
        color: '#3f51b5'
      }
    }
  },
  minWithColumn: {
    minWidth: '150px'
  }
}));

export default StatisticWarehouseTable;
