import { createSlice } from '@reduxjs/toolkit';
import { 
  HTTP_GETTYPE,
  PAGE_SIZE_IMAGE, STATUS_API,
} from 'src/app/constant/config';
import AxiosAdapter from './AxiosAdapter';
import { MESSAGE } from 'src/app/constant/message';

export const getListDeviceVersion = AxiosAdapter.GetHttp(
  'versionDeviceSlice/getListDeviceVersion',
  '/device_versions',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const createDeviceVersion = AxiosAdapter.HttpPost(
  'versionDeviceSlice/createDeviceVersion',
  '/device_versions',
  MESSAGE.CREATE_VERSIONDEVICE_SUCCESS,
  'Tạo mới phiên bản thiết bị thất bại'
);

export const updateDeviceVersion = AxiosAdapter.HttpPut(
  'versionDeviceSlice/createDeviceVersion',
  '/device_versions/',
  MESSAGE.UPDATE_VERSIONDEVICE_SUCCESS,
  'Cập nhật phiên bản thiết bị thất bại'
);

export const deleteDeviceVersion = AxiosAdapter.HttpDeleteMulti(
  'versionDeviceSlice/createDeviceVersion',
  '/device_versions',
  MESSAGE.DELETE_VERSIONDEVICE_SUCCESS,
  'Xóa phiên bản thiết bị thất bại'
);

export const versionDeviceSlice = createSlice({
  name: 'versionDeviceSlice',
  initialState: {
    listDeviceVersion: [],
    
    statusGet: null,
    statusCreate: null,
    statusUpdate: null,
    statusMultiDelete: null,
    error : null,
    totalVersion: 0,
    totalPage: 0,
    isLoading: false
  },

  reducers: {
    resetChange: state => {
      state.statusGet = null;
      state.error = null;
      state.statusMultiDelete = null;
      state.statusCreate = null;
      state.statusUpdate = null;
    }
  },
  extraReducers: {

    [getListDeviceVersion.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getListDeviceVersion.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.totalVersion = action.payload.payload.number_of_item;
      state.listDeviceVersion = action.payload.payload.result;
      state.totalPage =
        action.payload.payload.number_of_item % PAGE_SIZE_IMAGE === 0
          ? action.payload.payload.number_of_item / PAGE_SIZE_IMAGE
          : Math.ceil(action.payload.payload.number_of_item / PAGE_SIZE_IMAGE);

      state.isLoading = false;
    },
    [getListDeviceVersion.rejected]: (state, action) => {
      state.statusGet = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
      state.isLoading = false;
    },
    
    [createDeviceVersion.pending]: state => {
      state.statusCreate = STATUS_API.PENDING;
    },
    [createDeviceVersion.fulfilled]: (state, action) => {
      state.statusCreate = STATUS_API.SUCCESS;
    },
    [createDeviceVersion.rejected]: (state, action) => {
      state.statusCreate = STATUS_API.ERROR;
      state.error = action.payload.message;
    },

    [updateDeviceVersion.pending]: state => {
      state.statusUpdate = STATUS_API.PENDING;
    },
    [updateDeviceVersion.fulfilled]: (state, action) => {
      state.statusUpdate = STATUS_API.SUCCESS;
    },
    [updateDeviceVersion.rejected]: (state, action) => {
      state.statusUpdate = STATUS_API.ERROR;
      state.error = action.payload.message;
    },

    [deleteDeviceVersion.pending]: state => {
      state.statusMultiDelete = STATUS_API.PENDING;
    },
    [deleteDeviceVersion.fulfilled]: (state, action) => {
      state.statusMultiDelete = STATUS_API.SUCCESS;
    },
    [deleteDeviceVersion.rejected]: (state, action) => {
      state.statusMultiDelete = STATUS_API.ERROR;
      state.error = action.payload.message;
    }
  }
});
export const { resetChange } = versionDeviceSlice.actions;

export default versionDeviceSlice.reducer;
