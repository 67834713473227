import {
  Box,
  Button,
  Grid,
  InputLabel,
  makeStyles,
  TextField
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDialog from 'src/app/components/CustomDialog';
import { updateUser } from 'src/features/userSlice';
import * as Yup from 'yup';

function EditUser({ open, onClose, userSelected }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const listProvinces = useSelector(state => state.provinceSlice.listProvinces);

  const userStatus = [
    { 
      label: 'Hoạt động', 
      value: 1
    },
    { 
      label: 'Tạm dừng', 
      value: 0
    },
  ]

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = data => {
    if (!userSelected || !userSelected?.id) return;
    const newUser = {
      full_name: data.full_name,
      email: data.email,
      phone: data.phone,
      citizen_id: data.citizen_id,
      address: data.address,
      dob: 'nothing',
      status: data.status
    }
    dispatch(updateUser({ id: userSelected.id , data: newUser }));
    onClose();
  };

  return (
    <CustomDialog title="Chỉnh sửa người dùng" open={open} onClose={handleClose}>
      <div>
        <Formik
          initialValues={{
            full_name: userSelected.full_name || '',
            email: userSelected.email || '',
            phone: userSelected.phone || '',
            citizen_id: userSelected.citizen_id || '',
            address: userSelected.address || '',
            status: userSelected.status || ''
          }}
          validationSchema={Yup.object().shape({
            full_name: Yup.string()
              .max(100)
              .required('Tên không được để trống'),
            email: Yup.string()
              .email('Email chưa đúng định dạng')
              .required('email không được để trống'),
            phone: Yup.string()
              .matches(
                /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
                'Số điện thoại chưa đúng định dạng'
              )
              .required('Số điện thoại không được để trống'),
            address: Yup.string()
                .max(100)
                .required('Địa chỉ không được để trống'),
          })}
          onSubmit={handleSubmit}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values
            }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <InputLabel>Tên đăng nhập <span style={{ color: 'red' }}>*</span></InputLabel>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    className="input-no-fieldset"
                    helperText={touched.phone && errors.phone}
                    margin="normal"
                    size="small"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Tên người dùng <span style={{ color: 'red' }}>*</span></InputLabel>
                  <TextField
                    error={Boolean(
                      touched.full_name && errors.full_name
                    )}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.full_name && errors.full_name}
                    margin="normal"
                    name="full_name"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.full_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Tỉnh thành <span className="text-danger">*</span></InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    helperText={touched.agency_id && errors.agency_id}
                    margin="normal"
                    name="citizen_id"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('citizen_id', e.target.value)}
                    SelectProps={{
                      native: true
                    }}
                    value={values.citizen_id}
                    variant="outlined"
                  >
                    <option>Chọn tỉnh thành</option>
                    {listProvinces && listProvinces.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid> 
                <Grid item md={6} xs={12}>
                  <InputLabel>Địa chỉ<span style={{ color: 'red' }}>*</span></InputLabel>
                  <TextField
                    error={Boolean(
                      touched.address && errors.address
                    )}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.address && errors.address}
                    margin="normal"
                    name="address"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Email <span style={{ color: 'red' }}>*</span></InputLabel>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    margin="normal"
                    className="input-no-fieldset"
                    name="email"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>{' '}
                <Grid item md={6} xs={12}>
                  <InputLabel>Trạng thái <span className="text-danger">*</span></InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    helperText={touched.agency_id && errors.agency_id}
                    margin="normal"
                    name="status"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('status',Number(e.target.value))}
                    SelectProps={{
                      native: true
                    }}
                    value={values.status}
                    variant="outlined"
                  >
                    <option>Chọn trạng thái</option>
                    {userStatus && userStatus.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid> 

              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{ marginRight: '10px', textTranform: 'none !important' }}
                      onClick={() => handleClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{ marginRight: '10px', textTranform: 'none !important' }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Chỉnh sửa
                    </Button>
                    {/* {statusCreate === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )} */}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>

        
      </div>
    </CustomDialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
}));

export default React.memo(EditUser);
