import { Box, Button } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const NotificationList = ({
  notifications,
  onClickNotification,
  handleLoadMoreNotification
}) => {
  const [page, setPage] = useState(1); 
  const [viewAll, setViewAll] = useState(true);
  const numUnreadNotifications = useSelector(state => state.notificationSlice.numUnreadNotifications);
  const filteredNotifications = viewAll
    ? notifications
    : notifications.filter(item => !item.is_read);

  
  const handleLoadMore = () => {
    let countNext = page;
    countNext = countNext + 1;
    setPage(countNext)
    handleLoadMoreNotification(countNext);
  };
  
  return (
    <Box
      style={{
        overflow: 'auto',
        maxHeight: 'inherit',
        backgroundColor: '#ffffff'
      }}
    >
      <Box display={'flex'} alignItems="center" p={1}>
        <Button
          size="small"
          style={{ fontWeight: 500, color: viewAll ? '#D9042F' : '#8B8C9B' }}
          onClick={() => setViewAll(true)}
        >
          Tất cả
        </Button>
        <Button
          size="small"
          style={{ fontWeight: 500, color: !viewAll ? '#D9042F' : '#8B8C9B' }}
          onClick={() => setViewAll(false)}
        >{`Chưa đọc (${numUnreadNotifications})`}</Button>
      </Box>
      {filteredNotifications && filteredNotifications.length > 0 ? (
        filteredNotifications.map(entry => {
          return (
            <Box
              key={entry.id}
              onClick={() => onClickNotification(entry)}
              style={{
                padding: ' 10px 30px 0 4px',
                display: 'flex',
                flexDirection: 'row',
                justifyItems: 'center',
                backgroundColor: entry.is_read
                  ? 'rgb(255, 255, 255)'
                  : 'rgba(255, 145, 145, 0.25)',
                cursor: 'pointer'
              }}
            >
              <Box
                style={{
                  width: '30px',
                  padding: '0 25px',
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <img
                  alt=""
                  style={{ width: 56 }}
                  src={`/static/iconNoti/notiIcon.svg`}
                />
              </Box>
              <Box style={{ flex: 1, minWidth: 0, color: '#03294a' }}>
                <Box
                  style={{
                    fontStyle: 'normal',
                    fontWeight: entry.is_read ? 500 : 'bold',
                    fontSize: '14px',
                    lineHeight: '20px',
                    alignItems: 'center',
                    textAlign: 'justify'
                  }}
                >
                  {entry.message}
                </Box>
                <Box
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: '#828282',
                    padding: '10px 0'
                  }}
                >
                   {moment(entry.created_at).format('HH:mm:ss DD-MM-yyyy')}
                </Box>
              </Box>
            </Box>
          );
        })
      ) : (
        <Box
          style={{
            padding: ' 10px 30px 0 4px',
            cursor: 'pointer',
            color: '#03294a'
          }}
        >
          <Box style={{ textAlign: 'center', padding: '10px 0 24px' }}>
            Danh sách thông báo trống
          </Box>
        </Box>
      )}

      {filteredNotifications.length > 0 && filteredNotifications.length < 300 && (
        <Box style={{padding:"20px 10px 10px 10px"}}>
          <Button
            onClick={handleLoadMore}
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              padding:'10px',
              width: '100%',
              backgroundColor:'#D9042F',
            }}
          >
            <span style={{ 
              margin:'0 auto',
              color:'#ffffff',
              fontWeight:'bold',
            }}>
              Xem thêm
            </span>
          </Button>
        </Box>
      )}
    </Box>
  );
};
