import { Box, CircularProgress } from '@material-ui/core';
import { Typography } from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generate_token } from 'src/app/utils/commomService';
import { closeLoading, showLoading } from 'src/features/videoSlice';
import styled from 'styled-components';
import VGPSPlayer from './VGPSPlayer';
import './style.css';

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  background-color: #4c4c4c;
  background-clip: content-box;
  //border: 1px 0px 0px 1px solid #333333;
  border: 0.5px solid #333333;
  object-fit: fill;
`;
const CDN_URL = process.env.REACT_APP_CDN_WSS_SERVER;

const Video = ({ id, height, controls, onSignalStatusChange }) => {
  const ref = useRef();

  useEffect(() => {
    const videoEl = ref.current;

    if (videoEl) {
      const handleError = () => onSignalStatusChange(id, false);
      const handleCanPlay = () => onSignalStatusChange(id, true);

      videoEl.addEventListener('error', handleError);
      videoEl.addEventListener('canplay', handleCanPlay);

      return () => {
        videoEl.removeEventListener('error', handleError);
        videoEl.removeEventListener('canplay', handleCanPlay);
      };
    }
  }, [id, onSignalStatusChange]);
  return (
    <Box style={{ height: height, width: '-webkit-fill-available' }}>
      <StyledVideo
        playsInline
        autoPlay
        ref={ref}
        id={id}
        //controls={controls}
      />
    </Box>
  );
}

const VideoWall = React.memo(({ deviceSerial }) => {
  const dispatch = useDispatch();
  const [videoMode, setVideoMode] = useState(false);
  const isShowLoading = useSelector((state) => state.videoSlice.isShowLoading);
  const [signalStatus, setSignalStatus] = useState({});
  const cameras = videoMode
    ?  [
      //HD
      {
        id: `${deviceSerial}_05`,
        name: 'CH5',
        isMain: false,
      },
      {
        id: `${deviceSerial}_07`,
        name: 'CH7',
        isMain: false
      },
      {
        id: `${deviceSerial}_01`,
        name: 'CH1',
        isMain: false
      },
      {
        id: `${deviceSerial}_03`,
        name: 'CH3',
        isMain: true
      },
    ]
    :  
    [
      //SD
      {
        id: `${deviceSerial}_08`,
        name: 'CH8',
        isMain: false,
      },
      {
        id: `${deviceSerial}_06`,
        name: 'CH6',
        isMain: false
      },
      {
        id: `${deviceSerial}_02`,
        name: 'CH2',
        isMain: false
      },
      {
        id: `${deviceSerial}_04`,
        name: 'CH4',
        isMain: true
      },
     
    ]
  
  const [listCameras, setListCameras] = useState([]);

  useEffect(() => {
    setListCameras(cameras);
  }, [videoMode]);

  var players = [];
  useEffect(() => {
    if (!deviceSerial) return;

    if (players.length > 0) {
      for (let l of players) {
        l.stop();
      }
      players = [];
    }
    for (let c of listCameras) {
      if (c.id) {
        let token = generate_token(32);
        var ws_url = `${CDN_URL}/evup/${token}/${c.id}`;
        const player = new VGPSPlayer(c.id, { transport: ws_url });
        players.push(player);
      }
    }

    return () => {
      if (players.length > 0) {
        for (let l of players) {
          l.stop();
        }
        players = [];
      }
    };
  }, [listCameras]);
 
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(closeLoading());
    }, 5000);

    return () => clearTimeout(timeout); // Dọn dẹp timer khi component unmount
  }, [signalStatus]);

  const handleSignalStatusChange = (cameraId, hasSignal) => {
    setSignalStatus(prev => ({ ...prev, [cameraId]: hasSignal }));
  };

  const cameraMain = listCameras.find(c => c.isMain === true);
  const otherCameras = listCameras.filter(camera => !camera.isMain);

  const handleChangeMain = (cameraId) => {
    dispatch(showLoading());
    const listCamerasClone = _.cloneDeep(listCameras);
    // current
    const trueElementIndex = listCamerasClone.findIndex((item) => item.isMain === true);

    // select
    const inputElementIndex = listCamerasClone.findIndex((item) => item.id === cameraId);
  
    if (
      inputElementIndex !== -1 &&
      trueElementIndex !== -1 
      && listCamerasClone[inputElementIndex].isMain === false
    ) {
    
      const temp = listCamerasClone[trueElementIndex];
      listCamerasClone[trueElementIndex] = listCamerasClone[inputElementIndex];
      listCamerasClone[inputElementIndex] = temp;
   
      listCamerasClone[trueElementIndex].isMain = true;
      listCamerasClone[inputElementIndex].isMain = false;
    } 
    setListCameras(listCamerasClone);
  }

  const handleChangeVideoSolution = () => {
    setVideoMode(!videoMode);
    dispatch(showLoading());
  }

  return (
    <>
      <Box
        className="row"
        style={{
          position: 'relative',
          left: 0,
          top: 0,
          backgroundColor: '#000000'
        }}
      >
        <Box className={'col-12 px-0'}>
          <Box
            style={{
              height: '66vh',
              position: 'relative'
              // cursor: 'pointer',
            }}
          >
            <Video
              id={cameraMain?.id}
              height={'66vh'}
              controls={cameraMain?.isMain}
              onSignalStatusChange={handleSignalStatusChange}
            />
            {isShowLoading && (
              <Box
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -100%)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '20px'
                }}
              >
                <Box>
                  <CircularProgress color="inherit" />
                </Box>
                <Typography color="inherit">
                  Vui lòng đợi trong giây lát
                </Typography>
                {/* <LoadingComponent color="inherit"/> */}
              </Box>
            )}
            {!signalStatus[cameraMain?.id] && !isShowLoading && (
              <Typography
                style={{
                  color: '#fff',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                NO SIGNAL
              </Typography>
            )}
          </Box>
        </Box>

        {otherCameras.map((camera, index) => (
          <Box key={index} className={'col-4 px-0'}>
            <Box
              style={{
                height: '22vh',
                position: 'relative',
                cursor: 'pointer'
              }}
              onClick={() => handleChangeMain(camera.id)}
            >
              <Video
                id={camera.id}
                height={'22vh'}
                controls={camera.isMain}
                onSignalStatusChange={handleSignalStatusChange}
              />
              {isShowLoading && (
                <Box
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  <CircularProgress color="inherit" />
                </Box>
              )}
              {!signalStatus[camera.id] && !isShowLoading && (
                <Box
                  style={{
                    color: '#fff',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  NO SIGNAL
                </Box>
              )}
            </Box>
          </Box>
        ))}

        <Box
          style={{
            display: 'flex',
            backgroundColor: '#D9042F',
            height: '50px',
            padding: '0 25px',
            position: 'absolute',
            left: '20',
            bottom: -50,
            width: '100%'
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Box style={{ display: 'flex', gap: '20px' }}>
              <Typography
                style={{
                  color: '#ffffff',
                  cursor: 'pointer',
                  display: 'inline-block'
                }}
                onClick={() => handleChangeVideoSolution()}
              >
                {videoMode === true ? 'HD' : 'SD'}
              </Typography>
            </Box>
            <Box style={{ display: 'flex', gap: '15px', cursor: 'pointer' }}>
              <Box>
                <img src="/static/iconSvg/grid/grid4_icon.svg" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});
export default VideoWall;
