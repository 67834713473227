import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  HTTP_GETTYPE,
  PAGE_SIZE_IMAGE,
  STATUS_API,
  MSG_TIMEOUT_REQUEST,
  TIMEOUT_DEFAULT,
  messageToastType_const,
} from 'src/app/constant/config';
import AxiosAdapter from './AxiosAdapter';
import axios from 'axios';
import Cookie from 'js-cookie';
import { MESSAGE } from 'src/app/constant/message';
import { showGlobalToast } from './uiSlice';

export const getListDriver = AxiosAdapter.HttpGetById(
  'driverSlice/GetListDriver',
  '/drivers/agency',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const getListDriverAdmin = AxiosAdapter.GetHttp(
  'driverSlice/GetListDriver',
  '/drivers'
);

export const createDriver = AxiosAdapter.HttpPost(
  'driverSlice/createDriver',
  '/drivers',
  MESSAGE.CREATE_DRIVER_SUCCESS,
  'Tạo lái xe thất bại'
);

export const getListDriverLicenseType = AxiosAdapter.GetHttp(
  'driver/drivertype',
  '/driver_license_type',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const updateDrivers = AxiosAdapter.HttpUpdateById(
  'driverSlice/updateDrivers',
  '/drivers/agency',
  MESSAGE.UPDATE_DRIVER_SUCCESS,
  'Cập nhật lái xe thất bại'
);

export const deleteDrivers = createAsyncThunk(
  'deviceSlice/deleteDrivers',
  (payload, { dispatch, rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/drivers/agency/${payload.agencyId}`,
        method: 'Delete',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token'),
          timeout: TIMEOUT_DEFAULT
        },
        data: payload.listAgenciesId
      })
        .then(res => {
          if (MESSAGE.DELETE_DRIVER_SUCCESS) {
            dispatch(
              showGlobalToast({
                message: MESSAGE.DELETE_DRIVER_SUCCESS,
                type: messageToastType_const.success
              })
            );
          }
          resolve(res.data);
        })
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          if ('Xóa lái xe thất bại') {
            dispatch(
              showGlobalToast({
                message: err?.response?.data?.message || 'Xóa lái xe thất bại',
                type: messageToastType_const.error
              })
            );
          }
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const driverSlice = createSlice({
  name: 'driverSlice',
  initialState: {
    listDriver: null,
    totalDriver: 0,
    statusGet: null,
    statusCreate: null,
    statusDelete: null,
    statusDeleteMulti: null,
    statusUpdate: null,
    errors: null,
    totalPage: 0,
    isLoading: false
  },

  reducers: {
    resetChange: state => {
      state.statusGet = null;
      state.userSelected = null;
      state.selectedLicensePlate = null;
      state.statusCreate = null;
      state.statusDelete = null;
      state.statusDeleteMulti = null;
      state.statusUpdate = null;
      state.errors = null;
    }
  },
  extraReducers: {
    [getListDriver.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getListDriver.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.listDriver = action.payload.payload.drivers;
      state.totalDriver = action.payload.payload.numberOfItem;
      state.totalPage =
        action.payload.payload.numberOfItem % PAGE_SIZE_IMAGE === 0
          ? action.payload.payload.numberOfItem / PAGE_SIZE_IMAGE
          : Math.ceil(action.payload.payload.numberOfItem / PAGE_SIZE_IMAGE);

      state.isLoading = false;
    },
    [getListDriver.rejected]: (state, action) => {
      state.statusGet = STATUS_API.ERROR;
      state.errors = action.payload?.message || action.error;
      state.isLoading = false;
    },
    [getListDriverAdmin.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getListDriverAdmin.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.listDriver = action.payload.payload.drivers;
      state.totalDriver = action.payload.payload.numberOfItem;
      state.totalPage =
        action.payload.payload.numberOfItem % PAGE_SIZE_IMAGE === 0
          ? action.payload.payload.numberOfItem / PAGE_SIZE_IMAGE
          : Math.ceil(action.payload.payload.numberOfItem / PAGE_SIZE_IMAGE);

      state.isLoading = false;
    },
    [getListDriverAdmin.rejected]: (state, action) => {
      state.statusGet = STATUS_API.ERROR;
      state.errors = action.payload?.message || action.error;
      state.isLoading = false;
    },
    [createDriver.pending]: state => {
      state.statusCreate = STATUS_API.PENDING;
    },
    [createDriver.fulfilled]: (state, action) => {
      state.statusCreate = STATUS_API.SUCCESS;
    },
    [createDriver.rejected]: (state, action) => {
      state.statusCreate = STATUS_API.ERROR;
      state.errors = action.payload?.message || action.error;
    },
    [getListDriverLicenseType.fulfilled]: (state, action) => {
      state.listDriverLicenseType = action.payload;
    },
    [deleteDrivers.pending]: state => {
      state.statusDelete = STATUS_API.PENDING;
    },
    [deleteDrivers.fulfilled]: (state, action) => {
      state.statusDelete = STATUS_API.SUCCESS;
    },
    [deleteDrivers.rejected]: (state, action) => {
      state.statusDelete = STATUS_API.ERROR;
      state.errors = action.payload.message || action.error;
    },
    [updateDrivers.pending]: state => {
      state.statusUpdate = STATUS_API.PENDING;
    },
    [updateDrivers.fulfilled]: (state, action) => {
      state.statusUpdate = STATUS_API.SUCCESS;
    },
    [updateDrivers.rejected]: (state, action) => {
      state.statusUpdate = STATUS_API.ERROR;
      state.errors = action.payload?.message || action.error;
    }
  }
});
export const { resetChange } = driverSlice.actions;

export default driverSlice.reducer;
