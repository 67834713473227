import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MenuBoxTracking from 'src/app/components/maps/MenuBoxTracking';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';
import './style.css';
import { Box } from '@material-ui/core';
import {
  getListVehicleTracking,
  getListVehicleTrackingById,
  getListVehicleTrackingByUserId
} from 'src/features/vehicleSlice';
import RotateIcon from 'src/app/utils/RotateIcon';
import { Typography } from '@material-ui/core';

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  LayersControl,
  useMap
} from 'react-leaflet';
import L from 'leaflet';
import mkIcon from '../../assets/mkicon.png';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
import { getIconStatus, vehicleStates } from 'src/app/utils/vehicleService';
import LicensePlateTag from 'src/app/components/licensePlateTag/LicesePlateTag';
import ContentCarInfo from 'src/app/components/popupInfocar/ContentCarInfo';
import { useQuery } from 'src/app/hooks/useQuery';
import { convertIdToDeviceType } from 'src/app/utils/commomService';

const ResetView = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    const interval = setInterval(() => {
      map.setView([center[0] - 0.002, center[1]]);
    }, 5000);

    return () => clearInterval(interval);
  }, [center, map]);

  return null;
};

const MapOsm = () => {
  const [mapRef, setMapRef] = useState(null);
  const markerRef = useRef({});
  const dispatch = useDispatch();

  const query = useQuery();
  const device_type_id = query.get('device_type_id');
  const agency_id = query.get('agency_id');

  const defaultQueryFilter = {
    device_type_id: device_type_id ? device_type_id : '',
    agency_id: agency_id ? agency_id : ''
  };
  const [filterQuery, setFilterQuery] = useState(defaultQueryFilter);
  const deviceType = convertIdToDeviceType(Number(filterQuery.device_type_id));

  const animationRef = useRef({});
  const vehiclePositionsRef = useRef({});

  useEffect(() => {
    setFilterQuery(defaultQueryFilter);
  }, [device_type_id, agency_id]);

  const listVehicleTracking = useSelector(
    state => state.vehicleSlice.listVehicleTracking
  );

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin = useSelector(state => state.authSlice.isAdmin);

  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === 'agency';
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;
  const isEndUser =
    dataLogin && dataLogin.role && dataLogin.role.title === 'enduser';
  const userID =
    isEndUser && dataLogin.endUser ? dataLogin.endUser.id : undefined;

  const statisticVehicleTracking = useSelector(
    state => state.vehicleSlice.statisticVehicleTracking
  );

  const fetchData = () => {
    if (isAdmin) {
      dispatch(getListVehicleTracking(filterQuery));
    } else if (isAgency) {
      dispatch(
        getListVehicleTrackingById({
          id: agencyID,
          data: { device_type_id: filterQuery.device_type_id }
        })
      );
    } else if (isEndUser) {
      dispatch(
        getListVehicleTrackingByUserId({
          id: userID,
          data: { device_type: deviceType }
        })
      );
    }
  };

  useEffect(() => {
    let intervalId;
    fetchData();
    intervalId = window.setInterval(fetchData, 5000);

    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [isAdmin, isAgency, agencyID, userID, dispatch, filterQuery]);

  useEffect(() => {
    if (!listVehicleTracking || listVehicleTracking.length === 0) return;

    const startAnimations = () => {
      listVehicleTracking.forEach(vehicle => {
        const deviceId = vehicle.device_id;
        const newPosition = [Number(vehicle.lat), Number(vehicle.lng)];
        const oldPosition =
          vehiclePositionsRef.current[deviceId] || newPosition;

        if (animationRef.current[deviceId]) {
          cancelAnimationFrame(animationRef.current[deviceId]);
        }

        let step = 0;
        const steps = 50;

        const animate = () => {
          if (step >= steps) {
            vehiclePositionsRef.current[deviceId] = newPosition;
            return;
          }

          const progress = step / steps;
          const interpolatedLat =
            oldPosition[0] + (newPosition[0] - oldPosition[0]) * progress;
          const interpolatedLng =
            oldPosition[1] + (newPosition[1] - oldPosition[1]) * progress;

          if (markerRef.current[deviceId]) {
            markerRef.current[deviceId].setLatLng([
              interpolatedLat,
              interpolatedLng
            ]);
          }

          step++;
          animationRef.current[deviceId] = requestAnimationFrame(animate);
        };

        animate();
        vehiclePositionsRef.current[deviceId] = oldPosition;
      });
    };

    startAnimations();

    return () => {
      Object.values(animationRef.current).forEach(animationId => {
        cancelAnimationFrame(animationId);
      });
    };
  }, [listVehicleTracking]);

  const trim = x => {
    return x.replace(/^\(+|\)+$/gm, '');
  };

  const centerDefaultLocal = localStorage.getItem('center')
    ? trim(localStorage.getItem('center')).split(', ')
    : [21.024683, 105.832495];

  const centerDefault = [
    Number(centerDefaultLocal[0]),
    Number(centerDefaultLocal[1])
  ];

  const [positionsInfoBox, setPositionsInfoBox] = useState({});
  const [center, setCenter] = useState(centerDefault);
  const [showMenu, setShowMenu] = useState(true);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [vehicleSelected, setVehicleSelected] = useState({});
  const [carStatus, setCarStatus] = useState('');

  const handleMarkerClick = vehicle => {
    if (vehicle) {
      if (vehicleSelected.device_id === vehicle.device_id) {
        setVehicleSelected({});
        setPositionsInfoBox({});
        if (markerRef.current[vehicle.device_id]) {
          markerRef.current[vehicle.device_id].closePopup();
        }
      } else {
        getVehicle(vehicle);
        setPositionsInfoBox({
          active_at: vehicle.active_at,
          date_debt: vehicle.date_debt,
          daily_distance: vehicle.daily_distance,
          location: vehicle.location,
          device_serial: vehicle.device_serial,
          max_speed: vehicle.max_speed,
          memory_card: vehicle.memory_card,
          phone_number: vehicle.phone_number,
          register_package_day: vehicle.register_package_day,
          remaining_days: vehicle.remaining_days,
          device_type: vehicle?.device_type,
          is_overspeed: vehicle?.is_overspeed,
          remaining_days: vehicle?.remaining_days,
          sim: vehicle?.sim,
          phone: vehicle?.phone,
          license_plate: vehicle.license_plate,
          lat: Number(vehicle?.lat),
          lng: Number(vehicle?.lng),
          created_at: vehicle.created_at,
          air_condition_status: vehicle.air_condition_status,
          engine_status: vehicle.engine_status,
          battery: vehicle.battery,
          charge_status: vehicle.charge_status,
          speed_gps: vehicle.speed_gps,
          door_status: vehicle.door_status,
          stop_times: vehicle.stop_times,
          is_stopping: vehicle.is_stopping,
          total_distance: vehicle.total_distance,
          total_overspeed: vehicle.total_overspeed,
          total_drive_time: vehicle.total_drive_time,
          driver_login: vehicle.driver_login,
          driver_logout: vehicle.driver_logout,
          driver_name: vehicle.driver_name,
          driver_license: vehicle.driver_license,
          non_stop_driving_time: vehicle.non_stop_driving_time
        });
      }
    }
  };

  const getVehicle = vehicle => {
    if (vehicle) {
      if (vehicleSelected.device_id === vehicle.device_id) {
        setVehicleSelected({});
        setPositionsInfoBox({});
        if (markerRef.current[vehicle.device_id]) {
          markerRef.current[vehicle.device_id].closePopup();
        }
      } else {
        setVehicleSelected(vehicle);
        setPositionsInfoBox({
          ...vehicle,
          lat: Number(vehicle.lat),
          lng: Number(vehicle.lng)
        });
        setCenter([Number(vehicle.lat), Number(vehicle.lng)]);
        mapRef.setView([Number(vehicle.lat), Number(vehicle.lng)], 15);
        if (markerRef.current[vehicle.device_id]) {
          markerRef.current[vehicle.device_id].openPopup();
        }
      }
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setCenter([position.coords.latitude, position.coords.longitude]);
        setCurrentLocation([
          position.coords.latitude,
          position.coords.longitude
        ]);

        if (!mapRef) return;
        mapRef.flyTo(
          [position.coords.latitude, position.coords.longitude],
          15,
          {
            animate: true
          }
        );
      });
    }
  };

  //#region Display Icons
  const handleDisplayIcon = (vehicle, statisticVehicleTracking) => {
    if (!vehicle || !statisticVehicleTracking) {
      return L.icon({
        iconUrl: '/static/iconSvg/cars/car_lost_gsm.svg',
        iconAnchor: [0, 0]
      });
    }
    const iconVehicle = getIconStatus(
      vehicle.device_status,
      vehicle.device_type
    );

    let makerIcon;

    if (vehicle && statisticVehicleTracking) {
      for (const { list } of vehicleStates) {
        if (statisticVehicleTracking[list]?.includes(vehicle.license_plate)) {
          let iconObject = RotateIcon.makeIcon(iconVehicle);
          if (typeof iconObject.setRotation === 'function') {
            iconObject = iconObject.setRotation({
              deg: vehicle.direction ? vehicle.direction : 0.001
            });
          }
          const url =
            typeof iconObject.getUrl === 'function'
              ? iconObject.getUrl()
              : mkIcon;
          makerIcon = L.icon({
            iconUrl: url,
            iconAnchor: [16, 16]
          });
          break;
        }
      }
    }
    return (
      makerIcon ||
      L.icon({
        iconUrl: '/static/iconSvg/cars//car_lost_gsm.svg',
        iconAnchor: [0, 0]
      })
    );
  };

  const displayLocationIcon = () => {
    let locationCurrent;
    locationCurrent = L.icon({
      iconUrl: `/static/iconSvg/gps.svg`,
      iconSize: [25, 25],
      iconAnchor: [0, 25]
    });
    return locationCurrent;
  };

  return (
    <>
      <Box className="show_menu">
        <Box
          title="Hiện Menu"
          onClick={() => setShowMenu(true)}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'all 0.3s ease-in'
          }}
        >
          <ArrowRight />
        </Box>
      </Box>

      <Box
        style={{
          position: 'absolute',
          cursor: 'pointer',
          top: 22,
          left: 384,
          zIndex: 999,
          backgroundColor: '#ffffff',
          borderRadius: '0 8px 8px 0',
          boxShadow: 'rgb(220,220,220) 3px 3px 6px 0px inset',
          width: '28px',
          height: '48px',
          display: showMenu ? 'block' : 'none',
          transition: 'all .3s'
        }}
      >
        <Box
          title="Ẩn Menu"
          onClick={() => setShowMenu(false)}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ArrowLeft />
        </Box>
      </Box>

      <Box
        style={{
          position: 'absolute',
          bottom: '80px',
          right: '20px',
          cursor: 'pointer',
          boxShadow: 'rgba(20, 20, 20, 0.322)  0px 0px 2px 0px',
          border: '0px !important',
          padding: '8px',
          color: 'red',
          width: '48px',
          height: '48px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '50%',
          zIndex: 1000
        }}
        onClick={() => getCurrentLocation()}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img src="/static/iconSvg/locationIconv2.svg" alt="location" />
        </Box>
      </Box>

      <MenuBoxTracking
        getVehicle={getVehicle}
        showMenu={showMenu}
        setCarStatus={setCarStatus}
        carStatus={carStatus}
        idSelected={vehicleSelected.device_id || undefined}
      />

      <MapContainer
        center={center}
        zoom={13}
        maxZoom={20}
        minZoom={6}
        style={{
          height: 'calc(100vh - 66px)',
          position: 'relative',
          width: '100%'
        }}
        zoomControl={false}
        scrollWheelZoom={true}
        whenCreated={mapR => setMapRef(mapR)}
        zoomAnimation={true}
      >
        <LayersControl position="bottomright">
          {/* <LayersControl.BaseLayer name="Open Street Map">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer> */}
          <LayersControl.BaseLayer name="Vệ tinh">
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}"
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              maxZoom={20}
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer checked name="Google Map">
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=m&hl=vi&x={x}&y={y}&z={z}"
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              maxZoom={20}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <FullscreenControl
          forceSeparateButton={true}
          position="topright"
          content='<img src="/static/iconSvg/fullscreen.svg" style="width: 24px; height: 24px;" />'
        />

        {listVehicleTracking && listVehicleTracking.length > 0
          ? listVehicleTracking.map((vehicle, index) => {
              const popupHeight =
                vehicle.device_type === 'VGPS_MOTO4G' ? 350 : 470;
              if (carStatus && carStatus === vehicle.device_status) {
                return (
                  <>
                    {vehicle ? (
                      <Marker
                        key={index}
                        position={[Number(vehicle?.lat), Number(vehicle?.lng)]}
                        eventHandlers={{
                          click: () => handleMarkerClick(vehicle)
                        }}
                        icon={handleDisplayIcon(
                          vehicle,
                          statisticVehicleTracking
                        )}
                        ref={ref => {
                          if (!markerRef.current) {
                            markerRef.current = {};
                          }
                          if (ref) {
                            markerRef.current[vehicle.device_id] = ref;
                          }
                        }}
                      >
                        <Popup
                          offset={[0, 0]}
                          autoPan={true}
                          autoPanPaddingTopLeft={[20, 40]}
                          maxWidth={350} // Tùy chỉnh chiều rộng tối đa của popup (đơn vị: px)
                          minWidth={250} // Chiều rộng tối thiểu (tùy chọn)
                          maxHeight={400} // Chiều cao tối đa (nếu muốn giới hạn và có cuộn)
                        >
                          <ContentCarInfo positionsInfoBox={positionsInfoBox} />
                        </Popup>

                        {vehicleSelected.license_plate ===
                        vehicle.license_plate ? (
                          <Tooltip
                            direction="center"
                            offset={[0, 5]}
                            autoPan={true}
                            opacity={1}
                            permanent={true}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <img
                                src="/static/iconSvg/focusIcon.svg"
                                alt="focus"
                              />
                            </Box>
                            <Typography
                              style={{
                                backgroundColor: '#F5FAFF',
                                padding: '1px 4px',
                                fontSize: '12px',
                                width: 'max-content !important',
                                borderRadius: '0.1em',
                                color: 'rgb(0, 0, 0)',
                                fontWeight: 'bold',
                                marginTop: '0px',
                                marginLeft: '-3px'
                              }}
                            >
                              {vehicle.license_plate}
                            </Typography>
                            <Box>
                              <ResetView
                                center={[
                                  Number(vehicle.lat),
                                  Number(vehicle.lng)
                                ]}
                              />
                            </Box>
                          </Tooltip>
                        ) : (
                          <LicensePlateTag
                            position={vehicle}
                            license_plate={vehicle.license_plate}
                          />
                        )}
                      </Marker>
                    ) : null}
                  </>
                );
              }
              if (!carStatus) {
                return (
                  <>
                    {vehicle ? (
                      <Marker
                        key={index}
                        position={[Number(vehicle?.lat), Number(vehicle?.lng)]}
                        icon={handleDisplayIcon(
                          vehicle,
                          statisticVehicleTracking
                        )}
                        eventHandlers={{
                          click: () => handleMarkerClick(vehicle)
                        }}
                        ref={ref => {
                          if (!markerRef.current) {
                            markerRef.current = {};
                          }
                          if (ref) {
                            markerRef.current[vehicle.device_id] = ref;
                          }
                        }}
                      >
                        <Popup
                          offset={[0, 0]}
                          autoPan={true}
                          autoPanPaddingTopLeft={[20, 40]}
                          maxWidth={350} 
                          minWidth={250} 
                          maxHeight={400} 
                        >
                          <ContentCarInfo positionsInfoBox={positionsInfoBox} />
                        </Popup>

                        {vehicleSelected.license_plate ===
                        vehicle.license_plate ? (
                          <Tooltip
                            direction="center"
                            offset={[0, 5]}
                            autoPan={true}
                            opacity={1}
                            permanent={true}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <img
                                src="/static/iconSvg/focusIcon.svg"
                                alt="focus"
                              />
                            </Box>
                            <Typography
                              style={{
                                backgroundColor: '#F5FAFF',
                                padding: '1px 4px',
                                fontSize: '12px',
                                width: 'max-content !important',
                                borderRadius: '0.1em',
                                color: 'rgb(0, 0, 0)',
                                fontWeight: 'bold',
                                marginTop: '0px',
                                marginLeft: '-3px'
                              }}
                            >
                              {vehicle.license_plate}
                            </Typography>
                            <Box>
                              <ResetView
                                center={[
                                  Number(vehicle.lat),
                                  Number(vehicle.lng)
                                ]}
                              />
                            </Box>
                          </Tooltip>
                        ) : (
                          <LicensePlateTag
                            position={vehicle}
                            license_plate={vehicle.license_plate}
                          />
                        )}
                      </Marker>
                    ) : null}
                  </>
                );
              }
            })
          : null}

        {currentLocation && (
          <Marker icon={displayLocationIcon()} position={currentLocation}>
            <Tooltip
              style={{
                background: 'rgba(0, 0, 0, 0) !important',
                boxShadow: 'none !important'
              }}
              position={currentLocation}
              direction="center"
              permanent
            >
              <Box
                style={{
                  backgroundColor: 'rgba(19, 19, 19, 0.493)',
                  color: 'white',
                  borderRadius: '5px',
                  padding: '2px',
                  position: 'absolute',
                  top: 5,
                  left: -22
                }}
              >
                {' '}
                Vị trí của bạn
              </Box>
            </Tooltip>
          </Marker>
        )}
      </MapContainer>
    </>
  );
};

export default React.memo(MapOsm);
