import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import {
  STATUS_API
} from 'src/app/constant/config';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { updateVehicleType } from 'src/features/vehicleTypeSlice';
import CustomDialog from 'src/app/components/CustomDialog';


function EditVehicleType({ open, onClose, vehicleType }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusCreate = useSelector(state => state.driverSlice.statusCreate);

  const handleSubmit = data => {
    if (!vehicleType || !vehicleType?.id) return;
    const newData = {
      slots: Number(data.slots),
      title: data.title,
      tonnage: data.tonnage
    };
    dispatch(updateVehicleType({ id: vehicleType.id , data: newData }));
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog title="Chỉnh sửa loại phương tiện" open={open} onClose={handleClose}>
      <div>
        <Formik
          initialValues={{
            title: vehicleType.title || '',
            slots: vehicleType.slots || '',
            tonnage: vehicleType.tonnage || ''
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string()
              .max(100)
              .required('Tên không được để trống'),
            slots: Yup.number()
              .max(100)
              .required('Số chỗ không được để trống'),
            tonnage: Yup.string()
            .max(100)
            .required('Tải trọng không được để trống'),
          })}
          onSubmit={handleSubmit}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <InputLabel>Tên loại phương tiện </InputLabel>
                  <TextField 
                    error={Boolean(
                      touched.title && errors.title
                    )}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.title && errors.title}
                    margin="normal"
                    name="title"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                  
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Số chỗ </InputLabel>
                  <TextField
                    number
                    error={Boolean(
                      touched.slots && errors.slots
                    )}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.slots && errors.slots}
                    margin="normal"
                    name="slots"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.slots}
                  
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Tải trọng </InputLabel>
                  <TextField
                    error={Boolean(
                      touched.tonnage && errors.tonnage
                    )}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.tonnage && errors.tonnage}
                    margin="normal"
                    name="tonnage"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.tonnage}
                  
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{ marginRight: '10px', textTranform: 'none !important' }}
                      onClick={() => onClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{ marginRight: '10px', textTranform: 'none !important' }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Cập nhật
                    </Button>
                    {statusCreate === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
}));

export default React.memo(EditVehicleType);
