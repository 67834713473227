import {
  Box,
  Card,
  Checkbox,
  Fade,
  List,
  ListItem,
  makeStyles,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import DialogConfirm from 'src/app/components/DialogConfirm';
import { MESSAGE } from 'src/app/constant/message';
import { deleteDrivers } from 'src/features/driverSlice';
import { closeDialogConfirm, showDialogConfirm } from 'src/features/uiSlice';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import LoadingComponent from 'src/app/components/Loading';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import EditDriver from './EditDriver';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    whiteSpace: 'nowrap'
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap'
  },
  root: {
    padding: '6px'
  }
}))(TableCell);

const Results = ({
  className,
  listDriver,
  getListDriverRef,
  totalAgency,
  actionDeleteUserRef,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.driverSlice.isLoading);
  const totalDriver = useSelector(state => state.driverSlice.totalDriver);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const classes = useStyles();
  const [limit, setLimit] = useState(PAGE_SIZE_LIST);
  const [selectedItem, setSelectedItem] = useState();
  const [itemCurrent, setItemCurrent] = useState();
  const [rowsSelected, setRowsSelected] = useState([]);

  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    page: page,
    page_size: limit
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleClickMenu = (event, driver) => {
    setAnchorEl(event.currentTarget);
    setItemCurrent(driver);
    setSelectedItem(driver.id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleDeleteDriver = () => {
    dispatch(showDialogConfirm());

    handleCloseMenu();
  };
  const closeModalEdit = () => {
    setOpenEditModal(false);
  };
  const handleEditDrive = () => {
    setOpenEditModal(true);
    handleCloseMenu();
  };

  const confirmDelete = () => {
    const listId =
      rowsSelected?.length > 0
        ? rowsSelected.map(id => String(id))
        : [String(selectedItem)];
    dispatch(
      deleteDrivers({
        agencyId: dataLogin.agency.id,
        listAgenciesId: { list_driver_id: listId }
      })
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (!getListDriverRef) return;
    const newparams = Object.assign({}, params, { page: newPage });
    setParams(newparams);
    getListDriverRef(newparams);
  };

  const checked = id => {
    return rowsSelected.indexOf(id) !== -1;
  };
  const select = driver => {
    if (rowsSelected.indexOf(driver) === -1) {
      const newSelected = [driver, ...rowsSelected];
      setRowsSelected(newSelected);
    } else {
      const newSelected = rowsSelected.filter(i => i !== driver);
      setRowsSelected(newSelected);
    }
  };

  const checkAll = () => {
    if (rowsSelected.length > 0) {
      setRowsSelected([]);
    }

    if (listDriver.length > 0 && rowsSelected.length !== listDriver.length) {
      const all = listDriver.map(item => {
        return item.id;
      });
      setRowsSelected(all);
    }
  };

  return (
    <>
      {openEditModal && (
        <EditDriver
          open={openEditModal}
          driverSelected={itemCurrent}
          onClose={closeModalEdit}
        />
      )}
      <DialogConfirm
        textOk={MESSAGE.BTN_YES}
        textCancel={MESSAGE.BTN_CANCEL}
        title={MESSAGE.CONFIRM_DELETE_DRIVER}
        content={MESSAGE.CONTENT_DELETE_DRIVER}
        callbackOk={() => confirmDelete()}
        callbackCancel={() => dispatch(closeDialogConfirm())}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div>
            {rowsSelected.length > 0 && (
              <Fade in={rowsSelected.length > 0}>
                <Box
                  minWidth={1050}
                  style={{ fontSize: '14px', padding: '6px 4px' }}
                >
                  <span>
                    <Checkbox
                      checked={rowsSelected.length > 0}
                      onChange={() => checkAll()}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </span>

                  <span
                    onClick={handleDeleteDriver}
                    className="mx-2 btnDevice"
                    color="primary"
                    variant="outlined"
                    size="small"
                  >
                    <img
                      src={`/static/iconSvg/delete-icon.svg`}
                      alt="delete"
                      style={{ paddingRight: '5px', paddingBottom: '4px' }}
                    />
                    <span className="pt-1"> Xóa </span>
                  </span>
                </Box>
              </Fade>
            )}
            <PerfectScrollbar>
              {listDriver && listDriver.length ? (
                <Box minWidth={1050}>
                  <Table>
                    {rowsSelected.length === 0 && listDriver?.length > 0 && (
                      <TableHead>
                        <TableRow>
                          <StyledTableCell width={50}>
                            <Checkbox
                              checked={rowsSelected.length > 0}
                              onChange={() => checkAll()}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>Tên tài xế</StyledTableCell>
                          <StyledTableCell>Số điện thoại</StyledTableCell>
                          <StyledTableCell>Email</StyledTableCell>
                          <StyledTableCell>Số GPLX</StyledTableCell>
                          <StyledTableCell>Loại bằng</StyledTableCell>
                          <StyledTableCell>Ngày cấp</StyledTableCell>
                          <StyledTableCell>Ngày hết hạn</StyledTableCell>
                          <StyledTableCell>Địa chỉ</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>
                    )}
                    <TableBody>
                      {(listDriver || []).map((driver, index) => {
                        if (!driver) return null;
                        return (
                          <TableRow hover key={driver.id}>
                            <StyledTableCell
                              style={{ maxWidth: '40px' }}
                              onClick={() => select(driver?.id)}
                            >
                              <Checkbox
                                checked={checked(driver.id)}
                                onChange={() => select(driver.id)}
                                color="primary"
                                inputProps={{
                                  'aria-label': 'secondary checkbox'
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              {driver.name || '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {driver.phone || '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {driver.email || '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {driver.license_number || '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {driver.license_type || '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {driver.license_issue_date
                                ? moment(driver.license_issue_date).format(
                                    'DD-MM-yyyy'
                                  )
                                : '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {driver.license_expire_date
                                ? moment(driver.license_expire_date).format(
                                    'DD-MM-yyyy'
                                  )
                                : '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {driver.address || '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              <span style={{ color: '#AEB1C5' }}>
                                <MoreVertIcon
                                  aria-describedby={`menu-device-${driver.id}`}
                                  className="cursor-pointer hover-red"
                                  onClick={e => handleClickMenu(e, driver)}
                                />
                              </span>

                              <Popover
                                id={`menu-device-${driver.id}`}
                                className="popover-device"
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleCloseMenu}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <Typography>
                                  <List>
                                    <ListItem
                                      onClick={handleEditDrive}
                                      className="border-bottom list-menu-device cursor-pointer"
                                    >
                                      Chỉnh sửa
                                    </ListItem>
                                    <ListItem
                                      onClick={handleDeleteDriver}
                                      className="cursor-pointer list-menu-device"
                                    >
                                      <span className="text-danger">Xóa</span>
                                    </ListItem>
                                  </List>
                                </Typography>
                              </Popover>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <div className="text-center pb-5">
                  <img src="/static/empty.png" class="justify-content-center" />
                  <h4> Danh sách trống </h4>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Card>
      {listDriver && listDriver.length > 0 && (
        <div class="mt-3 mr-4 float-right">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15px'
            }}
          >
            <Typography style={{ fontSize: '14px' }}>
              Tổng: {totalDriver}
            </Typography>
            <Pagination
              count={Math.ceil(totalDriver / limit)}
              color='primary'
              size="small"
              onChange={handlePageChange}
              page={Number(params.page)}
              showFirstButton
              showLastButton
            />
          </Box>
        </div>
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  listDriver: PropTypes.array.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  groupAction: {
    display: 'flex',
    alignItems: 'center',
    '& .action': {
      cursor: 'pointer',
      padding: '0 5px',
      '&:hover': {
        color: '#3f51b5'
      }
    }
  },
  minWithColumn: {
    minWidth: '150px'
  }
}));

export default Results;
