import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/app/layouts/DashboardLayout';
import MainLayout from 'src/app/layouts/MainLayout';
import LoginView from 'src/app/views/auth/LoginView';
import RegisterView from 'src/app/views/auth/RegisterView';
import NotFoundView from './app/views/errors/NotFoundView';
import DraftPageView from './app/views/errors/DraftPageView';
import MapViewCamera from './app/views/streamCamera';
import Playback from './app/views/playback';

import DeviceListView from './app/views/device';
import ImageListView from './app/views/image/ImageListView';
import MapView from './app/views/map';
import ManageView from './app/views/manage';
import BaoCaoBGTView from './app/views/baoCaoBGT';
import HanhTrinhChayXeControlView from './app/views/baoCaoBGT/HanhTrinhChayXe';
import BaoCaoDungDoControlView from './app/views/baoCaoBGT/BaoCaoDungDo';
import BaoCaoTongHopTheoLaiXeControlView from './app/views/baoCaoBGT/BaoCaoTongHopTheoLaiXe';
import BaoCaoTongHopTheoXeControlView from './app/views/baoCaoBGT/BaoCaoTongHopTheoXe';
import QuaTocDoGioihanControlView from './app/views/baoCaoBGT/QuaTocDoGioihan';
import ThoiGianLaiXeLienTucControlView from './app/views/baoCaoBGT/ThoiGianLaiXeLienTuc';
import TocDoCuaXeControlView from './app/views/baoCaoBGT/TocDoCuaXe';

import BaoCaoPhuLuc5ControlView from './app/views/baoCaoTT09/BaoCaoPhuLuc5';
import BaoCaoPhuLuc8ControlView from './app/views/baoCaoTT09/BaoCaoPhuLuc8';
import BaoCaoPhuLuc14ControlView from './app/views/baoCaoTT09/BaoCaoPhuLuc14';
import BaoCaoPhuLuc16ControlView from './app/views/baoCaoTT09/BaoCaoPhuLuc16';
import BaoCaoPhuLuc17ControlView from './app/views/baoCaoTT09/BaoCaoPhuLuc17';
import BaoCaoPhuLuc19ControlView from './app/views/baoCaoTT09/BaoCaoPhuLuc19';
import BaoCaoPhuLuc9ControlView from './app/views/baoCaoTT09/BaoCaoPhuLuc9';
import MainControlView from './app/views/manage/mainControl';
import MainBaoCaoDNiew from './app/views/baoCaoDN/mainControl';
import DriverListView from './app/views/manage/driver';
import TrackingView from './app/views/tracking';
import MemberManage from './app/views/manage/users';
import VehicleType from './app/views/manage/vehicles/vehicle_types';
import AgenciesManage from './app/views/manage/agency';
import MonitorView from './app/views/manage/monitor/MonitorView';
import VideoPlaybackList from './app/views/playbackVideo/PlaybackVideo';
import PrivacyPolicy from './app/views/privacyPolicy';
import ServicePackageView from './app/views/manage/service-packages';
import StatisticWarehouseTransfer from './app/views/manage/StatisticWarehouseTransfer/StatisticWarehouseTransfer';
import StatisticDeviceActive from './app/views/manage/StatisticDeviceActive/StatisticDeviceActive';
import StatisticServices from './app/views/manage/StatisticServices/StatisticServices';
import MainControlStatistic from './app/views/manage/mainControl/MainControlStatistic';
import ServiceStaff from './app/views/manage/staff';
import BaoCaoHinhAnh from './app/views/baoCaoBGT/BaoCaoHinhAnh/BaoCaoHinhAnh';
import VersionView from './app/views/manage/versions';
import ForbiddenPage from './app/views/errors/ForbiddenPage'
import UserProfileView from './app/views/profile/UserProfile';
import SimView from './app/views/manage/sim'
const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <UserProfileView /> },
      { path: 'tracking', element: <TrackingView /> },
      { path: 'map', element: <MapView /> },
      { path: 'image', element: <ImageListView /> },
    
      { 
        path: 'camera', 
        element: <MonitorView/>,
        children: [ 
          { 
            path: 'streaming', 
            element: <MapViewCamera/>
          },
          { 
            path: 'playback', 
            element: <VideoPlaybackList/>
          },
        ],
      },
      { path: 'playback', element: <Playback /> },

      //manage
      {
        path: 'manage',
        element: <ManageView />,
        children: [
          { 
            path: 'control', element: <MainControlView />
          },
          {
            path: 'statistics',
            element: <MainControlStatistic/>,
            children: [
              { 
                path: 'warehouse-transfer', 
                element: <StatisticWarehouseTransfer/>
              },
              { 
                path: 'active', 
                element: <StatisticDeviceActive/>
              },
              { 
                path: 'service', 
                element: <StatisticServices/>
              },
            ]
          },
          {
            path: 'users',
            element: <MemberManage />
          },
          {
            path: 'agencies',
            element: <AgenciesManage/>
          },
          {
            path: 'service-staffs',
            element: <ServiceStaff/>
          },
          {
            path: 'service-packages',
            element: <ServicePackageView />
          },
          {
            path: 'vehicle-types',
            element: <VehicleType />
          },
          {
            path: 'devices',
            element: <DeviceListView/>
          },
          {
            path: 'versions',
            element: <VersionView/>
          },
          {
            path: 'sim',
            element: <SimView/>
          },
          {
            path: 'broadcasts-schedules',
            element: <ManageView />
          },
          {
            path: 'files-audio',
            element: <ManageView />
          },
          {
            path: 'files-video',
            element: <ManageView />
          },
       
          {
            path: 'driver',
            element: <DriverListView /> 
          },
        ]
      },

      //reports BGT
      {
        path: 'baocao-bgt',
        element: <BaoCaoBGTView />,
        children: [
          { 
            path: 'tt073', 
            children: [
              {
                path: 'hanh-trinh-chay-xe',
                element: <HanhTrinhChayXeControlView />
              },
              { 
                path: 'bao-cao-dung-do', 
                element: <BaoCaoDungDoControlView /> 
              },
    
              {
                path: 'bao-cao-tong-hop-theo-lai-xe',
                element: <BaoCaoTongHopTheoLaiXeControlView />
              },
    
              {
                path: 'bao-cao-tong-hop-theo-xe',
                element: <BaoCaoTongHopTheoXeControlView />
              },
    
              {
                path: 'qua-toc-do-gioi-han',
                element: <QuaTocDoGioihanControlView />
              },
    
              {
                path: 'thoi-gian-lai-xe-lien-tuc',
                element: <ThoiGianLaiXeLienTucControlView />
              },
    
              { 
                path: 'toc-do-cua-xe', 
                element: <TocDoCuaXeControlView />
              },
              { 
                path: 'bao-cao-hinh-anh', 
                element: <BaoCaoHinhAnh/>
              },
            ]
          },
          
          { 
            path: 'tt09', 
            children: [
              { path: 'tt09-phu-luc-5', element: <BaoCaoPhuLuc5ControlView /> },
              { path: 'tt09-phu-luc-8', element: <BaoCaoPhuLuc8ControlView /> },
              { path: 'tt09-phu-luc-14', element: <BaoCaoPhuLuc14ControlView /> },
              { path: 'tt09-phu-luc-16', element: <BaoCaoPhuLuc16ControlView /> },
              { path: 'tt09-phu-luc-17', element: <BaoCaoPhuLuc17ControlView /> },
              { path: 'tt09-phu-luc-9', element: <BaoCaoPhuLuc9ControlView/> },
              { path: 'tt09-phu-luc-19', element: <BaoCaoPhuLuc19ControlView /> }
            ]
          },

          {
            path: 'baocao-dn',
            children: [
              { path: 'control', element: <MainBaoCaoDNiew /> },  
              { 
                path: 'bao-cao-ve-tong-hop-hoat-dong-theo-nhom', 
              },
              { 
                path: 'bao-cao-tong-hop-hoat-dong', 
              },
              { 
                path: 'bao-cao-chi-tiet-hoat-dong', 
              },
              { 
                path: 'bao-cao-lich-hen', 
              },
              { 
                path: 'bao-cao-tong-hop-km-xe-hoat-dong', 
              },
              { 
                path: 'bao-cao-tong-hop-bat-dieu-hoa', 
              },
              { 
                path: 'bao-cao-dung-do', 
              },
              { 
                path: 'bao-cao-ra-vao-tram', 
              },
              { 
                path: 'bao-cao-chuyen-kinh-doanh', 
              },
              { 
                path: 'bao-cao-bat-dieu-hoa', 
              },
              { 
                path: 'bao-cao-thu-phi-duong-bo', 
              },
              { 
                path: 'bao-cao-cuoc-qua-diem-thu-phi', 
              },
              { 
                path: 'bao-cao-hoat-dong-thang', 
              },
              { 
                path: 'bao-cao-hoat-hanh-trinh', 
              },
              { 
                path: 'bao-cao-mo-cua', 
              },
              { 
                path: 'bao-cao-nang-ha-ben', 
              },
              { 
                path: 'bao-cao-nang-ha-cau', 
              },
              { 
                path: 'bao-cao-dong-co', 
              },
              { 
                path: 'bao-cao-trang-thai-dong-co', 
              },
              { 
                path: 'bao-cao-loi-xung', 
              },
              { 
                path: 'bao-cao-mat-tin-hieu', 
              },
            ]
          },
        ]
      },

      { path: '*', element: <Navigate to="/app/tracking" /> }
    ]
  },

  {
    path: '/',
    element: <MainLayout />,
    children: [
      {path: 'privacy-policy', element: <PrivacyPolicy />},
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: 'waiting-confirm', element: <DraftPageView /> },
      { path: '/', element: <Navigate to="/app/p2p?role=viewer" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: "/403", element: <ForbiddenPage /> },
    ]
  }
];

export default routes;
