import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { STATUS_API } from 'src/app/constant/config';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { updateSim } from 'src/features/simSlice';
import CustomDialog from 'src/app/components/CustomDialog';

export const EditSim = React.memo(({ open, onClose, selectedSim }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusUpdate = useSelector(state => state.simSlice.statusUpdate);

  const handleSubmit = data => {
    if (!selectedSim || !selectedSim?.id) return;
    const newSim = {
      data: {
        phone: data.phone,
        imei_sim: data.imei_sim,
        active_at: data.active_at
      },
      id: selectedSim.id
    };
    dispatch(updateSim(newSim));
    handleClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog title="Chỉnh sửa sim" open={open} onClose={handleClose}>
      <div>
        <Formik
          initialValues={{
            phone: selectedSim?.phone || '',
            imei_sim: selectedSim?.imei_sim || '',
            active_at: selectedSim?.active_at || ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            imei_sim: Yup.string()
              .max(100)
              .required('Imei sim không được để trống'),
            phone: Yup.string()
              .matches(
                /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
                'Số điện thoại chưa đúng định dạng'
              )
              .required('Số điện thoại không được để trống'),
            active_at: Yup.date().required('Ngày kích hoạt không được để trống')
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Số điện thoại <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    className="input-no-fieldset"
                    helperText={touched.phone && errors.phone}
                    margin="normal"
                    size="small"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Imei sim <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.imei_sim && errors.imei_sim)}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.imei_sim && errors.imei_sim}
                    margin="normal"
                    name="imei_sim"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.imei_sim}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Ngày kích hoạt<span style={{ color: 'red' }}>*</span>{' '}
                  </InputLabel>
                  <TextField
                    fullWidth
                    margin="normal"
                    type="date"
                    name="active_at"
                    value={
                      values.active_at ? values.active_at.split('T')[0] : ''
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.active_at && errors.active_at)}
                    helperText={touched.active_at && errors.active_at}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      onClick={() => handleClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Lưu lại
                    </Button>
                    {statusUpdate === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
});

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  shadowBox: {
    boxShadow: '0 2px 5px rgba(0,0,0,.18)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  formHeader: {
    padding: theme.spacing(3)
  },
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  avatar: {
    height: 100,
    width: 100
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  disableForm: {
    pointerEvents: 'none'
  },
  colorWhite: {
    color: '#fff'
  }
}));
