export const MENU = [
  {
    label: 'Giám sát',
    value: 'tracking',
    link: '/tracking',
    query: ''
  },
  {
    label: 'Lộ trình',
    value: 'map',
    link: '/map',
    query: ''
  },
  {
    label: 'Hình ảnh',
    value: 'image',
    link: '/image',
    query: 'page=1'
  },
  {
    label: 'Video',
    value: 'camera',
    link: '/camera/streaming?show_menu=true',
    query: 'show_menu=true'
  },
];
