import React,  { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  Select
} from '@material-ui/core';
import { deviceType } from '../constant/deviceType';
import { DEVICE_STATUS_FILTER, PAGE_SIZE_LIST } from '../constant/config';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { _convertObjectToQuery } from '../utils/apiService';

const FilterBox = ({ listAgencies, searchRef }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultFiltersValue = {
    agency_id: searchParams.agencyId || 'all',
    device_type_id: searchParams.deviceTypeId || 'all',
    status: searchParams.status || 'all',
  }
  const [agency, setAgency] = useState(null);
  const [query, setQuery] = useState(defaultFiltersValue);
  const [filterValue, setFilterValue] = useState({});
 
  useEffect(() => {
    if (listAgencies && listAgencies?.length > 0) {
      const data = listAgencies.map(item => {
        return {
          id: item.id,
          name: item.name
        };
      });
      setAgency(data);
    }
  }, []);

  const handleChange = (e, type) => {
    if(type === 'agency_type') {
      const queryValue = Object.assign({}, filterValue, {
        agency_id: Number(e.target.value),
      });
      setFilterValue(queryValue);
      setQuery(queryValue);
    }
    if(type === 'device_type') {
      const queryValue = Object.assign({}, filterValue, {
        device_type_id: Number(e.target.value),
      });
      setFilterValue(queryValue);
      setQuery(queryValue);
    }
    if(type === 'status_type') {
      const queryValue = Object.assign({}, filterValue, {
        status: e.target.value,
      });
      setFilterValue(queryValue);
      setQuery(queryValue);
    }
  }

  const handleFilter = () => {
    if(query) {
      const queryString = _convertObjectToQuery(query);
      navigate(`/app/manage/devices?${queryString}`);
      searchRef(query);
    };
  };

  const handleResetFilter = () => {
    setFilterValue({});
    setQuery({agency_id:'all', device_type_id:'all', status:'all'});
    navigate('/app/manage/devices');
    const queryValue =  {
      page: 1,
      page_size: PAGE_SIZE_LIST,
    };
    searchRef(queryValue, 'reset');
  };

  return (
    <div className="py-3 px-4">
      <div className="row text-left title-filter mb-3">
        Bộ lọc
      </div>

      <div className="row justify-content-center mb-2">
        <FormControl className="filter-item" size="small" variant="outlined">
          <span className="mb-1 label-input-filter"> Đại lý </span>
          <Select
            native
            value={query.agency_id}
            onChange={(e) => handleChange(e, 'agency_type')}
            inputProps={{
              name: 'Đại lý',
            }}
          >
            <option aria-label="None" value=""> Tất cả </option>
            {agency && agency.length > 0 && agency.map((item, index) => 
              (
                <option aria-label="None" value={item.id}> {item.name} </option>
              )
            )}
            
          </Select>
        </FormControl>
      </div>
      <div className="row justify-content-center mb-2">

        <FormControl  className="filter-item" size="small" variant="outlined">
          <span className="mb-1 label-input-filter"> Loại thiết bị </span>
          <Select
            native
            value={query.device_type_id}
            onChange={(e) => handleChange(e, 'device_type')}
            inputProps={{
              name: 'Loại thiết bị',
            }}
          >
            <option aria-label="None" value=""> Tất cả </option>
            {deviceType.map((item, index) =>(
              <option aria-label="None" value={item.id}> {item.name} </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="row justify-content-center mb-2">
        <FormControl className="filter-item" size="small" variant="outlined">
          <span className="mb-1 label-input-filter"> Trạng thái </span>
          <Select
            native
            value={query.status}
            onChange={(e) => handleChange(e, 'status_type')}
            inputProps={{
              name: 'Trạng thái',
              id: 'outlined-age-native-simple3',
            }}
          >
            <option aria-label="None" value=""> Tất cả </option>
            {DEVICE_STATUS_FILTER.map((item, index) =>(
              <option aria-label="None" value={item.value}> {item.name} </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <Button
              className="btn-filter btn-plain"
              variant="contained"
              onClick={handleResetFilter}
          >
            Đặt lại
          </Button>
        </div>

        <div className="col-6">
          <Button
              className="btn-filter"
              color="primary"
              variant="contained"
              onClick={handleFilter}
          >
            Áp dụng
          </Button>
        </div>
      </div>
  
    </div>
  );
};

export default FilterBox;
