import { colors, createMuiTheme } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white,
      light: '#ffe0e6',
    },
    primary: {
      main: "#D9042F",
      dark: "#D9042F",
    },
    primaryDark: {
      main: "#D9042F"
    },
    secondary: {
      main: "#D9042F"
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
      greyScale3:'#475461'
    }
  },
  shadows,
  typography
});

export default theme;
