import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AxiosAdapter from './AxiosAdapter';
import axios from 'axios';
import Cookie from 'js-cookie';
import {
  HTTP_GETTYPE,
  MSG_TIMEOUT_REQUEST,
  TIMEOUT_DEFAULT,
  STATUS_API
} from '../app/constant/config';
import { MESSAGE } from 'src/app/constant/message';

export const getListAgencyBusiness = AxiosAdapter.GetHttp(
  'agencySlice/getListAgencyBusiness',
  '/agencies/business',
  HTTP_GETTYPE.ALL
);

export const getListAgencies = AxiosAdapter.GetHttp(
  'agencySlice/getListAgencies',
  '/agencies',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const createAgency = AxiosAdapter.HttpPost(
  'agencySlice/createAgency',
  '/agencies',
  MESSAGE.CREATE_AGENCY_SUCCESS,
  'Tạo mới người dùng thất bại'
);

export const updateAgency= AxiosAdapter.HttpUpdateById(
  'agencySlice/updateAgency',
  '/agencies',
   MESSAGE.UPDATE_AGENCY_SUCCESS,
  'Cập nhật người dùng thất bại'
);

export const updateWarehouseTransfer= AxiosAdapter.HttpUpdate(
  'agencySlice/updateWarehouseTransfer',
  '/devices/transfer/agency',
  MESSAGE.WAREHOUSE_TRANSFER_SUCCESS,
  'Chuyển thiết bị xuống đại lý thất bại'
)

export const updateAgencyStatus = createAsyncThunk(
  'agencySlice/updateAgencyStatus', 
  (payload, { rejectWithValue }) => {
  return new Promise((resolve, reject) => {
    axios({
      url: process.env.REACT_APP_BACKEND_URL + `/agencies/${payload.id}/status`,
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': true,
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + Cookie.get('access-token')
      },
      data: payload.data,
      timeout: TIMEOUT_DEFAULT
    })
      .then(res => resolve(res.data))
      .catch(err => {
        if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
        if (!err.response) reject(err);
        reject(rejectWithValue(err.response?.data));
      });
  });
});

export const deleteAgency = AxiosAdapter.HttpDelete(
  'agencySlice/deleteAgency',
  'agencies',
  MESSAGE.DELETE_AGENCY_SUCCESS,
  'Xóa đại lý thất bại'
);

export const deleteMultiAgency = AxiosAdapter.HttpDeleteMulti(
  'agencySlice/deleteMultiAgency',
  'agencies/delete/agencies',
  MESSAGE.DELETE_AGENCY_SUCCESS,
  'Xóa đại lý thất bại'
);

export const agencySlice = createSlice({
  name: 'agencySlice',
  initialState: {
    listAgencies: [],
    listAgencyBusiness: [],
    totalAgencies: 0,
    error: null,
    statusGet: null,
    statusCreate: null,
    statusUpdate: null,
    statusDelete: null,
    statusDeleteMulti: null,
    statusTransfer: null,
    isLoading: false
  },

  reducers: {
      resetChange: state => {
          state.statusGet = null;
          state.statusCreate = null;
          state.statusUpdate = null;
          state.statusTransfer = null;
          state.statusDelete = null;
          state.isLoading = false;
          state.statusDeleteMulti = null;
          state.error = null;
      }
  },

  extraReducers: {
    [getListAgencies.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getListAgencies.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.listAgencies = action.payload.payload.agencies;
      state.totalAgencies = action.payload.payload.numberOfItem;
      state.isLoading = false;
    },
    [getListAgencies.rejected]: (state, action) => {
      state.statusGetAllAgencies = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
      state.isLoading = false;
    },
    [createAgency.pending]: state => {
      state.statusCreate = STATUS_API.PENDING;
    },
    [createAgency.fulfilled]: (state, action) => {
      state.statusCreate = STATUS_API.SUCCESS;
    },
    [createAgency.rejected]: (state, action) => {
      state.statusCreate = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },
    [updateAgency.pending]: state => {
      state.statusUpdate = STATUS_API.PENDING;
    },
    [updateAgency.fulfilled]: (state, action) => {
      state.statusUpdate = STATUS_API.SUCCESS;
    },
    [updateAgency.rejected]: (state, action) => {
      state.statusUpdate = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },
    [updateWarehouseTransfer.pending]: state => {
      state.statusTransfer = STATUS_API.PENDING;
    },
    [updateWarehouseTransfer.fulfilled]: (state, action) => {
      state.statusTransfer = STATUS_API.SUCCESS;
    },
    [updateWarehouseTransfer.rejected]: (state, action) => {
      state.statusTransfer = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },

    [getListAgencyBusiness.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.listAgencyBusiness = null;
      state.error = null;
    },
    [getListAgencyBusiness.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.listAgencyBusiness = action.payload.payload.businesses;
      state.error = null;
    },
    [getListAgencyBusiness.rejected]: (state, action) => {
      state.statusGet = STATUS_API.ERROR;
      state.error = action.payload?.message || action.error;
    },
    [deleteAgency.pending]: state => {
      state.statusDelete = STATUS_API.PENDING;
    },
    [deleteAgency.fulfilled]: (state, action) => {
      state.statusDelete = STATUS_API.SUCCESS;
    },
    [deleteAgency.rejected]: (state, action) => {
      state.statusDelete = STATUS_API.ERROR;
      state.errors = action.payload.message;
    },
    [deleteMultiAgency.pending]: state => {
      state.statusDeleteMulti = STATUS_API.PENDING;
    },
    [deleteMultiAgency.fulfilled]: (state, action) => {
      state.statusDeleteMulti = STATUS_API.SUCCESS;
    },
    [deleteMultiAgency.rejected]: (state, action) => {
      state.statusDeleteMulti = STATUS_API.ERROR;
      state.error = action.payload.message;
    },
  }
});

export const { resetChange } = agencySlice.actions;

export default agencySlice.reducer;
