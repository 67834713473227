import React from 'react';
import {
  AppBar,
  Container,
  Dialog,
  makeStyles,
  Slide
} from '@material-ui/core';
import CommonHeader from 'src/app/components/Header';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomDialog({ title, open, onClose, children, sizeSmall }) {
  const classes = useStyles();
  const size = sizeSmall ? 'xs' : 'md';
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={size}
        open={open}
        onClose={() => handleClose()}
        TransitionComponent={Transition}
      >
        <div className="device-active-modal">
          <AppBar className={classes.appBar}>
            <CommonHeader title={title} closeToolbarRef={handleClose} />
          </AppBar>
          <Container maxWidth="lg">{children}</Container>
        </div>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none !important'
  }
}));

export default CustomDialog;
