// import { tr } from "date-fns/locale";

const RotateIcon = function(options) {
    this.options = options || {};
    this.rImg = options.img || new Image();
    this.rImg.src = this.rImg.src || this.options.url || '';
    this.options.width = this.options.width || this.rImg.width || 52;
    this.options.height = this.options.height || this.rImg.height || 60;
    const canvas = document.createElement('canvas');
    canvas.width = this.options.width;
    canvas.height = this.options.height;
    this.context = canvas.getContext('2d');
    this.canvas = canvas;
  };
  RotateIcon.makeIcon = function(url) {
    return new RotateIcon({ url: url });
  };
  
  RotateIcon.prototype.setRotation = function (options) {

    const angle = options.deg ? (options.deg * Math.PI) / 180 : options.rad;
    const sin = Math.abs(Math.sin(angle));
    const cos = Math.abs(Math.cos(angle));

    // Tính kích thước mới để chứa đầy đủ ảnh khi xoay
    const newWidth = this.options.width * cos + this.options.height * sin;
    const newHeight = this.options.width * sin + this.options.height * cos;

    // Cập nhật kích thước canvas
    this.canvas.width = newWidth;
    this.canvas.height = newHeight;
    const ctx = this.canvas.getContext("2d");
    ctx.clearRect(0, 0, newWidth, newHeight);
    
    // Dịch ảnh vào giữa canvas mới
    ctx.save();
    ctx.translate(newWidth / 2, newHeight / 2);
    ctx.rotate(angle);
    ctx.translate(-this.options.width / 2, -this.options.height / 2);
    
    if (this.rImg.complete) {
        ctx.drawImage(this.rImg, 0, 0, this.options.width, this.options.height);
    } else {
        this.rImg.onload = () => {
            ctx.drawImage(this.rImg, 0, 0, this.options.width, this.options.height);
        };
    }
    
    ctx.restore();
    
    return this;
};

  RotateIcon.prototype.getUrl = function() {
    return this.canvas.toDataURL();
  };

export default RotateIcon;