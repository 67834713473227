import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import Results from './Results.js';
import ToolBar from './ToolBar.js';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { STATUS_API } from 'src/app/constant/config';
import { getListUsers } from 'src/features/userSlice.js';
import { getListProvinces } from 'src/features/provinceSlice.js';
import { _convertObjectToQuery } from 'src/app/utils/apiService.js';
import { useQuery } from 'src/app/hooks/useQuery.js';

const UserTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState({
    page: 1,
    page_size: PAGE_SIZE_LIST
  });
  const query = useQuery();

  const citizenId = query.get('citizen_id');
  const status = query.get('status');
  const page = query.get('page') || 1;
  const keywords = query.get('keywords');
  
  useEffect(() => {
    const queryValue = {
      ...params,
      page: page ? page : undefined,
      citizen_id: citizenId ? citizenId : undefined,
      status: status ? status : undefined,
      keywords: keywords ? keywords : undefined
    }
    setParams(queryValue);
  }, [query]);

  useEffect(() => {
    dispatch(getListUsers(params));
    dispatch(getListProvinces());
  }, []);

  const statusCreate = useSelector(
    state => state.userSlice.statusCreate
  );
  const statusDelete = useSelector(
    state => state.userSlice.statusDelete
  );
  const statusDeleteMultiUsers = useSelector(
    state => state.userSlice.statusDeleteMultiUsers
  );
  const statusUpdate = useSelector(
    state => state.userSlice.statusUpdate
  );
  const listProvinces = useSelector(state => state.provinceSlice.listProvinces);
  const listUsers = useSelector(state => state.userSlice.listUsers);
  const classes = useStyles();

  const getListUsersWithParams = data => {
    navigate('/app/manage/users?' + _convertObjectToQuery(data));
    dispatch(getListUsers(data));
  };

  useEffect(() => {
    if (
      statusCreate === STATUS_API.SUCCESS ||
      statusDelete === STATUS_API.SUCCESS ||
      statusUpdate === STATUS_API.SUCCESS ||
      statusDeleteMultiUsers === STATUS_API.SUCCESS
    ) {
      dispatch(getListUsers(params));
    }
  }, [
    statusCreate,
    statusDelete,
    statusUpdate,
    statusDeleteMultiUsers
  ]);

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: '98%', margin: '0 auto' }}>
          <ToolBar
            searchRef={getListUsersWithParams}
            listProvinces={listProvinces}
          />
          <Results
            className="mt-3"
            listUsers={listUsers}
            getListUsersRef={getListUsersWithParams}
          />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(2)
  },
}));

export default UserTable;
