import React from 'react';
import PropTypes from 'prop-types';
import ImageItem from '../../../components/ImageItem';
import { Grid, makeStyles, Box } from '@material-ui/core';
import NullData from '../../../components/NullData';
import { useSelector } from 'react-redux';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import CardInfoImage from './CardInfoImage';
import { Typography } from 'antd';
import { useNavigate } from 'react-router';

Result.propTypes = {
  listImages: PropTypes.array.isRequired,
  actionDetailsImgRef: PropTypes.func
};

function Result({ isShowResult }) {
  const classes = useStyles();
  const [isOpenImage, setIsOpenImage] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [dataPhotoView, setDataPhotoView] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const listImages = useSelector(state => state.imageSlice.listImage);

  const navigate = useNavigate();

  const actionDetailsImgRef = index => {
    setPhotoIndex(index);
    setDataPhotoView(listImages[index]);
    setIsOpenImage(true);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload= e => {
    const imageUrl = dataPhotoView.url
    const link = document.createElement("a");
    link.href = imageUrl; 
    link.download = "image.jpg"; 
    document.body.appendChild(link); 
    link.click(); 
    document.body.removeChild(link); 
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {isShowResult && (
        <>
          {listImages?.length === 0 ? (
            <NullData />
          ) : (
            <Grid container spacing={3}>
              {listImages?.map((image, i) => (
                <Grid item key={image.id} lg={3} md={6} xs={12}>
                  <ImageItem
                    actionDetailsImgRef={() => actionDetailsImgRef(i)}
                    className={classes.productCard}
                    image={image}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
      
      {isOpenImage && (
        <div>
          <CardInfoImage id = {id} open = {open} handleClose = {handleClose} anchorEl = {anchorEl} dataPhotoView={dataPhotoView}/>
          <Lightbox
            images={listImages}
            startIndex={photoIndex}
            onClose={() => setIsOpenImage(false)}
            
          ></Lightbox>
          
          <Box
            style={{
              position: 'fixed',
              top: '70px',
              //right: '36px',
              display: 'flex',
              width:'96%',
              justifyContent: 'space-between',
              alignItems:'center',
              zIndex: 99999
            }}
          >
            <Box style={{display:'flex', gap:'15px', alignItems:'center'}}>
              <Box
                style={{
                  cursor: 'pointer'
                }}
                //onClick={(e) => navigate('/app/image')}
              >
                <img src="/static/iconSvg/arrow-left-icon.svg" />
              </Box>
              <Box
                style={{
                  cursor: 'pointer'
                }}
              >
                <img src="/static/iconSvg/image-active-icon.svg" />
              </Box>
              <Box>
                <Typography style={{color:'#ffffff'}}>{dataPhotoView.file_name}</Typography>
              </Box>
            </Box>
            <Box style={{display:'flex', gap:'15px', alignItems:'center'}}>
             <Box
              style={{
                cursor: 'pointer'
              }}
              onClick={(e) => handleDownload(e)}
            >
              <img src="/static/iconSvg/downloadIcon.svg" />
            </Box>
            <Box
              style={{
                cursor: 'pointer'
              }}
              onClick={(e) => handleClick(e)}
            >
              <img src="/static/iconSvg/icon_infomation.svg" />
            </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
}
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%',
    borderRadius: '8px',
    boxShadow: '0 7px 7px 0px rgb(0 0 0 / 7%)'
  }
}));
export default Result;
