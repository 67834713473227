import axios from 'axios';
import Cookie from 'js-cookie';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true,
  }
});

instance.interceptors.request.use(
  config => {
      const accessToken = localStorage.getItem('access-token');
      if (accessToken) {
          config.headers.common = { Authorization: `Bearer ${accessToken}` };
      }
      return config;
  },
  
    error => Promise.reject(error.response || error.message)
);

instance.interceptors.response.use(
  response => {
      return response;
  },
  async error => {
      let originalRequest = error.config;
      let refreshToken = localStorage.getItem('refresh-token');

      if (
          refreshToken &&
          (error.response.status === 401) &&
          !originalRequest._retry
      ) {
          originalRequest._retry = true;
          return axios
              .post(`${process.env.REACT_APP_BACKEND_URL}/auth/refresh`, { 'refresh_token' : refreshToken })
              .then(res => {
                  if (res.status === 200) {
                      localStorage.setItem(
                          'access-token',
                          res.data.payload.token
                      );
                      localStorage.setItem(
                          'refresh-token',
                          res.data.payload.refresh_token
                      );
                      Cookie.set('access-token', res.data.payload.token);

                      originalRequest.headers[
                          'Authorization'
                      ] = `Bearer ${res.data.payload.token}`;

                      return axios(originalRequest);
                  }
              })
              .catch(() => {
                alert("Phiên đăng nhập hết hạn. Vui lòng đăng nhập lại.");
                localStorage.removeItem('access-token');
                localStorage.removeItem('refresh-token');
                Cookie.remove('access-token');
                location.reload();
            });
      }

      if (error.response?.status === 403) {
        window.location.href = '/403';
      }
      
      return Promise.reject(error.response || error.message);
  }
);

export default instance;
