import {
  makeStyles,
  Box,
  Card,
  CardContent,
  Typography,
  Popover,
  Divider
} from '@material-ui/core';
import moment from 'moment';
import { useState, useEffect } from 'react';

const useStyles = makeStyles({
  root: {
    minWidth: 375
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  pos: {
    marginBottom: 12
  }
});

const CardInfoImage = ({ id, open, handleClose, anchorEl, dataPhotoView }) => {
  const classes = useStyles();
  const [listTitles, setListTitles] = useState([
    {
      id: 1,
      url: '/static/iconSvg/infoImage/icon_clock.svg',
      title: 'Thời gian',
      content: '--'
    },
    {
      id: 1,
      url: '/static/iconSvg/infoImage/icon_pin.svg',
      title: 'Vị trí',
      content: '--'
    },
    {
      id: 1,
      url: '/static/iconSvg/infoImage/icon_speedometer.svg',
      title: 'Vận tốc',
      content: '--'
    },
    {
      id: 1,
      url: '/static/iconSvg/infoImage/icon_camera.svg',
      title: 'Camera',
      content: '--'
    }
  ]);

  useEffect(() => {
    setListTitles([
      {
        id: 1,
        url: '/static/iconSvg/infoImage/icon_clock.svg',
        title: 'Thời gian',
        content: dataPhotoView?.created_at
          ? moment.unix(dataPhotoView?.created_at).format('HH:mm:ss DD/MM/YYYY')
          : '--'
      },
      {
        id: 1,
        url: '/static/iconSvg/infoImage/icon_pin.svg',
        title: 'Vị trí',
        content: dataPhotoView?.location
      },
      {
        id: 1,
        url: '/static/iconSvg/infoImage/icon_speedometer.svg',
        title: 'Vận tốc',
        content: dataPhotoView?.speed
      },
      {
        id: 1,
        url: '/static/iconSvg/infoImage/icon_camera.svg',
        title: 'Camera',
        content: `Camera ${dataPhotoView?.camera_num}`
      }
    ]);
  }, [dataPhotoView]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      style={{ zIndex: 99999 }}
    >
      <Card className={classes.root}>
        <CardContent>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              gutterBottom
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                color: '#0C1132'
              }}
            >
              Chi tiết ảnh
            </Typography>
          </Box>

          {listTitles.map(item => {
            return (
              <>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      textAlign: 'left',
                      gap: '15px',
                      paddingTop: '15px'
                    }}
                  >
                    <Box
                    >
                      <img
                        src={item.url}
                        alt="image"
                        
                      />
                    </Box>
                    <Typography
                      style={{
                        fontWeight: '500',
                        color: '#0C1132',
                        fontSize: '14px',
                        textAlign: 'left'
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      style={{
                        textAlign: 'right',
                        fontSize: '14px',
                        maxWidth: '250px',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2
                      }}
                    >
                      {item.content}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
              </>
            );
          })}
        </CardContent>
      </Card>
    </Popover>
  );
};

export default CardInfoImage;
