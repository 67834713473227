import { Box, Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import L from 'leaflet';
import {
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  useMap
} from 'react-leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import LicensePlateTag from 'src/app/components/licensePlateTag/LicesePlateTag';
import ContentCarInfo from 'src/app/components/popupInfocar/ContentCarInfo';
import RotateIcon from 'src/app/utils/RotateIcon';
import { getIconStatus } from 'src/app/utils/vehicleService';
import mkIcon from '../../assets/mkicon.png';
import './style.css';

const ResetView = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    const interval = setInterval(() => {
      map.setView(center);
    }, 5000); // 10s

    return () => clearInterval(interval);
  }, [center, map]);

  return null;
};

function MapOsmCamera({ listVehicleTracking, zoom, showMenu }) {
  const centerInRedux = useSelector(state => state.vehicleSlice.centerMap);
  const centerMap = [centerInRedux['lat'], centerInRedux['lng']];
  const [positionsInfoBox, setPositionsInfoBox] = useState({});
  const [mapRef, setMapRef] = useState(null);
  const [centerMapCam, setCenterMapCam] = useState(centerMap);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [vehicleSelected, setVehicleSelected] = useState({});

  const statisticVehicleTracking = useSelector(
    state => state.vehicleSlice.statisticVehicleTracking
  );
  const query = new URLSearchParams(useLocation().search);
  const deviceSerial = query.get('device_serial')
    ? query.get('device_serial')
    : null;

  const findVehicle = deviceSerial => {
    const device = listVehicleTracking.filter(vehicle => {
      return vehicle.device_serial === deviceSerial;
    });

    return device.length > 0 ? device[0] : null;
  };

  const getVehicleViewMap = vehicle => {
    if (vehicle && vehicle?.lat && vehicle?.lng) {
      setCenterMapCam([Number(vehicle.lat), Number(vehicle.lng)]);
      mapRef?.setView([Number(vehicle.lat), Number(vehicle.lng)], 15);
      setVehicleSelected(vehicle);
    }
  };

  useEffect(() => {
    if (mapRef) {
      setTimeout(() => {
        mapRef.invalidateSize();
      }, 300);
    }
  }, [mapRef, showMenu]);

  useEffect(() => {
    if (deviceSerial) {
      const vehicle = findVehicle(deviceSerial);
      getVehicleViewMap(vehicle);
    }
  }, [deviceSerial]);

  const handleMarkerClick = vehicle => {
    if (vehicle) {
      setPositionsInfoBox({
        active_at: vehicle.active_at,
        date_debt: vehicle.date_debt,
        daily_distance: vehicle.daily_distance,
        location: vehicle.location,
        device_serial: vehicle.device_serial,
        max_speed: vehicle.max_speed,
        daily_distance: vehicle.daily_distance,
        memory_card: vehicle.memory_card,
        phone_number: vehicle.phone_number,
        register_package_day: vehicle.register_package_day,
        remaining_days: vehicle.remaining_days,
        device_type: vehicle?.device_type,
        is_overspeed: vehicle?.is_overspeed,
        remaining_days: vehicle?.remaining_days,
        sim: vehicle?.sim,
        phone: vehicle?.phone,
        license_plate: vehicle.license_plate,
        lat: Number(vehicle?.lat),
        lng: Number(vehicle?.lng),
        created_at: vehicle.created_at,
        air_condition_status: vehicle.air_condition_status,
        engine_status: vehicle.engine_status, // bật tắt động cơ
        battery: vehicle.battery,
        charge_status: vehicle.charge_status,
        speed_gps: vehicle.speed_gps,
        door_status: vehicle.door_status,
        stop_times: vehicle.stop_times, // số lần dừng đỗ
        is_stopping: vehicle.is_stopping, // đang dừng đỗ
        total_distance: vehicle.total_distance, // Tổng quãng đường đi được trong ngày
        total_overspeed: vehicle.total_overspeed, // số lần quá tốc độ
        total_drive_time: vehicle.total_drive_time, // thời gian lái xe trong ngày
        driver_login: vehicle.driver_login,
        driver_logout: vehicle.driver_logout,
        driver_name: vehicle.driver_name, // tên lái xe
        driver_license: vehicle.driver_license, // bằng lái xe
        non_stop_driving_time: vehicle.non_stop_driving_time
      });
    }
  };

  //#region Display Icons
  const handleDisplayIcon = useCallback(
    (vehicle, statisticVehicleTracking) => {
      if (!vehicle || !statisticVehicleTracking) {
        return L.icon({
          iconUrl: '/static/iconSvg/cars/car_lost_gsm.svg',
          iconAnchor: [0, 0]
        });
      }
      const iconVehicle = getIconStatus(
        vehicle.device_status,
        vehicle.device_type
      );
      const vehicleStates = [
        {
          list: 'lost_gsm_vehicles',
          icon: iconVehicle
        },
        {
          list: 'over_speed_vehicles',
          icon: iconVehicle
        },
        {
          list: 'stop_vehicles',
          icon: iconVehicle
        },
        {
          list: 'running_vehicles',
          icon: iconVehicle
        },
        {
          list: 'lost_gps_vehicles',
          icon: iconVehicle
        }
      ];

      let makerIcon;

      if (vehicle && statisticVehicleTracking) {
        for (const { list, icon } of vehicleStates) {
          if (statisticVehicleTracking[list]?.includes(vehicle.license_plate)) {
            let iconObject = RotateIcon.makeIcon(icon);
            if (typeof iconObject.setRotation === 'function') {
              iconObject = iconObject.setRotation({
                deg: vehicle.direction ? vehicle.direction : 0.001
              });
            }
            const url =
              typeof iconObject.getUrl === 'function'
                ? iconObject.getUrl()
                : mkIcon;

            makerIcon = L.icon({
              iconUrl: url,
              iconAnchor: [16, 16],
              popupAnchor: [0, 0]
            });
            break;
          }
        }
      }
      return (
        makerIcon ||
        L.icon({
          iconUrl: '/static/iconSvg/cars/car_lost_gsm.svg',
          iconAnchor: [0, 0]
        })
      );
    },
    [statisticVehicleTracking]
  );

  const displayLocationIcon = () => {
    let locationCurrent = L.icon({
      iconUrl: `/static/iconSvg/gps.svg`,
      iconSize: [25, 25],
      iconAnchor: [1, 25]
    });
    return locationCurrent;
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setCurrentLocation([
          position.coords.latitude,
          position.coords.longitude
        ]);

        if (!mapRef) return;
        mapRef.flyTo(
          [position.coords.latitude, position.coords.longitude],
          13,
          {
            animate: true
          }
        );
      });
    }
  };

  return (
    <>
      <MapContainer
        center={centerMapCam}
        zoom={zoom}
        maxZoom={20}
        minZoom={6}
        style={{ position: 'relative', height: '94vh' }}
        zoomControl={false}
        scrollWheelZoom={true}
        whenCreated={mapR => setMapRef(mapR)}
        zoomAnimation={true}
      >
        <Box
          style={{
            position: 'absolute',
            bottom: '80px',
            right: '22px',

            cursor: 'pointer',
            boxShadow: 'rgba(20, 20, 20, 0.322)  0px 0px 2px 0px',
            border: '0px !important',

            padding: '8px',
            color: 'red',
            width: '48px',
            height: '48px',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            backgroundColor: 'white',
            borderRadius: '50%',
            zIndex: 1000
          }}
          onClick={() => getCurrentLocation()}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img src="/static/iconSvg/locationIconv2.svg" alt="location" />
          </Box>
        </Box>

        <LayersControl position="bottomright">
          {/* <LayersControl.BaseLayer name="Open Street Map">
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer> */}

          <LayersControl.BaseLayer name="Vệ tinh">
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}"
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              maxZoom={20}
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer checked name="Google Map">
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=m&hl=vi&x={x}&y={y}&z={z}"
              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              maxZoom={20}
            />
          </LayersControl.BaseLayer>
        </LayersControl>

        <Box style={{ marginRight: 10 }}>
          <FullscreenControl
            forceSeparateButton={true}
            position="topright"
            content='<img src="/static/iconSvg/fullscreen.svg" style="width: 24px; height: 24px;" />'
          />
        </Box>
        <>
          {listVehicleTracking &&
            listVehicleTracking.map((vehicle, index) => (
              <Marker
                key={index}
                position={[Number(vehicle.lat), Number(vehicle.lng)]}
                eventHandlers={{
                  click: () => handleMarkerClick(vehicle)
                }}
                icon={handleDisplayIcon(vehicle, statisticVehicleTracking)}
              >
                <Popup
                  offset={[0, -470]}
                  autoPan={true}
                  autoPanPaddingTopLeft={[20, 40]}
                >
                  <ContentCarInfo positionsInfoBox={positionsInfoBox} />
                </Popup>

                {vehicleSelected.license_plate === vehicle.license_plate ? (
                  <Tooltip
                    direction="center"
                    offset={[0, 5]}
                    position={[Number(vehicle.lat), Number(vehicle.lng)]}
                    opacity={1}
                    permanent={true}
                  >
                    <Box>
                      <img src="/static/iconSvg/focusIcon.svg" alt="focus" />
                    </Box>
                    <Typography
                      style={{
                        backgroundColor: '#F5FAFF',
                        padding: '1px 4px',
                        fontSize: '12px',
                        width: 'max-content !important',
                        borderRadius: '0.1em',
                        color: 'rgb(0, 0, 0)',
                        fontWeight: 'bold',
                        marginTop: '0px',
                        marginLeft: '-3px'
                      }}
                    >
                      {vehicle.license_plate}
                    </Typography>
                    <Box>
                      <ResetView
                        center={[Number(vehicle.lat), Number(vehicle.lng)]}
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <LicensePlateTag
                    position={vehicle}
                    license_plate={vehicle.license_plate}
                  />
                )}
              </Marker>
            ))}

          {currentLocation && (
            <Marker icon={displayLocationIcon()} position={currentLocation}>
              <Tooltip
                style={{
                  background: 'rgba(0, 0, 0, 0) !important',
                  boxShadow: 'none !important'
                }}
                position={currentLocation}
                direction="center"
                permanent
              >
                <Box
                  style={{
                    backgroundColor: 'rgba(19, 19, 19, 0.493)',
                    color: 'white',
                    borderRadius: '5px',
                    padding: '2px',
                    position: 'absolute',
                    top: 5,
                    left: -22
                  }}
                >
                  {' '}
                  Vị trí của bạn
                </Box>
              </Tooltip>
            </Marker>
          )}
        </>
      </MapContainer>
    </>
  );
}

export default MapOsmCamera;
