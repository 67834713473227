import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { closeGlobalToast } from '../../features/uiSlice';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

export function GlobalToastMessage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toastData = useSelector(state => state.uiSlice.globalToast);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeGlobalToast());
  };

  if (!toastData || !toastData?.message) return null;

  return (
    <div className={classes.root}>
      <Snackbar
        open
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={toastData.type}>
          {toastData.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
