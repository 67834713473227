import * as React from 'react';
import { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Typography,
  TextField,
  makeStyles,
  withStyles
} from '@material-ui/core';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { deviceType } from 'src/app/constant/deviceType';
import { timeFilter } from 'src/app/views/manage/StatisticWarehouseTransfer/constance';
import { useSelector } from 'react-redux';

const CssTextField = withStyles({
  root: {
    margin: 0
  },
  "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "& fieldset": {
        borderColor: "#ccc",
      },
      "&:hover fieldset": {
        borderColor: "#888",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
      },
    },
})(TextField);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

//const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const labelsMonth = [
  'Tháng 1',
  'Tháng 2',
  'Tháng 3',
  'Tháng 4',
  'Tháng 5',
  'Tháng 6',
  'Tháng 7',
  'Tháng 8',
  'Tháng 9',
  'Tháng 10',
  'Tháng 11',
  'Tháng 12'
];

const labelsYear = [
  'Năm 2025',
  'Năm 2026',
  'Năm 2027',
  'Năm 2028',
  'Năm 2029',
  'Năm 2030',
  'Năm 2031',
  'Năm 2032',
  'Năm 2033',
  'Năm 2034',
  'Năm 2035',
  'Năm 2036'
];

const options = {
  scales: {
    x: {
      type: 'category',
      grid: {
        display: false
      }
    }
  },
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: 'bottom'
    }
  }
};

const ChartStatistic = ({
  dataStatistics,
  chartTitle,
  labelChart,
  searchRef
}) => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    device_type_id: undefined,
    agency_id: undefined,
    time_to_chart: undefined,
    time_to_search: undefined,
    page: 1,
    page_size: PAGE_SIZE_LIST
  });
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);
  const labels = query.time_to_chart ? labelsMonth : labelsYear;
  const data = {
    labels,
    datasets: [
      {
        label: labelChart,
        data: dataStatistics?.length > 0 ? dataStatistics : [],
        backgroundColor: '#2d9aff',
        barThickness: 45
      }
    ]
  };
  const handleFilter = (e, type) => {
    if (type === 'deviceType') {
      const queryValue = Object.assign({}, query, {
        device_type_id: e.target.value,
      });
      setQuery(queryValue);
      searchRef(queryValue);
    }
    if (type === 'agencyType') {
      const queryValue = Object.assign({}, query, {
        agency_id: e.target.value,
      });
      setQuery(queryValue);
      searchRef(queryValue);
    }
    if (type === 'timeType') {
      const queryValue = Object.assign({}, query, {
        time_to_chart: e.target.value,
        time_to_search: e.target.value,
      });
      setQuery(queryValue);
      searchRef(queryValue);
    }
  };

  return (
    <Box>
      <Box
        style={{
          padding: '15px 0px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box>
          <Typography
            style={{ fontSize: '16px', fontWeight: '500', color: '#1E2323' }}
          >
            {chartTitle}
          </Typography>
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <CssTextField
            className="input-no-fieldset"
            size="small"
            fullWidth
            select
            margin="normal"
            name="agency_id"
            onChange={e => handleFilter(e, 'agencyType')}
            SelectProps={{
              native: true,
              MenuProps: {
                PaperProps: {
                  style: {
                    borderRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                  },
                },
              },
            }}
            
            InputProps={{
              style: {
                fontSize: "14px",
                backgroundColor: "#ffffff",
                padding: "6px 8px",
                borderRadius: "8px",
                width: '125px',
              },
              disableUnderline: true
            }}
          >
            <option value={''} className={classes.option}>Tất cả đại lý</option>
            {listAgencies &&
              listAgencies?.map((item, index) => (
                <option key={index} className={classes.option} value={item.id}>
                  {item.name}
                </option>
              ))}
          </CssTextField>

          <CssTextField
            className="input-no-fieldset"
            size="small"
            fullWidth
            select
            margin="normal"
            name="device_type_id"
            onChange={e => handleFilter(e, 'deviceType')}
            SelectProps={{
              native: true
            }}
            InputProps={{
              style: {
                fontSize: "14px",
                backgroundColor: "#ffffff",
                padding: "6px 8px",
                borderRadius: "8px",
                width: '125px',
              },
              disableUnderline: true
            }}
          >
            <option value={''}>Tất cả thiết bị</option>
            {deviceType &&
              deviceType?.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
          </CssTextField>

          <CssTextField
            className="input-no-fieldset"
            size="small"
            fullWidth
            select
            margin="normal"
            name="time_to_chart"
            onChange={e => handleFilter(e, 'timeType')}
            SelectProps={{
              native: true
            }}
            InputProps={{
              style: {
                fontSize: '14px',
                backgroundColor: '#ffffff',
                padding: '1px 0px',
                width: '132px',
              },
              disableUnderline: true,
            }}
          >
            <option value={''}>Tất cả thời gian</option>
            {timeFilter &&
              timeFilter?.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
          </CssTextField>
        </Box>
      </Box>
      <Bar options={options} data={data} height={90} />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  option: {
    padding: "8px",
    backgroundColor: "white",
    color: "#333",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  icon: {
    fill: '#D9042F'
  },
  root: {
    color: '#D9042F'
  },

  button: {
    color: '#858C93',
    '&:hover': {
      boxShadow: 'none',
      background: '#FFF2F2',
      color: '#D9042F'
    },
    '&:active': {
      boxShadow: 'none',
      background: '#fcd1d1',
      color: '#D9042F'
    }
  },
  button_active: {
    background: '#FFF2F2',
    color: '#D9042F'
  }
}));

export default ChartStatistic;
