import { Box } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Loading from 'src/app/components/Loading';
import MapOsmCamera from './MapOsmCamera';
import './style.css';
import VideoWall from './VideoWall';

const MapViewCamera = () => {

  const query = new URLSearchParams(useLocation().search);

  const showMenu = JSON.parse(query.get('show_menu'));

  const deviceSerial = query.get('device_serial')
    ? query.get('device_serial')
    : null;

  const listVehicleTracking = useSelector(
    state => state.vehicleSlice.listVehicleTracking
  );
  const vehiclesCamera4GTracking = listVehicleTracking.filter(vehicle => vehicle?.device_type === 'VGPS_CAM4G');
  const listVehicles = listVehicleTracking.filter(vehicle => {
    return vehicle.device_serial === query.get('device_serial');
  });

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent:'flex-end',
        overflowX: 'hidden',
      }}
    >
      <Box className="row">
        <Box
          className = {showMenu ? "col-9": "col-7"}
          style={{
            paddingLeft: 52,
          }}
        >
          <VideoWall deviceSerial={deviceSerial} key={deviceSerial} />
        </Box>
        <Box
          className = {showMenu ? "col-3": "col-5"}
          style={{
            paddingLeft: 0,
          }}
        >
          <MapOsmCamera
            zoom={13}
            listVehicleTracking={query.get('device_serial') ? listVehicles : vehiclesCamera4GTracking}
            loadingElement={<Loading />}
            showMenu={showMenu}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(MapViewCamera);
