export const timeFilter = [
    {value:2025, label: 'Năm 2025'},
    {value:2026, label: 'Năm 2026'},
    {value:2027, label: 'Năm 2027'},
    {value:2028, label: 'Năm 2028'},
    {value:2029, label: 'Năm 2029'},
    {value:2030, label: 'Năm 2030'},
    {value:2031, label: 'Năm 2031'},
    {value:2032, label: 'Năm 2032'},
    {value:2033, label: 'Năm 2033'},
    {value:2034, label: 'Năm 2034'},
    {value:2035, label: 'Năm 2035'},
    {value:2036, label: 'Năm 2036'},
  ]