import { createSlice } from '@reduxjs/toolkit';
import { STATUS_API } from 'src/app/constant/config';
import AxiosAdapter from './AxiosAdapter';
import Cookie from 'js-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    MSG_TIMEOUT_REQUEST,
    TIMEOUT_DEFAULT
} from '../app/constant/config';
import axios from "../app/axiosInterceptor"
import { _convertObjectToQuery } from '../app/utils/apiService';
import { MESSAGE } from 'src/app/constant/message';

export const getListStaff = createAsyncThunk(
  'staffSlice/getListStaff',
  (payload, { rejectWithValue }) => {
    return new Promise((resolve, reject) => {
      axios({
        url:
          process.env.REACT_APP_BACKEND_URL +
          `/agencies/${payload.id}/staff` +
          '?' +
          _convertObjectToQuery(payload.data),
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': true,
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + Cookie.get('access-token')
        },
        timeout: TIMEOUT_DEFAULT
      })
        .then(res => resolve(res.data))
        .catch(err => {
          if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
          if (!err.response) reject(err);
          reject(rejectWithValue(err.response?.data));
        });
    });
  }
);

export const createStaff = AxiosAdapter.HttpPost(
  'staffSlice/createStaff',
  '/agencies/staff',
  MESSAGE.CREATE_STAFF_SUCCESS,
  'Tạo nhân viên thất bại'
);

export const deleteStaff = AxiosAdapter.HttpDelete(
  'staffSlice/deleteStaff',
  'agencies/staff',
  MESSAGE.DELETE_STAFF_SUCCESS,
  'Xóa nhân viên thất bại'
);

export const deleteMultiStaff = AxiosAdapter.HttpDeleteMulti(
  'staffSlice/deleteStaff',
  'agencies/delete/staff',
  MESSAGE.DELETE_STAFF_SUCCESS,
  'Xóa nhân viên thất bại'
);

export const updateStaff = AxiosAdapter.HttpUpdateById(
  'staffSlice/deleteStaff',
  '/agencies/staff',
  MESSAGE.UPDATE_STAFF_SUCCESS,
  'Cập nhật nhân viên thất bại'
);

export const staffSlice = createSlice({
  name: 'staffSlice',
  initialState: {
    listStaff: [],
    totalStaffs: 0,
    statusGet: null,
    statusCreate: null,
    statusDelete: null,
    statusDeleteMultiStaff: null,
    statusUpdate: null,
    errors: null,
    isLoading: false
  },

  reducers: {
    resetChange: state => {
      state.statusGet = null;
      state.statusCreate = null;
      state.statusDelete = null;
      state.statusUpdate = null;
      state.errors = null;
    }
  },

  extraReducers: {
    [getListStaff.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.isLoading = true;
    },
    [getListStaff.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.listStaff = action.payload.payload.agency.members;
      state.totalStaffs = action.payload.payload.numberOfItem;
      state.isLoading = false;
    },
    [getListStaff.rejected]: (state, action) => {
      state.statusGet = STATUS_API.ERROR;
      state.isLoading = false;
    },
    [createStaff.pending]: state => {
      state.statusCreate = STATUS_API.PENDING;
    },
    [createStaff.fulfilled]: (state, action) => {
      state.statusCreate = STATUS_API.SUCCESS;
    },
    [createStaff.rejected]: (state, action) => {
      state.statusCreate = STATUS_API.ERROR;
      state.errors = action.payload.message;
    },
    [deleteStaff.pending]: state => {
      state.statusDelete = STATUS_API.PENDING;
    },
    [deleteStaff.fulfilled]: (state, action) => {
      state.statusDelete = STATUS_API.SUCCESS;
    },
    [deleteStaff.rejected]: (state, action) => {
      state.statusDelete = STATUS_API.ERROR;
      state.errors = action.payload.message;
    },
    [deleteMultiStaff.pending]: state => {
      state.statusDeleteMultiStaff = STATUS_API.PENDING;
    },
    [deleteMultiStaff.fulfilled]: (state, action) => {
      state.statusDeleteMultiStaff = STATUS_API.SUCCESS;
    },
    [deleteMultiStaff.rejected]: (state, action) => {
      state.statusDeleteMultiStaff = STATUS_API.ERROR;
      state.errors = action.payload.message;
    },
    [updateStaff.pending]: state => {
      state.statusUpdate = STATUS_API.PENDING;
    },
    [updateStaff.fulfilled]: (state, action) => {
      state.statusUpdate = STATUS_API.SUCCESS;
    },
    [updateStaff.rejected]: (state, action) => {
      state.statusUpdate = STATUS_API.ERROR;
      state.errors = action.payload.message;
    }
  }
});

export const { resetChange } = staffSlice.actions;

export default staffSlice.reducer;
