import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Results from './Results.js';
import ToolBar from './ToolBar.js';
import {
  PAGE_SIZE_LIST,
  STATUS_API
} from 'src/app/constant/config';
import { useQuery } from 'src/app/hooks/useQuery.js';
import { _convertObjectToQuery } from 'src/app/utils/apiService.js';
import {
  getListDeviceVersion
} from 'src/features/versionDeviceSlice.js';

const VersionView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const [params, setParams] = useState({
    page: 1,
    page_size: PAGE_SIZE_LIST
  });

  const listDeviceVersion = useSelector(
    state => state.versionDeviceSlice.listDeviceVersion
  );
  const totalVersion = useSelector(
    state => state.versionDeviceSlice.totalVersion
  );
  const statusCreate = useSelector(
    state => state.versionDeviceSlice.statusCreate
  );
  const statusMultiDelete = useSelector(
    state => state.versionDeviceSlice.statusMultiDelete
  );
  const statusUpdate = useSelector(
    state => state.versionDeviceSlice.statusUpdate
  );
  const classes = useStyles();

  const page = query.get('page') || 1;
  const keywords = query.get('keywords');

  useEffect(() => {
    const queryValue = {
      ...params,
      page: page ? page : undefined,
      keywords: keywords ? keywords : undefined
    };
    setParams(queryValue);
  }, [query]);

  useEffect(() => {
    dispatch(getListDeviceVersion(params))
  }, []);

  const getListVersionDeviceWithParams = query => {
    navigate('/app/manage/versions?' + _convertObjectToQuery(query));
    dispatch(getListDeviceVersion(query));
  };

  useEffect(() => {
    if (
      statusCreate === STATUS_API.SUCCESS ||
      statusMultiDelete === STATUS_API.SUCCESS ||
      statusUpdate === STATUS_API.SUCCESS
    ) {
      getListVersionDeviceWithParams(params);
    }
  }, [statusCreate, statusUpdate, statusMultiDelete]);

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: '98%', margin: '0 auto' }}>
          <ToolBar searchRef={getListVersionDeviceWithParams} />
          <Results
            className="mt-3"
            listDeviceVersion={listDeviceVersion}
            totalVersion={totalVersion}
            getListVersionDeviceWithParams={getListVersionDeviceWithParams}
          />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(2)
  },
}));

export default VersionView;
