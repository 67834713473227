import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  makeStyles,
  TextField
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { green } from '@material-ui/core/colors';
import { STATUS_API } from 'src/app/constant/config';
import { Formik } from 'formik';
import { getListProvinces } from 'src/features/provinceSlice';
import * as Yup from 'yup';
import { vehicleType } from 'src/app/constant/vehicleType';
import { getServicePackage, updateDevice } from 'src/features/deviceSlice';
import CustomDialog from 'src/app/components/CustomDialog';

function EditDeviceModal({ open, onClose, device }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusCreate = useSelector(state => state.staffSlice.statusCreate);
  const [vehicleSelected, setVehicleSelected] = useState({});
  const listPackage = useSelector(state => state.deviceSlice.listPackage);

  const serviceOption = listPackage?.map(item => {
    return {
      id: Number(item.id),
      cost: item.cost,
      name: item.name
    };
  })

  useEffect(() => {
    const deviceSelected =
      vehicleType.find(vh => vh.name === device.vehicle?.vehicle_type?.title) ||
      null;
    setVehicleSelected(deviceSelected);
  }, [device]);

  useEffect(() => {
    if(device.id){
      dispatch(
        getServicePackage({
          device_type_id: device?.deviceType?.id,
          version: device?.hardware_version
        })
      );
    }
  }, [device]);

  useEffect(() => {
    dispatch(getListProvinces());
  }, [dispatch]);

  const handleSubmit = data => {
    const newData = {
      vehicle_license_plate:
        data.title_device_type !== 'Con người'
          ? data.vehicle_license_plate
          : null,
      slots: data.title_device_type === 'Xe khách' ? +data.slots : null,
      tonnage: data.title_device_type === 'Xe tải' ? data.tonnage : null,
      name_of_user_for_gps:
        data.title_device_type === 'Con người'
          ? data.name_of_user_for_gps
          : null,
      title_device_type: data.title_device_type,
      service_package_id: Number(data.service_package)
    };
    dispatch(updateDevice(
      {
        data: newData,
        id: device.id,
      }
    ));
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog title="Chọn gói cước" open={open} onClose={handleClose} >
      <div>
        <Formik
          initialValues={{
            title_device_type: device.vehicle?.vehicle_type?.title,
            vehicle_license_plate: device.vehicle?.license_plate || '',
            serial: device.serial,
            slots: device.vehicle?.vehicle_type?.slots || '',
            tonnage: device.vehicle?.vehicle_type?.tonnage || '',
            name_of_user_for_gps: device.vehicle?.name || '',
            version: device.hardware_version,
            service_package: ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            //   device_type: Yup.string()
            //     .max(100)
            //     .required('Tên không được để trống'),
            //   license_plate: Yup.string()
            //     .email('Email chưa đúng định dạng')
            //     .required('email không được để trống'),
            //   phone: Yup.string()
            //     .matches(
            //       /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
            //       'Số điện thoại chưa đúng định dạng'
            //     )
            //     .required('Số điện thoại không được để trống'),
            //   address: Yup.string()
            //       .max(100)
            //       .required('Địa chỉ không được để trống'),
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* <Grid item md={6} xs={12}>
                  <InputLabel>
                    Loại phương tiện <span className="text-danger">*</span>
                  </InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    helperText={
                      touched.title_device_type && errors.title_device_type
                    }
                    margin="normal"
                    name="title_device_type"
                    value={values.title_device_type}
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue('title_device_type', e.target.value);
                      handleSelectVehicle(e);
                    }}
                    //onChange={(e) => handleSelectVehicle(e)}
                    SelectProps={{ native: true }}
                    variant="outlined"
                  >
                    <option>Chọn loại phương tiện</option>
                    {vehicleType &&
                      vehicleType.map(type => (
                        <option key={type.id} value={type.name}>
                          {type.name}
                        </option>
                      ))}
                  </TextField>
                </Grid>
                {vehicleSelected?.id !== 1 && (
                  <Grid item md={6} xs={12}>
                    <InputLabel>
                      Biển số xe <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      error={Boolean(
                        touched.vehicle_license_plate &&
                          errors.vehicle_license_plate
                      )}
                      className="input-no-fieldset"
                      fullWidth
                      helperText={
                        touched.vehicle_license_plate &&
                        errors.vehicle_license_plate
                      }
                      margin="normal"
                      name="vehicle_license_plate"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.vehicle_license_plate}
                      variant="outlined"
                    />
                  </Grid>
                )}
                {vehicleSelected?.id === 1 && (
                  <Grid item md={6} xs={12}>
                    <InputLabel>
                      Tên người <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      error={Boolean(
                        touched.name_of_user_for_gps &&
                          errors.name_of_user_for_gps
                      )}
                      className="input-no-fieldset"
                      fullWidth
                      helperText={
                        touched.name_of_user_for_gps &&
                        errors.name_of_user_for_gps
                      }
                      margin="normal"
                      name="name_of_user_for_gps"
                      size="small"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name_of_user_for_gps}
                      variant="outlined"
                    />
                  </Grid>
                )} */}

                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Serial thiết bị <span style={{ color: 'red' }}></span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    disabled={true}
                    helperText={touched.email && errors.email}
                    margin="normal"
                    className="input-no-fieldset"
                    name="email"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.serial}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Phiên bản <span style={{ color: 'red' }}></span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.version && errors.version)}
                    className="input-no-fieldset"
                    fullWidth
                    disabled={true}
                    helperText={touched.version && errors.version}
                    margin="normal"
                    name="version"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.version}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Gói cước <span className="text-danger"></span>
                  </InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    margin="normal"
                    name="service_package"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.service_package}
                    SelectProps={{
                      native: true
                    }}
                    variant="outlined"
                    placeholder="Chọn gói cước"
                  >
                    <option>Chọn gói cước</option>
                    {serviceOption &&
                      serviceOption.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField>
                </Grid>

                {vehicleSelected?.id === 6 && (
                  <Grid item md={6} xs={12}>
                    <InputLabel>
                      Tải trọng <span className="text-danger">*</span>
                    </InputLabel>
                    <TextField
                      className="input-no-fieldset"
                      size="small"
                      fullWidth
                      select
                      margin="normal"
                      name="tonnage"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      SelectProps={{
                        native: true
                      }}
                      value={values.tonnage}
                      variant="outlined"
                    >
                      <option>Chọn loại tải trọng</option>
                      {vehicleSelected &&
                        vehicleSelected?.tonnage.map(type => (
                          <option key={type.id} value={type.value}>
                            {type.name}
                          </option>
                        ))}
                    </TextField>
                  </Grid>
                )}

                {vehicleSelected?.id === 4 && (
                  <Grid item md={6} xs={12}>
                    <InputLabel>
                      Số chỗ <span className="text-danger">*</span>
                    </InputLabel>
                    <TextField
                      className="input-no-fieldset"
                      size="small"
                      fullWidth
                      select
                      margin="normal"
                      name="slots"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      SelectProps={{
                        native: true
                      }}
                      value={values.slots}
                      variant="outlined"
                    >
                      <option>Chọn số chỗ</option>
                      {vehicleSelected &&
                        vehicleSelected?.slots.map(type => (
                          <option key={type.id} value={type.value}>
                            {type.name}
                          </option>
                        ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      onClick={() => onClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Cập nhật
                    </Button>
                    {statusCreate === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
}));

export default React.memo(EditDeviceModal);
