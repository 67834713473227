export const roles = {
    ADMIN: 'admin',
    AGENCY: 'agency',
    ENDUSER: 'enduser',
};

export const roleUsers = [
    { value: 1, label: 'Admin' },
    { value: 2, label: 'Nhân viên sale' },
    { value: 3, label: 'Nhân viên chăm sóc khách hàng' },
    { value: 4, label: 'Đại lý' },
    { value: 5, label: 'Nhân viên' },
    { value: 6, label: 'End user' },
]
