import { Box, Tooltip, IconButton, Badge, Popover } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListAlerts,
  getListNotifications,
  readAllNotificationById,
  readNotificationById
} from 'src/features/notificationSlice';
import React, { useEffect, useState } from 'react';
import { NotificationList } from './NotificationList';

const Notifier = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const params = {
    page: 1,
    page_size: 15,
  }
  const [isAlertView, setIsAlertView] = useState(false);
  const dispatch = useDispatch();
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const listNotification = useSelector(state => state.notificationSlice.listNotification);
  const listAlert = useSelector(state => state.notificationSlice.listAlert);
  const numUnreadNotifications = useSelector(state => state.notificationSlice.numUnreadNotifications);
  const filteredNotifications = isAlertView ? listAlert : listNotification;
 
  useEffect(() => {
    if (dataLogin) {
      dispatch(getListNotifications({params: params, isRefresh: true}));
      dispatch(getListAlerts({params: params, isRefresh: true}));
    }
  }, [dataLogin]);

  const hideNotification = () => {
    setAnchorEl(null);
  };

  const showNotifications = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickNotification = notification => {
    if (!notification.is_read) {
      dispatch(readNotificationById({id: notification.id}));
    }
    setAnchorEl(null);
  };

  const handleLoadMoreNotification = (pageNext) => {
    const newParam = {...params, page : pageNext} 
    if(isAlertView){
      dispatch(getListAlerts({params: newParam, isRefresh: false}));
    }else{
      dispatch(getListNotifications({params: newParam, isRefresh: false}));
    }
  };

  const readAll = () => {
    const userId = dataLogin.id;
    if (userId){
      dispatch(readAllNotificationById({id: userId}))
      if(isAlertView){
        dispatch(getListAlerts({params: params, isRefresh: true}));
      }else{
        dispatch(getListNotifications({params: params, isRefresh: true}));
      }
    }
  };

  return (
    <>
      <Tooltip>
        <IconButton onClick={showNotifications} edge="end" style={{marginRight:"0px"}}>
          {numUnreadNotifications > 0 && !anchorEl ? (
            <Badge
              style={{ width: '25px', height: '25px', borderRadius: '50%' }}
              badgeContent={numUnreadNotifications}
              color="primary"
              variant="standard"
            >
              <NotificationsIcon color="primary" style={{ color: '#D9042F0' }} />
            </Badge>
          ) : (
            <NotificationsIcon color="primary" style={{ color: '#90a0b7' }} />
          )}
        </IconButton>
      </Tooltip>0

      <Popover
        disableRestoreFocus
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={hideNotification}
      >
        <div
          style={{
            width: '516px',
            height: '100%',
            color: 'white',
            backgroundColor: 'rgba(255, 145, 145, 0.25)'
          }}
        >
          <div
            style={{
              backgroundColor: '#ffffff',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '48px',
              padding: '0 16px',
              color: '#03294a',

              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              border: '1px solid #e6e9ed'
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Box display={'flex'} alignItems="center" py={1}>
                <Box
                  style={{
                    fontSize: '20px',
                    fontWeight: 600,
                    color: !isAlertView ? '#1E2323' : '#C5C6D2',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIsAlertView(false)}
                  mr={2}
                >
                  Thông báo
                </Box>
                <Box
                  style={{
                    fontSize: '20px',
                    fontWeight: 600,
                    color: isAlertView ? '#1E2323' : '#C5C6D2',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIsAlertView(true)}
                >
                  Cảnh báo
                </Box>
              </Box>
              <Box
                onClick={() => readAll()}
                style={{
                  minWidth: 0,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  margin: '0 10px',
                  cursor: 'pointer'
                }}
              >
                Đánh dấu tất cả là đã đọc
              </Box>
            </Box>
          </div>
          <div style={{ height: 'auto', maxHeight: '736px' }}>
            {Boolean(anchorEl) && (
              <NotificationList
                notifications={filteredNotifications}
                onClickNotification={onClickNotification}
                handleLoadMoreNotification={handleLoadMoreNotification}
              />
            )}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default Notifier;
