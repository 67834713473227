import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { STATUS_API } from 'src/app/constant/config';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { updateWarehouseTransfer } from 'src/features/agencySlice';
import CustomDialog from 'src/app/components/CustomDialog';


function WarehouseTransfer({ open, onClose, itemSelected }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusTransfer = useSelector(state => state.agencySlice.statusTransfer);
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);
  const [agencySelected, setAgencySelected] = useState(0);

  const handleSubmit = (data) => {
    if(data && data?.id){
        dispatch(updateWarehouseTransfer(
            {
            "from_agency_id": data.id,
            "to_agency_id": agencySelected,
            }
        ))
    }
    onClose();
  };

  const handleChoose = (e) => {
    setAgencySelected(Number(e.target.value))
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog title="Chuyển kho thiết bị" open={open} onClose={handleClose} sizeSmall={true}>
      <div>
        <Typography
          style={{
              paddingBottom: '25px',
              color: '#475461',
          }}
        >
          Chuyển tất cả các thiết bị của đại lý này tới đại lý mới
        </Typography>
        <Formik
          initialValues={{id:itemSelected.id || ''}}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            id: Yup.number()
              .max(100)
              .required('Tên đại lý không được để trống'),
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <InputLabel>Chọn đại lý nhận <span className="text-danger">*</span></InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    helperText={touched.business_id && errors.business_id}
                    margin="normal"
                    name="id"
                    onBlur={handleBlur}
                    onChange={(e) => handleChoose(e)}
                    SelectProps={{
                      native: true
                    }}
                    value={agencySelected}
                    variant="outlined"
                  >
                    <option>Đại lý nhận</option>
                    {listAgencies && listAgencies.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                
              </Grid>
              <Box my={3}>
                <Box 
                  style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '15px',
                      '& .left-button': {
                      display: 'flex',
                      }
                  }}
                >
                  
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      onClick={() => onClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Chuyển kho
                    </Button>
                    {statusTransfer === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
}));

export default React.memo(WarehouseTransfer);
