import React from 'react';
import 'date-fns';
import { CAR_STATUS } from 'src/app/constant/config'
import { Paper } from '@material-ui/core';


const CarStatusBar = ({
  statisticVehicleTracking,
  setCarStatus,
  carStatus
}) => {
  const handleSelectCar = (car) => {
    if(carStatus === car.value) {
      setCarStatus('')
    } else {
      setCarStatus(car.value)
    }
  }
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '14px',
      lineHeight: '0.5em',
      width:'100%',
    }}>

      {
        CAR_STATUS && CAR_STATUS.map((car, index) =>
          <div style={{ position: 'relative'}} key={index++}>
            <Paper className={`CarStatusIcon ${carStatus === car.value ? 'CarStatusActive' : ''}`} onClick={() => handleSelectCar(car)}>
              <img alt="icon" src={`/static/iconSvg/carsv2/${car?.icon}.svg`} className={`imageCar`} />
            </Paper>
            <br />
            {car?.label}
            <span style={{
              position: 'absolute',
              top: 0,
              right: 0,
              fontSize: '10px',
              background:'#D9042F',
              width: '14px',
              height: '14px',
              borderRadius: '50%',
              color: 'white',
              textAlign: 'center',
              paddingTop: '4px',
            }}>{
                index === 0 && statisticVehicleTracking ? statisticVehicleTracking.running_vehicles.length :
                  index === 1 && statisticVehicleTracking ? statisticVehicleTracking.stop_vehicles.length :
                    index === 2 && statisticVehicleTracking ? statisticVehicleTracking.over_speed_vehicles.length :
                      index === 3 && statisticVehicleTracking ? statisticVehicleTracking.lost_gps_vehicles.length :
                        index === 4 && statisticVehicleTracking ? statisticVehicleTracking.lost_gsm_vehicles.length : 0
              }</span>
          </div>
        )
      }
    </div>
  );
};

export default CarStatusBar;
