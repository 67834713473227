import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import LoadingComponent from 'src/app/components/Loading';
import { Pagination } from '@material-ui/lab';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { useState } from 'react';
const StatisticServiceTable = ({
  listService,
  params,
  setParams,
  totalService,
  getStatisticServicesRef
}) => {
  const isLoading = useSelector(state => state.statisticSlice.isLoading);
  const [limit, setLimit] = useState(PAGE_SIZE_LIST);
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const formatCurrency = (amount, locale = 'vi-VN', currency = 'VND') => {
    return amount.toLocaleString(locale, {
      style: 'currency',
      currency: currency
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (!getStatisticServicesRef) return;
    const newparams = Object.assign({}, params, { page: newPage });
    setParams(newparams);
    getStatisticServicesRef(newparams);
  };

  return (
    <>
      <Card className={classes.root}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div>
            <PerfectScrollbar>
              {listService && listService.length ? (
                <Box minWidth={1050}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Ngày thanh toán</TableCell>
                        <TableCell>Mã thiết bị</TableCell>
                        <TableCell>Serial</TableCell>
                        <TableCell>Phiên bản</TableCell>
                        <TableCell>Tên/Biển số</TableCell>
                        <TableCell>Hạn gói</TableCell>
                        <TableCell>Số tiền</TableCell>
                        <TableCell>Người dùng</TableCell>
                        <TableCell>Số điện thoại</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listService.map((data, index) => (
                        <TableRow hover key={data?.id}>
                          <TableCell>
                            {data?.created_at
                              ? moment(data.created_at).format('DD-MM-yyyy')
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {data?.deviceType?.name || '--'}
                          </TableCell>
                          <TableCell>{data?.serial || '--'}</TableCell>
                          <TableCell>
                            {data?.hardware_version || '--'}
                          </TableCell>
                          <TableCell>{data?.vehicle?.license_plate || '--'}</TableCell>
                          <TableCell>
                            {`${data?.totalRemainingDays} Ngày` || '--'} 
                          </TableCell>
                          <TableCell>
                            {formatCurrency(data?.totalCost) || '--'}
                          </TableCell>
                          <TableCell>
                            {data?.end_user?.user?.full_name || '--'}
                          </TableCell>
                          <TableCell>
                            {data?.end_user?.user?.phone || '--'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <div className="text-center pb-5">
                  <img
                    src="/static/empty.png"
                    className="justify-content-center"
                  />
                  <h4> Danh sách trống </h4>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Card>
      {listService && listService?.length > 0 && (
        <div class="mt-3 mr-4 float-right">
          <Box style={{display:'flex', justifyContent:'center', alignItems:'center', gap:'15px'}}>
            <Typography style={{ fontSize:'14px'}}>Tổng: {totalService}</Typography>
          <Pagination
            count={Math.ceil(totalService / limit)}
            color='primary'
            size="small"
            onChange={handlePageChange}
            page={Number(params.page)}
            showFirstButton
            showLastButton
          />
          </Box>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  groupAction: {
    display: 'flex',
    alignItems: 'center',
    '& .action': {
      cursor: 'pointer',
      padding: '0 5px',
      '&:hover': {
        color: '#3f51b5'
      }
    }
  },
  minWithColumn: {
    minWidth: '150px'
  }
}));

export default StatisticServiceTable;
