import { Box, makeStyles, Avatar } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UpdateProfile from './UpdateProfile';
import { getListProvinces } from 'src/features/provinceSlice';
import { GetUserInfo } from 'src/features/authSlice';
import { STATUS_API } from 'src/app/constant/config';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    background: '#FFFFFF',
    height: '100%'
  },
  wrapper: {
    maxWidth: 968,
    margin: '0 auto'
  },
  AvatarWrapper: {
    width: '120px',
    height: '120px',
    position: 'relative',
    marginTop: '36px'
  },
  IconChange: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '0',
    right: '5px'
  },
  FullName: {
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '36px',
    display: 'flex',
    aligItems: 'center',
    margin: 0,
    marginTop: '30px'
  },
  ProfileInfor: {
    marginTop: '100px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  InforUser: {
    width: '384px',
    maxWidth: '384px'
  },
  Title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '40px',
    '& p': {
      fontSize: '20px',
      fontWeight: '700',
      margin: 0,
      color: '#52535C'
    },
    '& button': {
      fontFamily: 'Roboto',
      border: 'none',
      background: 'none',
      outline: 'none',
      color: '#8F0A0C',
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: '500',
      cursor: 'pointer'
    }
  },
  LabelInfor: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '32px',
    '& .label': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px',
      color: '#8B8C9B',
      margin: 0
    },
    '& .infor': {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '22px',
      margin: 0,
      color: '#1E2323',
      '&.button': {
        cursor: 'pointer',
        color: '#8F0A0C'
      }
    }
  },
  SeperateLine: {
    border: '0.5px solid #EEF2FA',
    maxHeight: '270px'
  }
}));

const UserProfileView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusUpdate = useSelector(state => state.userSlice.statusUpdate);
  const [openEditModal, setOpenEditModal] = useState(false);
  const listProvinces = useSelector(state => state.provinceSlice.listProvinces);

  useEffect(() => {
    dispatch(getListProvinces());
  }, []);

  useEffect(() => {
    if (statusUpdate === STATUS_API.SUCCESS) {
      dispatch(GetUserInfo());
    }
  }, [statusUpdate]);

  const closeModal = () => {
    setOpenEditModal(false);
  };

  const showModalUserClick = () => {
    setOpenEditModal(true);
  };

  const getProvinceAddress = citizenId => {
    const province = listProvinces?.find(
      province => province.id === +citizenId
    );
    return province?.name;
  };

  const RenderTitle = ({ title, action }) => {
    return (
      <Box className={classes.Title}>
        <p>{title}</p>
        <button onClick={() => action?.()}>Chỉnh sửa</button>
      </Box>
    );
  };

  const RenderInfor = ({ label, infor, action }) => {
    return (
      <Box className={classes.LabelInfor}>
        <p className="label">{label}</p>
        <p
          className={`infor ${action ? 'button' : ''}`}
          onClick={() => action?.()}
        >
          {infor}
        </p>
      </Box>
    );
  };

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const avatarImage = dataLogin && dataLogin.avatar ? `${process.env.REACT_APP_IMG_SERVER}/${dataLogin.avatar}` : "/static/images/no-avatar.svg";
  const inputRef = useRef();

  return (
    <>
      {openEditModal && (
        <UpdateProfile
          open={openEditModal}
          onClose={closeModal}
          user={dataLogin}
        />
      )}
      <Box className={classes.root}>
        <Box className={classes.wrapper}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box className={classes.AvatarWrapper}>
              <Avatar
                src={avatarImage}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%'
                }}
              />
              <Box
                className={classes.IconChange}
                onClick={() => inputRef.current.click()}
              >
                <CameraAltIcon
                  style={{
                    width: '60%',
                    height: '60%',
                    borderRadius: '50%',
                    color: '#144DD1'
                  }}
                />
              </Box>
              <input
                ref={inputRef}
                type="file"
                accept="image/x-png,image/jpeg"
                style={{ display: 'none' }}
              />
            </Box>
            <Box className={classes.FullName}>{dataLogin?.full_name || ''}</Box>
          </Box>
          <Box className={classes.ProfileInfor}>
            <Box className={classes.InforUser}>
              <RenderTitle
                title={'Thông tin'}
                action={() => showModalUserClick()}
              />
              <RenderInfor
                label="Số điện thoại đăng nhập"
                infor={dataLogin?.phone || '--'}
              />
              <RenderInfor
                label="Tên người sử dụng"
                infor={`${dataLogin?.full_name}`}
              />
              <RenderInfor label="Email" infor={dataLogin?.email || '--'} />
              <RenderInfor
                label="Mật khẩu"
                infor="Thay đổi mật khẩu"
                //action={() => setModalChangePass({ show: true })}
              />
            </Box>
            <Box className={classes.SeperateLine} />
            <Box className={classes.InforUser}>
              <RenderTitle title={'Thông tin'} action={showModalUserClick} />
              <RenderInfor
                label="Tên đại lý"
                infor={dataLogin?.phone || '--'}
              />
              <RenderInfor label="Địa chỉ" infor={dataLogin?.address || '--'} />
              <RenderInfor label="Tỉnh/Thành phố" infor={getProvinceAddress(dataLogin?.citizen_id) || '--'} />
              <RenderInfor
                label="Trạng thái"
                infor={
                  dataLogin?.status === 1 ? 'Đang hoạt động' : 'Dừng hoạt động'
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserProfileView;
