import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Page from 'src/app/components/Page';
import './style.css';
import Loading from 'src/app/components/Loading';
import MapOsm from './MapOsm';

const TrackingView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root}>
      <Box className="flex" style={{position:'relative'}}>
            <MapOsm
              className="map"
              loadingElement={<Loading />}
            />
      </Box>
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingLeft: 0,
    paddingRight: 0
  },
}));

export default React.memo(TrackingView);
