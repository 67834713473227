import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  makeStyles,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Page from 'src/app/components/Page';
import { clearMassageErrorAction, Login } from '../../../features/authSlice';
import { DRAFT_USER } from 'src/app/constant/config';
import { useDispatch, useSelector } from 'react-redux';
import MsgError from '../../components/MsgError';
import Cookie from 'js-cookie';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',

    position: 'relative',
    overflow: 'hidden'
  },
  textLogo: { fontWeight: 'bold', fontSize: '28px', marginBottom: '30px' },
  leftTextLogo: { color: '#000000' },
  rightTextLogo: { color: '#8F0A0C' },
  textRight: {
    padding: '30px',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 'bold',
    background: '#D9042F',
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '100%',
  },
  textBottomPage: {
    color: '#BDBDBD',
    textAlign: 'center',
    width: '100%',
    marginTop: '18vh'
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isLoadingLogin_gs = useSelector(
    state => state.authSlice.isLoadingLogin
  );
  const failStatus_gs = useSelector(state => state.authSlice.failStatus);
  const [isHiddenMsg_ls, setHiddenMsgStatus] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const token = Cookie.get('access-token')
  
  useEffect(() => {
    if (token) {
      navigate('/app/tracking', { replace: true });
    }
  }, [token]);

  const handleCheckRememberMe = () => {
    setRememberMe(!rememberMe)
  }
  return (
    <Page className={classes.root} title="Đăng nhập">
      <Grid item xs={12} style={{height: '100%'}}>
        <Grid container justify="space-between"  style={{height: '100%'}}>
          <Grid lg={6} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <Container maxWidth="sm" style={{marginTop: '50px'}}>
                <Formik
                  initialValues={{
                    phone: '',
                    password: ''
                  }}
                  validationSchema={Yup.object().shape({
                    phone: Yup.string()
	                    .matches(new RegExp('^[0-9-+]{9,11}$'), 'Số điện thoại phải đúng định dạng')
                      .required('Bạn cần nhập số điện thoại'),
                    password: Yup.string()
                      .max(255)
                      .required('Bạn cần nhập mật khẩu')
                  })}
                  onSubmit={values => {
                    dispatch(clearMassageErrorAction());
                    setHiddenMsgStatus(false);
                    dispatch(Login(values));
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={1} style={{ textAlign: 'left' }}>
                        <div style={{width: '67px', height:'80px', display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                          <img
                            alt="Logo"
                            src="/static/logo_login.svg"
                            style={{ width: '100%', height: '100%',  objectFit:'cover' }}
                          />
                        </div>
                        <Typography color='primary' style={{ fontSize: '28px', marginTop: '32px', fontWeight:'700' }} variant="h2">
                          Đăng nhập
                        </Typography>
                        <br />
                      </Box>
                      <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        fullWidth
                        helperText={touched.phone && errors.phone}
                        label="Số điện thoại"
                        margin="normal"
                        name="phone"
                        onBlur={e => {
                          handleBlur(e);
                          setHiddenMsgStatus(true);
                        }}
                        onChange={e => {
                          handleChange(e);
                          setHiddenMsgStatus(true);
                        }}
                        type="text"
                        value={values.phone}
                      />
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Mật khẩu"
                        margin="normal"
                        name="password"
                        onBlur={e => {
                          handleBlur(e);
                          setHiddenMsgStatus(true);
                        }}
                        onChange={e => {
                          handleChange(e);
                          setHiddenMsgStatus(true);
                        }}
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                                {showPassword ? <VisibilityOff style={{color:'#D9042F'}}/> : <Visibility style={{color:'#D9042F'}}/>}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography
                        color={'error'}
                        variant={'subtitle1'}
                        align={'center'}
                      >
                        {!isHiddenMsg_ls && failStatus_gs && (
                          <MsgError content={failStatus_gs.message} />
                        )}
                      </Typography>
                      <Box my={2}>
                        <Grid container>
                          <Grid lg={6} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox 
                                checked={rememberMe}
                                color="primary"
                                onChange={handleCheckRememberMe} 
                                name="rememberMe"
                              />}
                            label="Nhớ đăng nhập"
                          />
                          </Grid>
                          <Grid lg={6} md={6}
                            justify="flex-end"
                            style={{ 
                                display: 'inline-flex', 
                                verticalAlign: 'middle', 
                                alignItems: 'center', 
                                fontSize: '18px', 
                                fontWeight: '700', 
                                color: '#D9042F', 
                                textAlign: 'right' 
                              }}
                            >
                            Quên mật khẩu ? 
                          </Grid>
                        </Grid>
                       
                      </Box>
                      <Box my={2}>
                        <Button
                          style={{ color: '#fff', marginBottom:'10px' }}
                          disabled={isLoadingLogin_gs}
                          fullWidth
                          color='primary'
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Đăng nhập
                          {isLoadingLogin_gs && (
                            <div style={{ marginLeft: 20 }}>
                              <CircularProgress color='secondary' size={20} />
                            </div>
                          )}
                        </Button>
                        <span 
                          style={{
                            color:'#BDBDBD',
                            textDecoration:'underline',
                            marginTop:'10px !important',
                            cursor:'pointer',
                            fontSize:'14px',
                          }}
                          onClick={() => navigate('/privacy-policy')}
                        >
                          <a>
                            <i>Chính sách quyền riêng tư</i>
                          </a>
                        </span>
                      </Box>
                    
                      <Box
                          style={{
                            color: '#BDBDBD',
                            textAlign: 'center',
                            width: '100%',
                            marginTop: '18vh'
                          }}
                        >
                          <span>
                            {' '}
                            Sản phẩm thuộc bản quyền công ty{' '}
                            <a target="_blank" href="https://vntech24h.com/">
                              Vntech24h.,Jsc.
                            </a>
                            .<br></br>
                            Hỗ trợ & mua hàng: 0706 90 3333; Trở thành đại lý:
                            0934.88 83 86{' '}
                          </span>
                        </Box>
                    </form>
                  )}
                </Formik>
              </Container>
            </Box>
          </Grid>
          <Grid lg={6} md={6} style={{margin: 0, padding: 0, height: '100%'}}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <div style={{position: 'relative', height: '100%'}}>
                <img alt="Logo" src="/static/bg_login.jpeg" width={'100%'} height={'100%'} />
                <div className={classes.textRight} >
                  <span className={classes.textBottomRight}>
                    HỆ THỐNG GIÁM SÁT HÀNH TRÌNH VGPS
                  </span>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default LoginView;
