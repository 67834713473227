import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { getListDriver } from 'src/features/driverSlice';
import Results from './Results.js';
import ToolBar from './ToolBar.js';
import {
  PAGE_SIZE_LIST,
  STATUS_API
} from 'src/app/constant/config';
import { roles } from 'src/app/constant/roles.js';
import { useQuery } from 'src/app/hooks/useQuery.js';
import { _convertObjectToQuery } from 'src/app/utils/apiService.js';

const ServiceStaff = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();
  const query = useQuery();
  const [params, setParams] = useState({
    page: 1,
    page_size: PAGE_SIZE_LIST
  });

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.AGENCY;
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  const page = query.get('page') || 1;
  const keywords = query.get('keywords');

  const statusCreate = useSelector(state => state.driverSlice.statusCreate);
  const statusDelete = useSelector(state => state.driverSlice.statusDelete);
  const statusDeleteMulti = useSelector(state => state.driverSlice.statusDeleteMulti);
  const statusUpdate = useSelector(state => state.driverSlice.statusUpdate);
  const listDriver = useSelector(state => state.driverSlice.listDriver);

  useEffect(() => {
    const queryValue = {
      ...params,
      page: page ? page : undefined,
      keywords: keywords ? keywords : undefined
    };
    setParams(queryValue);
  }, [query, agencyID]);

  const getListDriverWithParams = query => {
    navigate('/app/manage/driver?' + _convertObjectToQuery(query.data));
    dispatch(getListDriver({ id: agencyID, data: query.data}));
  };

  useEffect(() => {
    if (agencyID) dispatch(getListDriver({ id: agencyID, data: params }));
  }, [agencyID]);

  useEffect(() => {
    if (
      statusCreate === STATUS_API.SUCCESS ||
      statusDelete === STATUS_API.SUCCESS ||
      statusUpdate === STATUS_API.SUCCESS ||
      statusDeleteMulti === STATUS_API.SUCCESS
    ) {
      getListDriverWithParams({ id: agencyID, data: params});
    }
  }, [statusCreate, statusDelete, statusUpdate, statusDeleteMulti]);

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: '98%', margin: '0 auto' }}>
          <ToolBar
            searchRef={getListDriverWithParams}
          />
          <Results
            className="mt-3"
            listDriver={listDriver}
            getListDriverRef={getListDriverWithParams}
          />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    width: '100%',
    marginTop: '17px'
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

export default ServiceStaff;
