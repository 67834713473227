import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Popover,
  Typography,
  List,
  ListItem,
  Checkbox,
  withStyles,
  Fade
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DialogConfirm from 'src/app/components/DialogConfirm';
import { closeDialogConfirm, showDialogConfirm } from 'src/features/uiSlice';
import { MESSAGE } from 'src/app/constant/message';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import LoadingComponent from 'src/app/components/Loading';
import { useDispatch } from 'react-redux';
import { deleteMultiAgency } from 'src/features/agencySlice';
import EditAgency from './EditAgency';
import WarehouseTransfer from './warehouseTransfer';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    whiteSpace: 'nowrap'
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap'
  },
  root: {
    padding: '6px'
  }
}))(TableCell);

const Results = ({
  className,
  totalAgencies,
  listAgencies,
  getListAgenciesRef
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.agencySlice.isLoading);
  const listProvinces = useSelector(state => state.provinceSlice.listProvinces);
  const classes = useStyles();
  const [limit, setLimit] = useState(PAGE_SIZE_LIST);
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState();
  const [itemCurrent, setItemCurrent] = useState();
  const [rowsSelected, setRowsSelected] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [params, setParams] = useState({
    page: page,
    page_size: limit
  });

  const handleClickMenu = (event, agency) => {
    setAnchorEl(event.currentTarget);
    setItemCurrent({
      id: agency.id,
      name: agency.name,
      email: agency.leader?.email,
      address: agency.leader?.address,
      phone: agency.leader?.phone,
      business_id: agency.business?.id,
      province_id: agency.leader?.citizen_id,
      status: agency.leader?.status
    });

    setSelectedItem(agency.id);
  };
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleEditAgency = () => {
    setOpenEditModal(true);
    handleCloseMenu();
  };

  const handleWarehouseTransfer = () => {
    setOpenTransferModal(true);
    handleCloseMenu();
  };

  const closeModalEdit = () => {
    setOpenEditModal(false);
  };
  const closeModalTransfer = () => {
    setOpenTransferModal(false);
  };

  const handleDeleteAgency = () => {
    dispatch(showDialogConfirm());
    handleCloseMenu();
  };

  const handleDeleteMultiAgency = () => {
    dispatch(showDialogConfirm());
    handleCloseMenu();
  };

  const confirmDelete = () => {
    const listId = rowsSelected?.length > 0 ? rowsSelected : [selectedItem];
    setRowsSelected([]);
    dispatch(closeDialogConfirm());
    dispatch(deleteMultiAgency({ agency_ids: listId }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (!getListAgenciesRef) return;
    const newParams = Object.assign({}, params, { page: newPage });
    setParams(newParams);
    getListAgenciesRef(newParams);
  };

  const checked = id => {
    return rowsSelected.indexOf(id) !== -1;
  };
  const select = driver => {
    if (rowsSelected.indexOf(driver) === -1) {
      const newSelected = [driver, ...rowsSelected];
      setRowsSelected(newSelected);
    } else {
      const newSelected = rowsSelected.filter(i => i !== driver);
      setRowsSelected(newSelected);
    }
  };

  const checkAll = () => {
    if (rowsSelected.length > 0) {
      setRowsSelected([]);
    }
    if (
      listAgencies.length > 0 &&
      rowsSelected.length !== listAgencies.length
    ) {
      const all = listAgencies.map(item => {
        return item.id;
      });
      setRowsSelected(all);
    }
  };

  const getProvinceAddress = citizenId => {
    const province = listProvinces?.find(
      province => province.id === +citizenId
    );
    return province?.name;
  };

  return (
    <>
      {openEditModal && (<EditAgency open={openEditModal} agencySelected={itemCurrent} onClose={closeModalEdit} />)}

      {openTransferModal && (<WarehouseTransfer open={openTransferModal} itemSelected={itemCurrent} onClose={closeModalTransfer} />)}
     
      <DialogConfirm
        title={MESSAGE.CONFIRM_DELETE_AGENCY}
        textOk={MESSAGE.BTN_YES}
        textCancel={MESSAGE.BTN_CANCEL}
        content={MESSAGE.CONTENT_DELETE_AGENCY}
        callbackOk={() => confirmDelete()}
        callbackCancel={() => dispatch(closeDialogConfirm())}
      />

      <Card className={clsx(classes.root, className)}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div>
            {rowsSelected.length > 0 && (
              <Fade in={rowsSelected.length > 0}>
                <Box
                  minWidth={1050}
                  style={{ fontSize: '14px', padding: '6px 4px' }}
                >
                  <span>
                    <Checkbox
                      checked={rowsSelected.length > 0}
                      onChange={() => checkAll()}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </span>

                  <span
                    onClick={handleDeleteMultiAgency}
                    className="mx-2 btnDevice"
                    color="primary"
                    variant="outlined"
                    size="small"
                  >
                    <img
                      src={`/static/iconSvg/delete-icon.svg`}
                      alt="delete"
                      style={{ paddingRight: '5px', paddingBottom: '4px' }}
                    />
                    <span className="pt-1"> Xóa </span>
                  </span>
                </Box>
              </Fade>
            )}
            <PerfectScrollbar>
              {listAgencies && listAgencies.length ? (
                <Box minWidth={1050}>
                  <Table>
                    {rowsSelected.length === 0 && listAgencies?.length > 0 && (
                      <TableHead>
                        <TableRow>
                          <StyledTableCell width={50}>
                            <Checkbox
                              checked={rowsSelected.length > 0}
                              onChange={() => checkAll()}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell width={300}>
                            Tên đại lý
                          </StyledTableCell>
                          <StyledTableCell width={320}>
                            Số điện thoại
                          </StyledTableCell>
                          <StyledTableCell width={320}>Email</StyledTableCell>
                          <StyledTableCell width={320}>Địa chỉ</StyledTableCell>
                          <StyledTableCell width={320}>
                            Tỉnh thành
                          </StyledTableCell>
                          {/* <StyledTableCell>Loại đại lý</StyledTableCell> */}
                          <StyledTableCell width={150}>
                            Đã kích hoạt
                          </StyledTableCell>
                          <StyledTableCell width={120}>
                            Thiết bị
                          </StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>
                    )}
                    <TableBody>
                      {listAgencies.map((agency, index) => (
                        <TableRow hover key={agency.id}>
                          <StyledTableCell
                            style={{ maxWidth: '45px' }}
                            onClick={() => select(agency?.id)}
                          >
                            <Checkbox
                              checked={checked(agency.id)}
                              onChange={() => select(agency.id)}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {agency?.name || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {agency?.leader?.phone || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {agency?.leader?.email || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {agency?.leader?.address || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {getProvinceAddress(agency?.leader?.citizen_id) ||
                              '-'}
                          </StyledTableCell>
                          {/* <StyledTableCell>
                            {agency?.business?.title || '-'}
                          </StyledTableCell> */}
                          <StyledTableCell>
                            {agency?.deviceActive || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {agency?.devicesTotal || '-'}
                          </StyledTableCell>
                          {/* <StyledTableCell>{moment(agency.created_at).format('DD-MM-yyyy')}</StyledTableCell> */}

                          <StyledTableCell>
                            <span style={{ color: '#AEB1C5' }}>
                              <MoreVertIcon
                                aria-describedby={`menu-device-${agency.id}`}
                                className={`cursor-pointer hover-red`}
                                onClick={e => handleClickMenu(e, agency)}
                              />
                            </span>

                            <Popover
                              id={`menu-device-${agency.id}`}
                              className="popover-device"
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <Typography>
                                <List>
                                  <ListItem className="border-bottom list-menu-device cursor-pointer">
                                    Đổi mật khẩu
                                  </ListItem>
                                  <ListItem
                                    onClick={handleEditAgency}
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Chỉnh sửa
                                  </ListItem>
                                  <ListItem
                                    onClick={() => handleWarehouseTransfer()}
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Chuyển kho
                                  </ListItem>
                                  <ListItem
                                    onClick={handleDeleteAgency}
                                    className="cursor-pointer list-menu-device"
                                  >
                                    <span class="text-danger"> Xóa </span>
                                  </ListItem>
                                </List>
                              </Typography>
                            </Popover>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <div className="text-center pb-5">
                  <img src="/static/empty.png" class="justify-content-center" />
                  <h4> Danh sách trống </h4>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Card>
      {listAgencies && listAgencies.length > 0 && (
        <div class="mt-3 mr-4 float-right">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15px'
            }}
          >
            <Typography style={{ fontSize: '14px' }}>
              Tổng: {totalAgencies}
            </Typography>
            <Pagination
              count={Math.ceil(totalAgencies / limit)}
              color='primary'
              size="small"
              onChange={handlePageChange}
              page={Number(params.page)}
              showFirstButton
              showLastButton
            />
          </Box>
        </div>
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  listAgencies: PropTypes.array.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    //position: 'relative',
    marginTop: '20px'
  },
  groupAction: {
    display: 'flex',
    alignItems: 'center',
    '& .action': {
      cursor: 'pointer',
      padding: '0 5px',
      '&:hover': {
        color: '#3f51b5'
      }
    }
  },
  minWithColumn: {
    minWidth: '150px'
  }
}));

export default React.memo(Results);
