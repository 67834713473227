import { makeStyles } from '@material-ui/core';
import Cookie from 'js-cookie';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ToastMessage from 'src/app/components/ToastMessage';
import { messageToastType_const, STATUS_API, PAGE_SIZE_LIST } from 'src/app/constant/config';
import { MESSAGE } from 'src/app/constant/message';
import { getListStaff, resetChange } from 'src/features/staffSlice';
import { showToast } from 'src/features/uiSlice';
import Results from './Results.js';
import ToolBar from './ToolBar.js';
import { useQuery } from 'src/app/hooks/useQuery.js';
import { _convertObjectToQuery } from 'src/app/utils/apiService.js';

const ServiceStaff = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const query = useQuery();
  const [params, setParams] = useState({
    page: 1,
    page_size: PAGE_SIZE_LIST
  });

  const statusCreate = useSelector(state => state.staffSlice.statusCreate);
  const statusDelete = useSelector(state => state.staffSlice.statusDelete);
  const statusUpdate = useSelector(state => state.staffSlice.statusUpdate);
  const statusDeleteMultiStaff = useSelector(
    state => state.staffSlice.statusDeleteMultiStaff
  );
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const agencyID = dataLogin?.agency?.id;
  const listStaff = useSelector(state => state.staffSlice.listStaff);
  const page = query.get('page') || 1;
  const keywords = query.get('keywords');

  useEffect(() => {
    const queryValue = {
      ...params,
      page: page ? page : undefined,
      keywords: keywords ? keywords : undefined
    };
    setParams(queryValue);
  }, [query, agencyID]);

  useEffect(() => {
    if (agencyID) dispatch(getListStaff({ id: agencyID, data: params }))
  }, [agencyID]);

  const getListStaffsWithParams = query => {
    navigate('/app/manage/service-staffs?' + _convertObjectToQuery(query.data));
    dispatch(getListStaff({ id: agencyID, data: query.data }));
  };

  useEffect(() => {
    if (
      statusCreate === STATUS_API.SUCCESS ||
      statusDelete === STATUS_API.SUCCESS ||
      statusUpdate === STATUS_API.SUCCESS ||
      statusDeleteMultiStaff === STATUS_API.SUCCESS
    ) {
      dispatch((getListStaff({ id: agencyID, data: params })));
    }
  }, [statusCreate, statusDelete, statusUpdate, statusDeleteMultiStaff]);

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: '98%', margin: '0 auto' }}>
          <ToolBar searchRef={getListStaffsWithParams} />
          <Results
            className="mt-3"
            listStaff={listStaff}
            getListStaffRef={getListStaffsWithParams}
            params={params}
            setParams={setParams}
          />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    //height: '100%',
    width: '100%',
    marginTop: '17px'
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 auto',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

export default ServiceStaff;
