import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { PAGE_SIZE_LIST, STATUS_API } from 'src/app/constant/config';
import { useQuery } from 'src/app/hooks/useQuery.js';
import { _convertObjectToQuery } from 'src/app/utils/apiService.js';
import {
  getListServicePackages
} from 'src/features/servicePackageSlice.js';
import Results from './Results.js';
import ToolBar from './ToolBar.js';
const ServicePackageView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const classes = useStyles();
  
  const [params, setParams] = useState({
    page: 1,
    page_size: PAGE_SIZE_LIST
  });

  const statusCreate = useSelector(
    state => state.servicePackageSlice.statusCreate
  );
  const statusDelete = useSelector(
    state => state.servicePackageSlice.statusDelete
  );
  const statusMultiDelete = useSelector(
    state => state.servicePackageSlice.statusMultiDelete
  );
  const statusUpdate = useSelector(
    state => state.servicePackageSlice.statusUpdate
  );
  const listServicePackages = useSelector(
    state => state.servicePackageSlice.listServicePackages
  );

  const page = query.get('page') || 1;
  const keywords = query.get('keywords');

  useEffect(() => {
    const queryValue = {
      ...params,
      page: page ? page : undefined,
      keywords: keywords ? keywords : undefined
    };
    setParams(queryValue);
  }, [query]);

    useEffect(() => {
      dispatch(getListServicePackages(params))
    }, []);

  const getListServicePackagesWithParams = query => {
    navigate('/app/manage/service-packages?' + _convertObjectToQuery(query));
    dispatch(getListServicePackages(query));
  };

  useEffect(() => {
    if (
      statusCreate === STATUS_API.SUCCESS ||
      statusDelete === STATUS_API.SUCCESS ||
      statusMultiDelete === STATUS_API.SUCCESS ||
      statusUpdate === STATUS_API.SUCCESS
    ) {
      getListServicePackagesWithParams(params);
    }
  }, [statusCreate, statusDelete, statusUpdate, statusMultiDelete]);

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: '98%', margin: '0 auto' }}>
          <ToolBar searchRef={getListServicePackagesWithParams} />
          <Results
            className="mt-3"
            listServicePackages={listServicePackages}
            getListServicePackagesWithParams={getListServicePackagesWithParams}
          />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(2)
  },
}));

export default ServicePackageView;
