import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { green } from '@material-ui/core/colors';
import { STATUS_API } from 'src/app/constant/config';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getListProvinces } from 'src/features/provinceSlice';
import { getListAgencies } from 'src/features/agencySlice';
import { roles, roleUsers } from 'src/app/constant/roles';
import { createUser } from 'src/features/userSlice';
import CustomDialog from 'src/app/components/CustomDialog';

const initStateFromValue = {
  username: '',
  full_name: '',
  email: '',
  phone: '',
  address: '',
  password: '',
  confirm_password: '',
  citizen_id: '',
  role: ''
};

function CreateUser({ open, onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusCreate = useSelector(state => state.staffSlice.statusCreate);
  const listProvinces = useSelector(state => state.provinceSlice.listProvinces);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;

  const [initValue, setInitValue] = useState(initStateFromValue);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChoose = (e, type, setFieldValue) => {
    if (type === 'role_type') {
      setFieldValue('role', Number(e.target.value));
    }
    if (type === 'province_type') {
      setFieldValue('citizen_id', e.target.value);
    }
  };

  const handleSubmit = data => {
    const newUser = {
      username: 'null',
      full_name: data.full_name,
      email: data.email ? data.email : 'example@gmail.com',
      phone: data.phone,
      password: data.password,
      confirm_password: data.confirm_password,
      address: data.address,
      citizen_id: data.citizen_id,
      role: data.role
    };

    setInitValue(newUser);
    dispatch(createUser(newUser));
    onClose();
  };

  const handleClose = () => {
    onClose();
    setInitValue(initStateFromValue);
  };

  useEffect(() => {
    dispatch(getListProvinces());
    if (isAdmin) dispatch(getListAgencies());
  }, [isAdmin]);

  return (
    <CustomDialog title="Thêm mới người dùng" open={open} onClose={handleClose}>
      <div>
        <Formik
          initialValues={initValue}
          validationSchema={Yup.object().shape({
            full_name: Yup.string()
              .max(100)
              .required('Tên người dùng không được để trống'),
            email: Yup.string()
              .email('Email chưa đúng định dạng'),
            phone: Yup.string()
              .matches(
                /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
                'Số điện thoại chưa đúng định dạng'
              )
              .required('Số điện thoại không được để trống'),
            address: Yup.string()
              .max(100),
            citizen_id: Yup.string()
              .max(100)
              .required('Tỉnh thành không được để trống'),
            role: Yup.string()
              .max(100)
              .required('Vai trò người dùng không được để trống'),
            password: Yup.string()
              .max(100)
              .required('Mật khẩu không được để trống'),
            confirm_password: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Mật khẩu chưa khớp')
              .required('Xác nhận lại mật khẩu')
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Tên đăng nhập <span style={{ color: 'red' }}> *</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    margin="normal"
                    name="phone"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    placeholder="Nhập số điện thoại"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Tên người dùng<span style={{ color: 'red' }}> *</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.full_name && errors.full_name)}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.full_name && errors.full_name}
                    margin="normal"
                    name="full_name"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.full_name}
                    placeholder="Nhập tên người dùng"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Tỉnh thành <span className="text-danger">*</span>
                  </InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    error={Boolean(touched.citizen_id && errors.citizen_id)}
                    helperText={touched.citizen_id && errors.citizen_id}
                    margin="normal"
                    name="citizen_id"
                    onBlur={handleBlur}
                    onChange={e =>
                      handleChoose(e, 'province_type', setFieldValue)
                    }
                    SelectProps={{
                      native: true
                    }}
                    value={values.citizen_id}
                    variant="outlined"
                  >
                    <option>Chọn tỉnh thành</option>
                    {listProvinces &&
                      listProvinces.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Địa chỉ<span style={{ color: 'red' }}></span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.address && errors.address)}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.address && errors.address}
                    margin="normal"
                    name="address"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    placeholder="Nhập địa chỉ"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Email <span style={{ color: 'red' }}></span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    margin="normal"
                    className="input-no-fieldset"
                    name="email"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Nhập email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>{' '}
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Vai trò người dùng <span className="text-danger">*</span>
                  </InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && errors.role}
                    margin="normal"
                    name="role"
                    onBlur={handleBlur}
                    onChange={e => handleChoose(e, 'role_type', setFieldValue)}
                    SelectProps={{
                      native: true
                    }}
                    value={values.role}
                    variant="outlined"
                  >
                    <option>Chọn vai trò người dùng</option>
                    {roleUsers &&
                      roleUsers.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Mật khẩu <span style={{ color: 'red' }}> *</span>{' '}
                  </InputLabel>

                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    margin="normal"
                    className="input-no-fieldset"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    placeholder="Nhập mật khẩu"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(prev => !prev)}
                          >
                            {showPassword ? (
                              <VisibilityOff style={{ color: '#D9042F' }} />
                            ) : (
                              <Visibility style={{ color: '#D9042F' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Xác nhận lại mật khẩu{' '}
                    <span style={{ color: 'red' }}> *</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.confirm_password && errors.confirm_password)}
                    fullWidth
                    helperText={touched.confirm_password && errors.confirm_password}
                    margin="normal"
                     name="confirm_password"
                    className="input-no-fieldset"
                    type={showConfirmPassword ? 'text' : 'password'}
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirm_password}
                    placeholder="Xác nhận lại mật khẩu"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowConfirmPassword(prev => !prev)
                            }
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff style={{ color: '#D9042F' }} />
                            ) : (
                              <Visibility style={{ color: '#D9042F' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>

              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      onClick={() => onClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Thêm mới
                    </Button>
                    {statusCreate === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export default React.memo(CreateUser);
