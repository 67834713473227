import {
  Box,
  Divider,
  List,
  ListItem,
  Typography,
  Tooltip
} from '@material-ui/core';
import 'leaflet-rotatedmarker';
import moment from 'moment';
import React from 'react';
import { INFOR_CAR, INFOR_MOTO } from 'src/app/constant/config';

const LocationDisplay = ({ address }) => {
  const maxLength = 30;
  const truncatedAddress =
    address && address.length > maxLength ? `${address.substring(0, maxLength)}...` : (address ? address : "--");

  return (
    <Tooltip title={address} arrow>
      <div style={{ fontSize: '12px', float: 'right', textAlign: 'right' }}>
        {truncatedAddress}
      </div>
    </Tooltip>
  );
};

const ContentCarInfo = ({ positionsInfoBox }) => {
  const vehicleInfoBox =
    positionsInfoBox.device_type === 'VGPS_MOTO4G' ? INFOR_MOTO : INFOR_CAR;

  const getRemainingDays = (startDate, endDate) => {
    const remaining_days = `${Math.abs(moment(startDate).diff(moment(endDate), "days"))} Ngày`;
    return remaining_days || '--';
  }

  const renderValueInWindowBox = infor => {
    switch (infor.dataKey) {
      case 'created_at':
        return moment(positionsInfoBox[infor.dataKey] * 1000)
          .utc()
          .format('HH:mm:ss DD/MM/YYYY');
      case 'register_package_day':
        return moment(positionsInfoBox[infor.dataKey])
          .utc()
          .format('HH:mm:ss DD/MM/YYYY');
      case 'speed_gps':
        return `${Math.floor(positionsInfoBox[infor.dataKey])} Km/h`;
      case 'max_speed':
        return `${Math.floor(positionsInfoBox[infor.dataKey])} Km/h`;
      case 'daily_distance':
        return `${Math.floor(positionsInfoBox[infor.dataKey])} Km`;
      case 'air_condition_status':
        return positionsInfoBox[infor.dataKey] ? 'Bật' : 'Tắt';
      case 'memory_card':
        return 'Bình thường';
      case 'is_stopping':
        return positionsInfoBox[infor.dataKey] ? 'Đang đỗ' : '--';
      case 'remaining_days':
        return getRemainingDays(positionsInfoBox.active_at, positionsInfoBox.date_debt);
      case 'location':
        return (
          <LocationDisplay address={`${positionsInfoBox[infor.dataKey]}`} />
        );

      default:
        return positionsInfoBox[infor.dataKey] ?? '--';
    }
  };

  return (
    <Box
      style={{
        width: '100%', // Chiếm toàn bộ chiều rộng của popup
        height: 'auto', // Chiều cao tự động theo nội dung
      }}
    >
      <Typography
        color="primary"
        style={{
          color: '#D9042F',
          fontSize: '20px',
          fontWeight: 600,
          paddingTop: '5px',
          margin: 0
        }}
      >
        Thông tin xe
      </Typography>
      <List
        component="nav"
        style={{
          padding: '0'
        }}
      >
        {vehicleInfoBox.map((infor, index) => (
          <>
            <ListItem
              disableGutters
              style={{
                padding: '4px 0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div style={{ float: 'left' }}>
                <img
                  src={`/static/iconSvg/${infor.icon}.svg`}
                  style={{
                    paddingRight: '5px',
                    marginLeft: '0 !important'
                  }}
                />
                <b> {infor.label} </b>
              </div>

              <div
                style={{
                  float: 'right',
                  textAlign: 'right'
                }}
              >
                {renderValueInWindowBox(infor)}
              </div>
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Box>
  );
};

export default React.memo(ContentCarInfo);
