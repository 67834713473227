import { makeStyles, Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ChartStatistic from 'src/app/components/ChartStatistic/ChartStatistic';
import ToolBarStatistic from 'src/app/components/ToolBarStatistic/ToolBarStatistic';
import StatisticDeviceActiveTable from './StatisticDeviceActiveTable';
import { getStatisticDeviceActive, postExportStatisticActive } from 'src/features/statisticSlice';
import { roles } from 'src/app/constant/roles';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { useQuery } from 'src/app/hooks/useQuery';
import { _convertObjectToQuery } from 'src/app/utils/apiService';

const StatisticDeviceActive = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const query = useQuery();
  const [params, setParams] = useState({
    page: 1,
    page_size: PAGE_SIZE_LIST
  });

  const listDeviceActive = useSelector(
    state => state.statisticSlice.listDeviceActive
  );
  const monthlyStatisticsActive = useSelector(
    state => state.statisticSlice.monthlyStatisticsActive
  );
  const yearlyStatisticsActive = useSelector(
    state => state.statisticSlice.yearlyStatisticsActive
  );

  const totalActive = useSelector(state => state.statisticSlice.totalActive);

  const page = query.get('page') || 1;
  const agencyId = query.get('agency_id') || undefined;
  const deviceTypeId = query.get('device_type_id') || undefined;
  const timeToChart = query.get('time_to_chart') || undefined;
  const timeToSearch = query.get('time_to_search') || undefined;
  
  const dataStatistics = timeToChart ? monthlyStatisticsActive : yearlyStatisticsActive;

  useEffect(() => {
    const queryValue = {
      ...params,
      page: page ? page : undefined,
      agency_id: agencyId ? agencyId : undefined,
      device_type_id: deviceTypeId ? deviceTypeId : undefined,
      time_to_chart: timeToChart ? timeToChart : undefined,
      time_to_search: timeToSearch ? timeToSearch : undefined
    };
    setParams(queryValue);
    dispatch(getStatisticDeviceActive(queryValue));
  }, [query]);

  const getListDeviceActiveWithParams = data => {
    console.log('data', data);
    navigate('/app/manage/statistics/active?' + _convertObjectToQuery(data));
    dispatch(getStatisticDeviceActive(data));
  };

  const exportStatistic = data => {
    dispatch(postExportStatisticActive(data));
  };

  return (
    <>
      <div className={classes.root}>
        <div style={{ width: '98%', margin: '0 auto' }}>
          <Box
            style={{
              backgroundColor: '#ffffff',
              borderRadius: '4px',
              padding: '0px 10px 5px'
            }}
          >
            <ChartStatistic
              dataStatistics={dataStatistics}
              chartTitle={'Thống kê kích hoạt'}
              labelChart={'Thiết bị'}
              searchRef={getListDeviceActiveWithParams}
            />
          </Box>
          <Box marginTop={'20px'}>
            <ToolBarStatistic
              devicesCount={`${totalActive} thiết bị`}
              isTransfer={false}
              exportStatistic = {exportStatistic}
            />
          </Box>
          <Box marginTop={'10px'}>
            <StatisticDeviceActiveTable
              listDeviceActive={listDeviceActive}
              totalActive={totalActive}
              getListDeviceActiveRef={getListDeviceActiveWithParams}
              params={params}
              setParams={setParams}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    // height: '100%',
    width: '100%',
    marginBottom: 30
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

export default StatisticDeviceActive;
