
export const getIconStatus = (status, type) => {
    const defaultIcon = `/static/iconSvg/cars/car_lost_gsm.svg`
    const iconMapping = {
        'VGPS_CAR4G': {
            lost_gps: '/static/iconSvg/cars/car_lost_gps.svg',
            lost_gms: '/static/iconSvg/cars/car_lost_gsm.svg',
            stopped: '/static/iconSvg/cars/car_stopped.svg',
            moving: '/static/iconSvg/cars/car_moving.svg',
            over_speed: '/static/iconSvg/cars/car_out_speed.svg',
        },
        'VGPS_CAM4G': {
            lost_gps: '/static/iconSvg/cars/car_lost_gps.svg',
            lost_gms: '/static/iconSvg/cars/car_lost_gsm.svg',
            stopped: '/static/iconSvg/cars/car_stopped.svg',
            moving: '/static/iconSvg/cars/car_moving.svg',
            over_speed: '/static/iconSvg/cars/car_out_speed.svg',
        },
        'VGPS_MOTO4G': {
            lost_gps: '/static/iconSvg/motors/motor_lost_gps.svg',
            lost_gms: '/static/iconSvg/motors/motor_lost_gsm.svg',
            stopped: '/static/iconSvg/motors/motor_stopped.svg',
            moving: '/static/iconSvg/motors/motor_moving.svg',
            over_speed: '/static/iconSvg/motors/motor_out_speed.svg',
        },
        'VGPS_HUM4G': {
            lost_gps: '/static/iconSvg/cars/car_lost_gps.svg',
            lost_gms: '/static/iconSvg/cars/car_lost_gsm.svg',
            stopped: '/static/iconSvg/cars/car_stopped.svg',
            moving: '/static/iconSvg/cars/car_moving.svg',
            over_speed: '/static/iconSvg/cars/car_out_speed.svg',
        }
        
    };
    return iconMapping[type]?.[status] || defaultIcon;
};

export const vehicleStates = [
    {
      list: 'lost_gsm_vehicles',
      id: 1
    },
    {
      list: 'over_speed_vehicles',
      id: 2
    },
    {
      list: 'stop_vehicles',
      id: 3
    },
    {
      list: 'running_vehicles',
      id: 4
    },
    {
      list: 'lost_gps_vehicles',
      id: 5
    }
  ];
