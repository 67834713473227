import React, { useEffect, useRef, useState, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './style.css';
import moment from 'moment';
import { FixedSizeList } from 'react-window';
import _ from 'lodash';


const StyledTableCell = withStyles(theme => ({
  root: {
    color: 'inherit', 
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    '&:not(.active):hover': { 
      backgroundColor: '#ffe6e6 !important', 
    },
  },
}))(TableRow);

const Row = React.memo(
  ({ index, style, data }) => {
    const {
      vehiclesData,
      getVehicle,
      sliderAction,
      showColumns,
      activeIndex
    } = data;
    const row = vehiclesData[index];
    const isActive = index === activeIndex;

    const handleClick = () => {
      const nextVehicle = vehiclesData[index + 1] || null;
      getVehicle(row, nextVehicle);
      sliderAction(index);
    };

    const stopDisplay = useMemo(() => {
      if (row.is_stop && row.duration > 300) {
        const duration = row.duration || 0;
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;

        const totalColumns =
          1 +
          (showColumns.speedCol ? 1 : 0) +
          (showColumns.gpsSpeedCol ? 1 : 0) +
          (showColumns.engineStatusCol ? 1 : 0);

        return (
          <StyledTableCell
            colSpan={totalColumns}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'flex-start',
                color: isActive ? '#FFFFFF' : '#19a539', 
                padding: '0 10px'
              }}
            >
              <span style={{ marginRight: '5px' }}>Dừng đỗ:</span>
              {`${hours}:${minutes
                .toString()
                .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
            </div>
          </StyledTableCell>
        );
      }
      return null;
    },[row.is_stop, row.duration, isActive, showColumns]);

    return (
      <StyledTableRow
        id={`row-${index}`}
        style={{
          ...style,
          backgroundColor: isActive ? '#f27b7b' : 'unset',
          color: isActive ? '#FFFFFF' : 'inherit', 
          display: 'flex',
          justifyContent: 'space-between'
        }}
        onClick={handleClick}
        className={isActive ? 'active' : ''}
      >
        {stopDisplay || (
          <>
            <StyledTableCell
              align="center"
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {moment.unix(row?.created_at).format('DD/MM HH:mm') || '--'}
            </StyledTableCell>
            {showColumns.speedCol && (
              <StyledTableCell
                align="center"
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {row?.speed || 0}
              </StyledTableCell>
            )}
            {showColumns.gpsSpeedCol && (
              <StyledTableCell
                align="center"
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {Number.isInteger(row?.speedGps) && row?.speedGps === 0
                  ? row.speedGps
                  : Math.floor(row?.speedGps) || '--'}
              </StyledTableCell>
            )}
            {showColumns.engineStatusCol && (
              <StyledTableCell
                align="center"
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {row?.engineStatus || '--'}
              </StyledTableCell>
            )}
          </>
        )}
      </StyledTableRow>
    );
  },
  (prevProps, nextProps) =>
    prevProps.index === nextProps.index &&
    prevProps.data.activeIndex === nextProps.data.activeIndex
);

const VehicleRoadMapTable = ({
  vehiclesData,
  getVehicle,
  showColumns,
  sliderAction,
  currentSliderTime
}) => {
  const listRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [listHeight, setListHeight] = useState(382);
  const { gpsSpeedCol, speedCol, engineStatusCol } = showColumns;

  const updateListHeight = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 1600) {
      setListHeight(194);
    } else {
      setListHeight(382);
    }
  };

  useEffect(() => {
    updateListHeight();
    window.addEventListener('resize', updateListHeight);

    return () => {
      window.removeEventListener('resize', updateListHeight);
    };
  }, [updateListHeight]);

  const findClosestRowIndex = time => {
    if (!vehiclesData || vehiclesData.length === 0 || !time) return -1;
    let closestIndex = 0;
    let smallestDiff = Infinity;

    vehiclesData.forEach((vehicle, index) => {
      const vehicleTime = Number(vehicle.created_at);
      const diff = Math.abs(vehicleTime - Number(time));
      if (diff < smallestDiff) {
        smallestDiff = diff;
        closestIndex = index;
      }
    });
    return closestIndex;
  };

  useEffect(() => {
    if (!vehiclesData || vehiclesData.length === 0) return;

    const targetIndex = currentSliderTime
      ? findClosestRowIndex(currentSliderTime)
      : 0;

    if (targetIndex === -1 || !listRef.current) return;

    setActiveIndex(targetIndex);
    listRef.current.scrollToItem(targetIndex, 'start');
  }, [currentSliderTime, vehiclesData]);

  const itemData = {
    vehiclesData,
    getVehicle,
    sliderAction,
    showColumns,
    activeIndex
  };

  return (
    <div style={{ minHeight: '20px' }}>
      <TableContainer component={Paper} className="vehicle_table_road_map">
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            style={{
              background: '#C62222 !important',
              color: 'white !important'
            }}
          >
            <TableRow style={{ display: 'flex', width: '100%' }}>
              <TableCell align="center" style={{ flex: 1 }}>
                Thời gian
              </TableCell>
              {speedCol && (
                <TableCell align="center" style={{ flex: 1 }}>
                  Vận tốc cơ
                </TableCell>
              )}
              {gpsSpeedCol && (
                <TableCell align="center" style={{ flex: 1 }}>
                  Vận tốc GPS
                </TableCell>
              )}
              {engineStatusCol && (
                <TableCell align="center" style={{ flex: 1 }}>
                  Động cơ
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <FixedSizeList
              ref={listRef}
              height={listHeight}
              width={348}
              itemCount={vehiclesData.length}
              itemSize={30}
              itemData={itemData}
            >
              {Row}
            </FixedSizeList>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default React.memo(VehicleRoadMapTable);