import {
  Box,
  Card,
  List,
  ListItem,
  makeStyles,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  withStyles,
  Fade
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import DialogConfirm from 'src/app/components/DialogConfirm';
import { MESSAGE } from 'src/app/constant/message';
import { closeDialogConfirm, showDialogConfirm } from 'src/features/uiSlice';

import { useDispatch } from 'react-redux';
import LoadingComponent from 'src/app/components/Loading';
import { deleteMultiServicePackage } from 'src/features/servicePackageSlice';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import EditServicePackage from './EditService';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    whiteSpace: 'nowrap'
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap'
  },
  root: {
    padding: '4px'
  }
}))(TableCell);

const TableServicePackages = ({
  className,
  listServicePackages,
  getListServicePackagesWithParams,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.servicePackageSlice.isLoading);
  const total = useSelector(state => state.servicePackageSlice.total);
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState();
  const [itemCurrent, setItemCurrent] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [limit, setLimit] = useState(PAGE_SIZE_LIST);
  const [page, setPage] = useState(1);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [params, setParams] = useState({
    page: page,
    page_size: limit
  });

  const handleClickMenu = (event, service) => {
    setAnchorEl(event.currentTarget);
    setItemCurrent({
      id: service.id,
      name: service.name,
      version: service.version,
      cost: Number(service.cost),
      day_limit: Number(service.day_limit),
      description: service.description,
      device_type_name: service.deviceType.name,
    });
    setSelectedItem(service.id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleEditServicePackage = () => {
    setOpenEditModal(true);
    handleCloseMenu();
  };

  const handleDeleteServicePackage = () => {
    dispatch(showDialogConfirm());
    handleCloseMenu();
  };

  const handleDeleteMultiServicePackage = () => {
    dispatch(showDialogConfirm());
    handleCloseMenu();
  };

  const confirmDelete = () => {
    const listId = rowsSelected.length > 0 ? rowsSelected : [selectedItem];
    setRowsSelected([]);
     dispatch(closeDialogConfirm());
    dispatch(deleteMultiServicePackage({ list_service_package_id: listId }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (!getListServicePackagesWithParams) return;
    const newparams = Object.assign({}, params, { page: newPage });
    setParams(newparams);
    getListServicePackagesWithParams(newparams);
  };

  const closeModal = () => {
    setOpenEditModal(false);
  };

  const checked = id => {
    return rowsSelected.indexOf(id) !== -1;
  };
  const select = driver => {
    if (rowsSelected.indexOf(driver) === -1) {
      const newSelected = [driver, ...rowsSelected];
      setRowsSelected(newSelected);
    } else {
      const newSelected = rowsSelected.filter(i => i !== driver);
      setRowsSelected(newSelected);
    }
  };

  const checkAll = () => {
    if (rowsSelected.length > 0) {
      setRowsSelected([]);
    }

    if (
      listServicePackages.length > 0 &&
      rowsSelected.length !== listServicePackages.length
    ) {
      const all = listServicePackages.map(item => {
        return item.id;
      });
      setRowsSelected(all);
    }
  };

  const formatCurrency = (amount, locale = 'vi-VN', currency = 'VND') => {
    return amount.toLocaleString(locale, {
      style: 'currency',
      currency: currency
    });
  };

  return (
    <>
    {openEditModal && (
      <EditServicePackage open={openEditModal} onClose={closeModal} servicePackage={itemCurrent} />
    )}
      <DialogConfirm
        title={MESSAGE.CONFIRM_DELETE_SERVICEPACKAGE}
        textOk={MESSAGE.BTN_YES}
        textCancel={MESSAGE.BTN_CANCEL}
        content={MESSAGE.CONTENT_DELETE_SERVICEPACKAGE}
        callbackOk={() => confirmDelete()}
        callbackCancel={() => dispatch(closeDialogConfirm())}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div>
            {rowsSelected.length > 0 && (
              <Fade in={rowsSelected.length > 0}>
                <Box
                  minWidth={1050}
                  style={{ fontSize: '14px', padding: '6px 4px' }}
                >
                  <span>
                    <Checkbox
                      checked={rowsSelected.length > 0}
                      onChange={() => checkAll()}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </span>

                  <span
                    onClick={handleDeleteMultiServicePackage}
                    className="mx-2 btnDevice"
                    color="primary"
                    variant="outlined"
                    size="small"
                  >
                    <img
                      src={`/static/iconSvg/delete-icon.svg`}
                      alt="delete"
                      style={{ paddingRight: '5px', paddingBottom: '4px' }}
                    />
                    <span className="pt-1"> Xóa </span>
                  </span>
                </Box>
              </Fade>
            )}
            <PerfectScrollbar>
              {listServicePackages && listServicePackages.length ? (
                <Box minWidth={1050}>
                  <Table>
                    {rowsSelected.length === 0 &&
                      listServicePackages?.length > 0 && (
                        <TableHead>
                          <TableRow>
                            <StyledTableCell width={50}>
                              <Checkbox
                                checked={rowsSelected.length > 0}
                                onChange={() => checkAll()}
                                color="primary"
                                inputProps={{
                                  'aria-label': 'secondary checkbox'
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell>Tên gói dịch vụ </StyledTableCell>
                            <StyledTableCell>Loại thiết bị </StyledTableCell>
                            <StyledTableCell>Giá gói dịch vụ</StyledTableCell>
                            <StyledTableCell>Phiên bản</StyledTableCell>
                            <StyledTableCell>Hạn gói dịch vụ</StyledTableCell>
                            <StyledTableCell>Mô tả</StyledTableCell>

                            <StyledTableCell></StyledTableCell>
                          </TableRow>
                        </TableHead>
                      )}
                    <TableBody>
                      {listServicePackages.map((service, index) => (
                        <TableRow hover key={service?.id}>
                          <StyledTableCell
                            style={{ maxWidth: '20px' }}
                            onClick={() => select(service?.id)}
                          >
                            <Checkbox
                              checked={checked(service?.id)}
                              onChange={() => select(service?.id)}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {service?.name || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {service?.deviceType.name || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {formatCurrency(service?.cost) || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {service?.version || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {service?.day_limit || '-'} Ngày
                          </StyledTableCell>
                          <StyledTableCell>
                            {service?.description || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            <span style={{ color: '#AEB1C5' }}>
                              <MoreVertIcon
                                aria-describedby={`menu-device-${service.id}`}
                                className={`cursor-pointer hover-red`}
                                onClick={e => handleClickMenu(e, service)}
                              />
                            </span>

                            <Popover
                              id={`menu-device-${service.id}`}
                              className="popover-device"
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <Typography>
                                <List>
                                  <ListItem
                                    onClick={handleEditServicePackage}
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Chỉnh sửa
                                  </ListItem>
                                  <ListItem
                                    onClick={handleDeleteServicePackage}
                                    className="cursor-pointer list-menu-device"
                                  >
                                    <span className="text-danger"> Xóa </span>
                                  </ListItem>
                                </List>
                              </Typography>
                            </Popover>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <div className="text-center pb-5">
                  <img
                    src="/static/empty.png"
                    className="justify-content-center"
                  />
                  <h4> Danh sách trống </h4>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Card>
      {listServicePackages && listServicePackages.length > 0 && (
        <div className="mt-3 mr-4 float-right">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15px'
            }}
          >
            <Typography style={{ fontSize: '14px' }}>Tổng: {total}</Typography>
            <Pagination
              count={Math.ceil(total / limit)}
              color='primary'
              size="small"
              onChange={handlePageChange}
              page={Number(params.page)}
              showFirstButton
              showLastButton
            />
          </Box>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  groupAction: {
    display: 'flex',
    alignItems: 'center',
    '& .action': {
      cursor: 'pointer',
      padding: '0 5px',
      '&:hover': {
        color: '#3f51b5'
      }
    }
  },
  minWithColumn: {
    minWidth: '150px'
  }
}));

export default TableServicePackages;
