import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'date-fns';
import Slide from '@material-ui/core/Slide';
import { Box, Card } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ToolBar from 'src/app/components/ToolBar';
import VehicleRoadMapTable from 'src/app/components/tables/VehicleRoadMapTable';
import { STATUS_API } from 'src/app/constant/config';
import {
  Slider,
  Typography,
  Popover,
  List,
  ListItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import axios from 'axios';
import { carSpeed } from './constance';
const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
    width: '304px',
    height: '408px'
  },
  listInformation: {
    width: '100',
    maxWidth: 375,
    borderRadius: 8,
    maxHeight: 469,
    backgroundColor: theme.palette.background.paper
  },
  popoverHeader: {
    textAlign: 'left'
  },
  noDataMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    height: '100px',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: '1px dashed #ccc',
    borderRadius: '4px'
  },
  valueLabel: {
    fontSize: '12px',
    padding: '2px 6px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: '4px',
    top: -22
  },
  totalDistance: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#0C1132'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
}));

const MenuBox = ({
  getVehicle,
  handleRoadTrackingUpdate,
  startFollow,
  resetTracking,
  pauseTracking,
  stoppedPoint,
  follow,
  mode,
  setMode,
  listVehicle,
  setShowMenu,
  showMenu,
  snapped,
  isPaused,
  setSpeed,
  position,
  setPosition,
  sliderAction,
  rePlay
}) => {
  const classes = useStyles();
  const statusGetPositions = useSelector(
    state => state.deviceSlice.statusGetPositions
  );
  const waiting =
    statusGetPositions !== STATUS_API.SUCCESS && statusGetPositions !== null;
  const [showButton, setShowButton] = useState(false);
  const [speedIcon, setSpeedIcon] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = useState(0);
  const [isRouteEnded, setIsRouteEnded] = useState(false);

  const [showColumns, setShowColumns] = useState({
    gpsSpeedCol: true,
    speedCol: true,
    doorStatusCol: true,
    airConditionStatusCol: true,
    engineStatusCol: true,
    latLngCol: true
  });

  const {
    gpsSpeedCol,
    speedCol,
    doorStatusCol,
    airConditionStatusCol,
    engineStatusCol,
    latLngCol
  } = showColumns;

  const handleShowColumns = event => {
    setShowColumns({
      ...showColumns,
      [event.target.name]: event.target.checked
    });
  };

  const totalDistance =
    snapped && snapped.length > 0
      ? snapped[snapped.length - 1].distance || 0
      : 0;

  const GetVehiclePosition = data => {
    setShowButton(true);
    handleRoadTrackingUpdate(data);
    resetTracking();
    setValue(0);
    setPosition(snapped[0] || {});
    setIsRouteEnded(false);
  };

  const handleClickSetting = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeSpeed = () => {
    const arrSpeed = [1, 2, 4, 8];
    const index = arrSpeed.findIndex(s => s == speedIcon);
    if (index < arrSpeed.length - 1) {
      setSpeedIcon(arrSpeed[index + 1]);
      const speedItem = carSpeed.find(cs => cs.speed === arrSpeed[index + 1]);
      const speed = speedItem?.speed;
      setSpeed(speed);
    } else {
      const speedItem = carSpeed.find(cs => cs.speed === arrSpeed[0]);
      const speed = speedItem?.speed;
      setSpeedIcon(arrSpeed[0]);
      setSpeed(speed);
    }
  };

  const handleReplay = () => {
    setValue(0);
    setPosition(snapped[0] || {});
    sliderAction(0);
    setIsRouteEnded(false);
    startFollow();
    rePlay();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const iconSetting = open ? 'iconSettingActive' : 'iconSetting';

  const PrettoSlider = withStyles(theme => ({
    root: {
      height: 4,
      width: 250,
      marginLeft: '1.5em',
      marginRight: '1.5em'
    },
    thumb: {
      height: 16,
      width: 16,
      backgroundColor: '#C62222',
      borderRadius: '50%',
      border: '1px solid white',
      '&:focus, &$active': {
        boxShadow: '0 0 0 8px rgba(198, 34, 34, 0.16)'
      },
      '&:hover': {
        backgroundColor: '#E57373',
        transform: 'scale(1.2)',
        boxShadow: '0 0 0 8px rgba(198, 34, 34, 0.16)'
      },
      marginTop: -6,
      marginLeft: -8
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 8px)',
      top: -22,
      fontSize: '12px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      padding: 0,
      borderRadius: 0,
      whiteSpace: 'nowrap',
      '& *': {
        background: 'transparent',
        color: theme.palette.primary.main
      }
      
    },
    track: {
      height: 4,
      borderRadius: 4,
      backgroundColor: '#C62222'
    },
    rail: {
      height: 4,
      borderRadius: 4,
      backgroundColor: '#E0E0E0'
    }
  }))(Slider);

  const handleExportExcel = async stoppedPoint => {
    const access_token = localStorage.getItem('access-token');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/excel`,
        { vehicles: stoppedPoint },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`
          },
          responseType: 'blob'
        }
      );

      const blob = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'vehicles_route_data.xlsx');
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Lỗi khi tải file:', error);
    }
  };

  const findCurrentIndex = () => {
    if (!position || !snapped || snapped.length === 0) return 0;
    const index = snapped.findIndex(
      item => item?.created_at === position?.created_at
    );
    return index >= 0 ? index : 0;
  };

  useEffect(() => {
    const newIndex = findCurrentIndex();
    setValue(newIndex);
    if (newIndex === snapped.length - 1 && follow && !isPaused) {
      pauseTracking();
      setIsRouteEnded(true);
    }
  }, [position, snapped, follow, isPaused, pauseTracking]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (snapped[newValue]) {
      setPosition(snapped[newValue]);
    }
    sliderAction(newValue);
    setIsRouteEnded(false);
  };

  const formatDistanceLabel = value => {
    if (value === 0) return '0 km';
    if (!snapped || !snapped[value]) return '0 km';

    let distance = snapped[value].distance;
    if (!distance) {
      let prevIndex = value - 1;
      while (prevIndex >= 0) {
        const prevDistance = snapped[prevIndex].distance;
        if(prevDistance) {
          distance = prevDistance;
          break;
        }
        prevIndex--;
      }
    }

    return distance ? `${distance.toFixed(2)} km` : '0 km';
  };

  return (
    <Slide
      direction="right"
      in={showMenu}
      style={{
        width: '384px',
        borderRadius: '6px',
        boxShadow: '5px 5px 15px 0px #9e9e9ede',
        zIndex: 450
      }}
    >
      <Card className="menu_map_route">
        <ToolBar
          resetTracking={resetTracking}
          vehiclesD={listVehicle}
          setShowMenu={setShowMenu}
          GetVehiclePosition={GetVehiclePosition}
        />
        <Box style={{ marginTop: '5px' }}>
          {showButton && statusGetPositions === STATUS_API.SUCCESS && (
            <div className={classes.headerContainer}>
              <Typography className={classes.totalDistance}>
                Tổng số Km: {totalDistance.toFixed(2)} km
              </Typography>
              <div className={classes.iconContainer}>
                <span
                  className="list_tracking_action"
                  onClick={() => handleExportExcel(stoppedPoint)}
                >
                  <img alt="excel_icon" src="/static/iconSvg/iconExcel.svg" />
                </span>
                <span style={{ cursor: 'pointer' }}>
                  <img
                    src={`/static/iconSvg/${iconSetting}.svg`}
                    alt="setting"
                    aria-describedby="settings-table"
                    onClick={handleClickSetting}
                  />
                </span>
                <Popover
                  id="settings-table"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  <Typography className={classes.typography}>
                    <div className={classes.popoverHeader}>
                      <b> Cài đặt hiển thị </b>
                      <span className="float-right">
                        <img
                          className="list_tracking_action"
                          onClick={handleClose}
                          alt="icon"
                          width="12px"
                          height="12px"
                          src="/static/iconSvg/close-btn.svg"
                        />
                      </span>
                    </div>
                    <List className={classes.listInformation}>
                      <ListItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={gpsSpeedCol}
                              onChange={handleShowColumns}
                              name="gpsSpeedCol"
                              color="primary"
                            />
                          }
                          label="Hiển thị vận tốc GPS"
                        />
                      </ListItem>
                      <ListItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={speedCol}
                              onChange={handleShowColumns}
                              name="speedCol"
                              color="primary"
                            />
                          }
                          label="Hiển thị vận tốc cơ"
                        />
                      </ListItem>
                      <ListItem>
                        <FormControlLabel
                          control={<Checkbox name="checkedA" color="primary" />}
                          label="Hiển thị Km"
                        />
                      </ListItem>
                      <ListItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={doorStatusCol}
                              onChange={handleShowColumns}
                              name="doorStatusCol"
                              color="primary"
                            />
                          }
                          label="Hiển thị trạng thái cửa"
                        />
                      </ListItem>
                      <ListItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={airConditionStatusCol}
                              onChange={handleShowColumns}
                              name="airConditionStatusCol"
                              color="primary"
                            />
                          }
                          label="Hiển thị trạng thái điều hòa"
                        />
                      </ListItem>
                      <ListItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={engineStatusCol}
                              onChange={handleShowColumns}
                              name="engineStatusCol"
                              color="primary"
                            />
                          }
                          label="Hiển thị trạng thái động cơ"
                        />
                      </ListItem>
                      <ListItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={latLngCol}
                              onChange={handleShowColumns}
                              name="latLngCol"
                              color="primary"
                            />
                          }
                          label="Hiển thị kinh độ, vĩ độ"
                        />
                      </ListItem>
                    </List>
                  </Typography>
                </Popover>
              </div>
            </div>
          )}
          {showButton && !waiting ? (
            <Box
              style={{
                marginTop: '25px',
                display: 'flex',
                verticalAlign: 'middle',
                alignItems: 'center'
              }}
            >
              {isRouteEnded ? (
                <span style={{ cursor: 'pointer' }} onClick={handleReplay}>
                  <img alt="replay" src="/static/iconSvg/iconReplay.svg" />
                </span>
              ) : follow ? (
                <span style={{ cursor: 'pointer' }} onClick={pauseTracking}>
                  {isPaused ? (
                    <img alt="icon" src="/static/iconSvg/iconPlay.svg" />
                  ) : (
                    <img alt="icon" src="/static/iconSvg/iconPause.svg" />
                  )}
                </span>
              ) : (
                <span style={{ cursor: 'pointer' }} onClick={startFollow}>
                  <img alt="icon" src="/static/iconSvg/iconPlay.svg" />
                </span>
              )}

              <PrettoSlider
                valueLabelDisplay="on"
                valueLabelFormat={formatDistanceLabel}
                aria-label="pretto slider"
                max={snapped.length - 1}
                defaultValue={0}
                min={0}
                value={value}
                onChange={handleChange}
              />

              <img
                alt="icon"
                src={`/static/iconSvg/icon${speedIcon}x.svg`}
                onClick={handleChangeSpeed}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          ) : (
            ''
          )}

          {showButton &&
            !waiting &&
            (snapped && snapped.length > 0 ? (
              <VehicleRoadMapTable
                vehiclesData={snapped}
                getVehicle={(rowTableCurrent, rowTableNext) => {
                  getVehicle(rowTableCurrent, rowTableNext);
                  setIsRouteEnded(false);
                }}
                mode={mode}
                showColumns={showColumns}
                sliderAction={sliderAction}
                currentSliderTime={position?.created_at}
              />
            ) : (
              <Typography className={classes.noDataMessage}>
                Không có dữ liệu
              </Typography>
            ))}
        </Box>
      </Card>
    </Slide>
  );
};

export default React.memo(MenuBox);
