import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  makeStyles,
  TextField
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS_API } from 'src/app/constant/config';

import { Formik } from 'formik';
import { vehicleType } from 'src/app/constant/vehicleType';
import { getListAgencies } from 'src/features/agencySlice';
import {
  activeDevice,
  getServicePackage,
  getUsernameByPhoneNumber,
  resetSearchUsernameByPhone
} from 'src/features/deviceSlice';
import * as Yup from 'yup';
import { roles } from 'src/app/constant/roles';
import CustomDialog from 'src/app/components/CustomDialog';


const ActiveDevice = ({ open, onClose, dataSelected }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusActive = useSelector(state => state.deviceSlice.statusActive);
  const username = useSelector(state => state.deviceSlice.username);

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;

  const [deviceTypeDetail, setDeviceTypeDetail] = useState({});
  const [selectedTonnage, setSelectedTonnage] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState('');

  const handleSubmit = data => {
    if (data && data.serial) {
      const dataActive = {
        phone: selectedPhone,
        full_name: username ? username : '',
        serial: data?.serial,
        version: data?.version,
        vehicle_license_plate:
          deviceTypeDetail?.id !== 1 ? data.vehicle_license_plate : null, // do tên người với biển số xe đang dùng chung 1 Textfield
        title_device_type: deviceTypeDetail?.name ? deviceTypeDetail.name : '',
        slots: data?.slots ? Number(data?.slots) : null,
        tonnage: data?.tonnage ? data?.tonnage : null,
        name_of_user_for_gps:
          deviceTypeDetail?.id === 1 ? data.vehicle_license_plate : null, // do tên người với biển số xe đang dùng chung 1 Textfield
      };
      dispatch(activeDevice(dataActive));
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const trim = x => {
    return x.replace(/^\(+|\)+$/gm, '');
  };

  const handleSelectVehicle = e => {
    const target = vehicleType.find(type => {
      return type.id === Number(e.target.value);
    });
    if (target) {
      setDeviceTypeDetail(target);
      setSelectedSlots(target?.slots);
      setSelectedTonnage(target?.tonnage);
    }
  };

  const handleChangePhoneNumber = (e, setFieldValue) => {
    let searchData = trim(e.target.value);
    setSelectedPhone(searchData);
    setFieldValue('phone', searchData);
    if (searchData && searchData.length === 10) {
      dispatch(getUsernameByPhoneNumber({ phoneNumber: searchData }));
    }
  };

  useEffect(() => {
    if(isAdmin) dispatch(getListAgencies());
    return () => {
      dispatch(resetSearchUsernameByPhone());
    };
  }, [dispatch]);

  return (
    <CustomDialog title="Kích hoạt thiết bị" open={open} onClose={handleClose} >
      <div>
        <Formik
          initialValues={{
          phone: '',
          full_name: '',
          serial: dataSelected?.serial || '',
          title_device_type: '',
          vehicle_license_plate: '',
          version: dataSelected?.hardware_version || '',
          name_of_user_for_gps: '',
          tonnage: '',
          slots: '',
          device_type: dataSelected?.deviceType?.name || '',
          service_package: dataSelected?.deviceServicePackages?.servicePackage?.name || ''
          }}
          validationSchema={Yup.object().shape({
            vehicle_license_plate: Yup.string()
              .max(100)
              .matches(/^[A-Z0-9]+$/, 'Chỉ nhập chữ in hoa và số, không chứa ký tự đặc biệt')
              .required('Biển số không được để trống'),
              phone: Yup.string()
                .matches(
                  /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
                  'Số điện thoại chưa đúng định dạng'
                )
               .required('Số điện thoại không được để trống'),
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Tài khoản người dùng <span className="text-danger">*</span>
                  </InputLabel>
                  <TextField
                    size="small"
                    className="input-no-fieldset"
                    fullWidth
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    margin="normal"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={e => handleChangePhoneNumber(e, setFieldValue)}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Tên người dùng <span className="text-danger"></span>
                  </InputLabel>
                  <TextField
                    size="small"
                    disabled={true}
                    className="input-no-fieldset"
                    fullWidth
                    margin="normal"
                    name="full_name"
                    onBlur={handleBlur}
                    value={username}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Serial thiết bị <span className="text-danger"></span>
                  </InputLabel>
                  <TextField
                    disabled={true}
                    size="small"
                    className="input-no-fieldset"
                    fullWidth
                    margin="normal"
                    name="serial"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.serial}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Loại thiết bị <span className="text-danger"></span>
                  </InputLabel>
                  <TextField
                    disabled={true}
                    size="small"
                    className="input-no-fieldset"
                    fullWidth
                    margin="normal"
                    name="serial"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.device_type}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Loại phương tiện <span className="text-danger">*</span>
                  </InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    margin="normal"
                    name="title_device_type"
                    onBlur={handleBlur}
                    onChange={e => handleSelectVehicle(e)}
                    SelectProps={{
                      native: true
                    }}
                    variant="outlined"
                    placeholder="Chọn loại phương tiện"
                  >
                    <option>Chọn loại phương tiện</option>
                    {vehicleType &&
                      vehicleType.map(type => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                  </TextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>
                    {deviceTypeDetail?.id !== 1 ? 'Biển số xe' : 'Tên người'}
                    <span className="text-danger"> *</span>
                  </InputLabel>
                  <TextField
                    size="small"
                    className="input-no-fieldset"
                    error={Boolean(
                      touched.vehicle_license_plate &&
                        errors.vehicle_license_plate
                    )}
                    fullWidth
                    helperText={
                      touched.vehicle_license_plate &&
                      errors.vehicle_license_plate
                    }
                    margin="normal"
                    name="vehicle_license_plate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.vehicle_license_plate}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Phiên bản <span className="text-danger"></span>
                  </InputLabel>
                  <TextField
                    disabled={true}
                    size="small"
                    className="input-no-fieldset"
                    fullWidth
                    margin="normal"
                    name="version"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.version}
                    variant="outlined"
                  />
                </Grid>
                {deviceTypeDetail.id === 6 && (
                  <Grid item md={6} xs={12}>
                    <InputLabel>
                      Tải trọng <span className="text-danger">*</span>
                    </InputLabel>
                    <TextField
                      className="input-no-fieldset"
                      size="small"
                      fullWidth
                      select
                      margin="normal"
                      name="tonnage"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      SelectProps={{
                        native: true
                      }}
                      value={values.tonnage}
                      variant="outlined"
                    >
                      <option>Chọn loại tải trọng</option>
                      {selectedTonnage &&
                        selectedTonnage.map(type => (
                          <option key={type.id} value={type.value}>
                            {type.name}
                          </option>
                        ))}
                    </TextField>
                  </Grid>
                )}

                {deviceTypeDetail.id === 4 && (
                  <Grid item md={6} xs={12}>
                    <InputLabel>
                      Số chỗ <span className="text-danger">*</span>
                    </InputLabel>
                    <TextField
                      className="input-no-fieldset"
                      size="small"
                      fullWidth
                      select
                      margin="normal"
                      name="slots"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      SelectProps={{
                        native: true
                      }}
                      value={values.slots}
                      variant="outlined"
                    >
                      <option>Chọn số chỗ</option>
                      {selectedSlots &&
                        selectedSlots.map(type => (
                          <option key={type.id} value={type.value}>
                            {type.name}
                          </option>
                        ))}
                    </TextField>
                  </Grid>
                )}

                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Gói cước <span className="text-danger"></span>
                  </InputLabel>
                  <TextField
                    disabled={true}
                    size="small"
                    className="input-no-fieldset"
                    fullWidth
                    margin="normal"
                    name="service_package"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.service_package}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      onClick={() => onClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Cập nhật
                    </Button>
                    {statusActive === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
}));

export default React.memo(ActiveDevice);
