import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { green } from '@material-ui/core/colors';
import { Formik } from 'formik';
import CustomDialog from 'src/app/components/CustomDialog';
import { STATUS_API } from 'src/app/constant/config';
import { updateDrivers } from 'src/features/driverSlice';
import * as Yup from 'yup';

function EditDriver({ open, onClose, driverSelected }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusCreate = useSelector(state => state.driverSlice.statusCreate);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);

  const handleSubmit = async dataUpdate => {
    if (!driverSelected || !driverSelected?.id) return;
    const newDriver = {
      id: driverSelected.id,
      name: dataUpdate.name,
      phone: dataUpdate.phone,
      license_type: dataUpdate.license_type,
      license_number: dataUpdate.license_number,
      address: dataUpdate.address,
      license_issue_date: String(dataUpdate.license_issue_date),
      license_expire_date: String(dataUpdate.license_expire_date)
    };
    dispatch(
      updateDrivers({
        id: dataLogin.agency.id,
        data: newDriver
      })
    );
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog title="Chỉnh sửa lái xe" open={open} onClose={handleClose} >
      <div>
        <Formik
          initialValues={driverSelected}
          validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .max(100)
                      .required('Tên không được để trống'),
                    email: Yup.string()
                      .email('Email chưa đúng định dạng')            
                      .required('Email không được để trống'),
                    phone: Yup.string()
                      .matches(
                        /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
                        'Số điện thoại chưa đúng định dạng'
                      )
                      .required('Số điện thoại không được để trống'),
                    address: Yup.string()
                        .max(100)
                        .required('Địa chỉ không được để trống'),
                    license_number: Yup.string()
                        .max(100)
                        .required('Số GPLX không được để trống'),
                    license_type: Yup.string()
                        .max(10)
                        .required('Loại bằng lái không được để trống'),
                    license_expire_date: Yup.date()
                      .required('Ngày hết hạn không được để trống'),
                    license_issue_date: Yup.date()
                      .required('Ngày cấp bằng không được để trống'),
                    date_of_birth: Yup.date()              
                      .required('Ngày sinh không được để trống'),
                    cmnd: Yup.string()
                      .max(12)
                      .required('Số chứng minh/ căn cước công dân không để trống'),
          
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Tên lái xe <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.name && errors.name}
                    margin="normal"
                    name="name"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>
                    Số điện thoại <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    className="input-no-fieldset"
                    helperText={touched.phone && errors.phone}
                    margin="normal"
                    size="small"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Email <span style={{ color: 'red' }}></span></InputLabel>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    margin="normal"
                    className="input-no-fieldset"
                    name="email"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Địa chỉ <span style={{ color: 'red' }}></span></InputLabel>
                  <TextField
                    error={Boolean(touched.address && errors.address)}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.address && errors.address}
                    margin="normal"
                    name="address"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Số giấy phép lái xe <span style={{ color: 'red' }}></span></InputLabel>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="license_number"
                    value={values.license_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.license_number && errors.license_number
                    )}
                    helperText={touched.license_number && errors.license_number}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Loại bằng <span style={{ color: 'red' }}></span></InputLabel>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="license_type"
                    value={values.license_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.license_type && errors.license_type)}
                    helperText={touched.license_type && errors.license_type}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Ngày cấp <span style={{ color: 'red' }}></span> </InputLabel>
                  <TextField
                    fullWidth
                    margin="normal"
                    type="date"
                    name="license_issue_date"
                    value={
                      values.license_issue_date
                        ? values.license_issue_date.split('T')[0]
                        : ''
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.license_issue_date && errors.license_issue_date
                    )}
                    helperText={
                      touched.license_issue_date && errors.license_issue_date
                    }
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Ngày hết hạn <span style={{ color: 'red' }}></span> </InputLabel>
                  <TextField
                    fullWidth
                    margin="normal"
                    type="date"
                    name="license_expire_date"
                    value={
                      values.license_expire_date
                        ? values.license_expire_date.split('T')[0]
                        : ''
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.license_expire_date && errors.license_expire_date
                    )}
                    helperText={
                      touched.license_expire_date && errors.license_expire_date
                    }
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      onClick={() => onClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Lưu lại
                    </Button>
                    {statusCreate === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
}
const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
}));

export default React.memo(EditDriver);
