import {
  Box,
  Checkbox,
  Fade,
  List,
  ListItem,
  makeStyles,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import ModalConfirm from 'src/app/components/ModalConfirm';
import { DEVICE_STATUS, PAGE_SIZE_LIST } from 'src/app/constant/config';
import { MESSAGE } from 'src/app/constant/message';
import { closeDialogConfirm, showDialogConfirm } from 'src/features/uiSlice';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import DialogConfirm from 'src/app/components/DialogConfirm';
import LoadingComponent from 'src/app/components/Loading';
import {
  deleteDevices,
  postExtendDevice,
  recallDevices,
  recallDevicesByAgency,
} from 'src/features/deviceSlice';

import { roles } from 'src/app/constant/roles';
import ActiveDevice from './ActiveDevice';
import EditDevice from './EditDevice';
import './style.css';
import TransferDevice from './TransferDevice';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const buttonPermissions = {
  admin: [
    "Kích hoạt",
    "Chọn gói cước",
    "Chuyển xuống đại lý",
    "Thu hồi",
    "Xóa",
  ],
  agency: [
    "Kích hoạt",
    "Chọn gói cước",
    "Thu hồi",
  ],
  enduser: ["Kích hoạt"],
};

const menuPermissions = {
  admin: [
    "Kích hoạt thiết bị",
    "Chọn gói cước",
    "Chuyển xuống đại lý",
    "Gia hạn thiết bị",
    "Thu hồi thiết bị bảo hành",
    "Chọn người bán",
    "Xóa",
  ],
  agency: [
    "Kích hoạt thiết bị",
    "Chọn gói cước",
    "Gia hạn thiết bị",
    "Thu hồi thiết bị bảo hành",
  ],
  enduser: ["Kích hoạt thiết bị"],
};

const Results = ({
  isLoading,
  getListDeviceRef,
  totalDevice,
  listDevice,
}) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [limit, setLimit] = useState(PAGE_SIZE_LIST);
  const [page, setPage] = useState(1);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openRecallModal, setOpenRecallModal] = useState(false);
  const [openExtendModal, setOpenExtendModal] = useState(false);
  const [openModalActive, setOpenModalActive] = useState(false);
  const [itemCurrent, setItemCurrent] = useState({});
  const [dataChecked, setDataChecked] = useState({});
  const [editOnBar, setEditOnBar] = useState(true);

// Menu action right
  const renderMenu = (role, device) => {
    const menuActions = {
      "Kích hoạt thiết bị": () => handleActiveDevice(),
      "Chọn gói cước": () => handleEditDevice(),
      "Chuyển xuống đại lý": () => handleTransferDevice(device.id),
      "Gia hạn thiết bị": () => handleExtendDevice(),
      "Thu hồi thiết bị bảo hành": () => handleRecallDevice(),
      "Chọn người bán": () => {},
      "Xóa": () => handleDeleteDevice(device.id),
    };
    const allowedActions = menuPermissions[role] || [];
    
    return (
      <Typography>
        <List>
          {allowedActions.map((item, index) => (
            <ListItem
              key={index}
              onClick={menuActions[item]}
              className={`border-bottom list-menu-device cursor-pointer ${
                item === "Xóa" ? "text-danger" : ""
              }`}
              {...(item === "Xóa" ? { "data-id": device.id } : {})}
            >
              {item === "Xóa" ? <span className="text-danger">Xóa</span> : item}
            </ListItem>
          ))}
        </List>
      </Typography>
    );
  };
// Menu action header bar
const renderButtons = (role) => {
  const buttonConfig = [
    {
      label: "Chọn gói cước",
      onClick: () => setOpenEditModal(true),
      icon: "/static/iconSvg/dollar.svg",
      alt: "seller",
    },
    {
      label: "Kích hoạt",
      onClick: () => setOpenModalActive(true),
      icon: "/static/iconSvg/select-seller.svg",
      alt: "seller",
    },
    {
      label: "Thu hồi",
      onClick: () => setOpenRecallModal(true),
      icon: "/static/iconSvg/recall.svg",
      alt: "seller",
    },
    {
      label: "Chuyển xuống đại lý",
      onClick: () => setOpenTransferModal(true),
      icon: "/static/iconSvg/transfer.svg",
      alt: "seller",
    },
    {
      label: "Xóa",
      onClick: () => handleDeleteMultiDevices(),
      icon: "/static/iconSvg/delete-icon.svg",
      alt: "delete",
    },
  ];
  const allowedButtons = buttonPermissions[role] || [];
  const filteredButtons = buttonConfig.filter((btn) =>
    allowedButtons.includes(btn.label)
  );

  return (
    <>
      {filteredButtons.map((btn, index) => (
        <span
          key={index}
          onClick={btn.onClick}
          className="mx-2 btnDevice"
          color="primary"
          variant="outlined"
          size="small"
        >
          <img
            src={btn.icon}
            alt={btn.alt}
            style={{ paddingRight: "5px", paddingBottom: "4px" }}
          />
          <span className="pt-1">{btn.label}</span>
        </span>
      ))}
    </>
  );
};

  const DeviceMenu = ({ role, device }) => {
    return renderMenu(role, device);
  };

  const DeviceButtons = ({ role }) => {
    return renderButtons(role);
  };

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const role = dataLogin?.role?.title;
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.AGENCY;
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  const [params, setParams] = useState({
    page: query.get('page') || page,
    page_size: query.get('page_size') || limit
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const dataSelected = editOnBar ? dataChecked : itemCurrent;

  useEffect(() => {
    if (query.get('page')) {
      setPage(query.get('page'));
    }
    if (query.get('page_size')) {
      setLimit(query.get('page_size'));
    }
  }, []);

  const checked = (id) => {
    return rowsSelected.indexOf(id) !== -1;
  };

  const select = (deviceId, device) => {
    let rowIndex = rowsSelected.indexOf(deviceId);
    setEditOnBar(true);
    setDataChecked(device);
    if (rowIndex === -1) {
      const newSelected = [deviceId, ...rowsSelected];
      setRowsSelected(newSelected);
    } else {
      const newSelected = rowsSelected.filter(i => i !== deviceId);
      setRowsSelected(newSelected);
    }
  };

  const handleDeleteMultiDevices = () => {
    dispatch(showDialogConfirm());
  };

  const confirmDeleteDevices = () => {
    const listId = rowsSelected.length > 0 ? rowsSelected : [itemCurrent.id];
    dispatch(closeDialogConfirm());
    dispatch(deleteDevices({ list_device_ids: listId }));
    setRowsSelected([]);
  };

  const handleDeleteDevice = () => {
    dispatch(showDialogConfirm());
    handleCloseMenu();
  };

  const handleRecallDevice = () => {
    setOpenRecallModal(true);
    handleCloseMenu();
  };

  const handleExtendDevice = () => {
    setOpenExtendModal(true);
    handleCloseMenu();
  };

  const getStatusDevice = (status, remainingDays) => {
    switch (status) {
      case DEVICE_STATUS.ACTIVE:
        return (
          <span
            className="badge-actived badge"
            style={{
              backgroundColor: remainingDays > 0 ? '#d4efdf' : '#F8D7DA', 
              color: remainingDays > 0 ? '#3DB670' : '#D9534F' 
            }}
          >
            {remainingDays > 0 ? 'Kích hoạt' : 'Hết hạn'}
          </span>
        );
      case DEVICE_STATUS.INACTIVE:
        return (
          <span
            className="badge-inActived badge"
            style={{
              backgroundColor: '#E7E8EE',
              color: '#475461'
            }}
          >
            Lưu kho
          </span>
        );
      default:
        return '-';
    }
  };

  const getSlotsOrTonnage = (vehicleType, device) => {
    switch (vehicleType) {
      case 'Xe khách':
        return <span> {device.vehicle?.vehicle_type?.slots} chỗ </span>;
      case 'Xe tải':
        return <span> {device.vehicle?.vehicle_type?.tonnage} tấn</span>;
      default:
        return '--';
    }
  };

  const confirmRecall = () => {
    const listId = rowsSelected.length > 0 ? rowsSelected : [itemCurrent.id];
    if (isAdmin) {
      dispatch(recallDevices({ list_device_id: listId }));
    } else if (isAgency) {
      dispatch(
        recallDevicesByAgency({
          id: agencyID,
          data: { list_device_id: listId }
        })
      );
    }
    setRowsSelected([]);
    setOpenRecallModal(false);
  };

  const confirmExtend = () => {
    dispatch(
      postExtendDevice({ id: itemCurrent.id, agencyId: itemCurrent.agencyId })
    );
    setOpenExtendModal(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (!getListDeviceRef) return;
    const newparams = Object.assign({}, params, { page: newPage });
    setParams(newparams);
    getListDeviceRef(newparams);
  };

  const handleClickMenu = (event, device) => {
    setAnchorEl(event.currentTarget);
    setItemCurrent(device);
    setEditOnBar(false);
  };

  const closeModalEdit = () => {
    setOpenEditModal(false);
  };

  const closeModalTransfer = () => {
    setOpenTransferModal(false);
  };

  const closeModalActive = () => {
    setOpenModalActive(false);
  };

  const handleCloseConfirm = () => {
    setRowsSelected([]);
    return dispatch(closeDialogConfirm());
  };

  const checkAll = () => {
    if (rowsSelected.length > 0) {
      setRowsSelected([]);
    }
    if (listDevice?.length > 0 && rowsSelected.length !== listDevice?.length) {
      const all = listDevice.map(item => {
        return item.id;
      });
      setRowsSelected(all);
    }
  };

  const handleEditDevice = () => {
    setOpenEditModal(true);
    handleCloseMenu();
  };

  const handleTransferDevice = id => {
    setOpenTransferModal(true);
    handleCloseMenu();
  };

  const handleActiveDevice = () => {
    setOpenModalActive(true);
    handleCloseMenu();
  };

  return (
    <>
      <ModalConfirm
        title={`Thu hồi thiết bị`}
        content={`Bạn có muốn thu hồi thiết bị không ?`}
        textOk={'Đồng ý'}
        textCancel={'Hủy'}
        open={openRecallModal}
        setOpen={setOpenRecallModal}
        callbackOk={confirmRecall}
      />

      <ModalConfirm
        title={`Gia hạn  gói cước`}
        content={`Bạn có chắn chắn  muốn gia hạn gói cước không ?`}
        textOk={'Đồng ý'}
        textCancel={'Hủy'}
        open={openExtendModal}
        setOpen={setOpenExtendModal}
        callbackOk={confirmExtend}
      />

      {openEditModal && (<EditDevice open={openEditModal} device={dataSelected} onClose={closeModalEdit} />)}

      {openModalActive && ( <ActiveDevice open={openModalActive} onClose={closeModalActive} dataSelected={dataSelected} /> )}

      {openTransferModal && (
        <TransferDevice
          open={openTransferModal}
          rowsSelected={rowsSelected.length > 0 ? rowsSelected : [itemCurrent.id]}
          onClose={closeModalTransfer}
        />
      )}

      <DialogConfirm
        title={MESSAGE.CONFIRM_DELETE_DEVICE}
        textOk={MESSAGE.BTN_YES}
        textCancel={MESSAGE.BTN_CANCEL}
        content={MESSAGE.CONTENT_DELETE_DEVICE}
        callbackOk={() => confirmDeleteDevices()}
        callbackCancel={handleCloseConfirm}
      />


      <TableContainer
        component={Paper}
        style={{ maxWidth: '100%', overflowX: 'auto' }}
      >
        <Box style={{ minWidth: 1050, paddingRight: 0, marginRight: 0 }}>
          {rowsSelected.length > 0 && (
            <Fade in={rowsSelected.length > 0}>
              <Box
                minWidth={1250}
                style={{ fontSize: '14px', padding: '9px 4px' }}
              >
                <span width={50}>
                  <Checkbox
                    checked={rowsSelected.length > 0}
                    onChange={() => checkAll()}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </span>

                <DeviceButtons role={role} />
              </Box>
            </Fade>
          )}

          <PerfectScrollbar>
            <Box mb={0} className="table-result">
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <Table>
                  {rowsSelected.length === 0 && listDevice?.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell width={50}>
                          <Checkbox
                            checked={rowsSelected.length > 0}
                            onChange={() => checkAll()}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </TableCell>
                        <TableCell style={{ width: 120, minWidth: 120 }}>
                          Mã thiết bị
                        </TableCell>
                        <TableCell style={{ width: 120, minWidth: 120 }}>
                          Tên/Biển số
                        </TableCell>
                        <TableCell style={{ width: 130, minWidth: 130 }}>
                          Loại phương tiện
                        </TableCell>
                        <TableCell style={{ width: 150, minWidth: 150 }}>
                          Serial
                        </TableCell>
                        <TableCell style={{ width: 100, minWidth: 100 }}>
                          Gói cước
                        </TableCell>
                        <TableCell style={{ width: 120, minWidth: 120 }}>
                          Ngày sản xuất
                        </TableCell>
                        <TableCell style={{ width: 80, minWidth: 80 }}>
                          Phiên bản
                        </TableCell>
                        <TableCell style={{ width: 100, minWidth: 100 }}>
                          Trạng thái
                        </TableCell>
                        <TableCell style={{ width: 130, minWidth: 130 }}>
                          Ngày kích hoạt
                        </TableCell>
                        <TableCell style={{ width: 100, minWidth: 100 }}>
                          Hạn gói cước
                        </TableCell>
                        <TableCell style={{ width: 100, minWidth: 100 }}>
                          Số sim
                        </TableCell>
                        <TableCell style={{ width: 120, minWidth: 120 }}>
                          Số chỗ/Tải trọng
                        </TableCell>
                        <TableCell style={{ width: 150, minWidth: 150 }}>
                          Người dùng
                        </TableCell>
                        <TableCell style={{ width: 120, minWidth: 120 }}>
                          Số điện thoại
                        </TableCell>
                        <TableCell style={{ width: 120, minWidth: 120 }}>
                          Đại lý
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                  )}

                  <TableBody>
                    {listDevice && listDevice.length > 0 ? (
                      listDevice.map((device, index) => (
                        <TableRow hover key={device.id}>
                          <TableCell>
                            <Checkbox
                              checked={checked(device.id)}
                              onChange={() => select(device.id, device)}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {device?.deviceType?.name || '--'}
                          </TableCell>
                          <TableCell>
                            {device.vehicle?.license_plate
                              ? device.vehicle?.license_plate
                              : device.vehicle?.name || '--'}
                          </TableCell>
                          <TableCell>
                            {device.vehicle?.vehicle_type?.title || '--'}
                          </TableCell>
                          <TableCell>{device.serial || '--'}</TableCell>
                          <TableCell>
                            {device.deviceServicePackages?.servicePackage
                              ?.name || '--'}
                          </TableCell>
                          <TableCell>
                            {device?.date_of_manufacture
                              ? moment(device.date_of_manufacture).format(
                                  'DD-MM-yyyy'
                                )
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {device?.hardware_version || '--'}
                          </TableCell>

                          <TableCell
                          >
                            {getStatusDevice(device?.status, device?.deviceServicePackages?.remaining_days)}
                          </TableCell>

                          <TableCell>
                            {device.active_at
                              ? moment(device.active_at).format('DD-MM-yyyy')
                              : '--'}
                          </TableCell>
                          <TableCell>
                            {device?.deviceServicePackages?.remaining_days ||
                              '--'}
                          </TableCell>
                          <TableCell>{device.sim_serial || '--'}</TableCell>
                          <TableCell>
                            {getSlotsOrTonnage(
                              device.vehicle?.vehicle_type?.title,
                              device
                            )}
                          </TableCell>
                          <TableCell>
                            {device.end_user?.user?.full_name || '--'}
                          </TableCell>
                          <TableCell>
                            {device.end_user?.user?.phone || '--'}
                          </TableCell>

                          <TableCell>
                            {device.agency?.name || '--'}{' '}
                            {device.agency?.agency_code
                              ? `(${device.agency.agency_code})`
                              : ''}
                          </TableCell>
                          <TableCell>
                            <span style={{ color: '#AEB1C5' }}>
                              <MoreVertIcon
                                aria-describedby={`menu-device-${device.id}`}
                                className={`cursor-pointer hover-red`}
                                onClick={e => handleClickMenu(e, device)}
                              />
                            </span>

                            <Popover
                              id={`menu-device-${device.id}`}
                              className="popover-device"
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <DeviceMenu role={role} device={device} />
                            </Popover>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100vw'
                        }}
                      >
                        <Box>
                          <img
                            src="/static/empty.png"
                            class="justify-content-center"
                          />
                        </Box>
                        <Typography>
                          <h4>Danh sách trống </h4>
                        </Typography>
                      </Box>
                    )}
                  </TableBody>
                </Table>
              )}
            </Box>
          </PerfectScrollbar>
        </Box>
        {/* </Card> */}
      </TableContainer>
      {listDevice && listDevice.length > 0 && (
        <div class="mt-3 mr-4 float-right">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15px'
            }}
          >
            <Typography style={{ fontSize: '14px' }}>
              Tổng: {totalDevice}
            </Typography>
            <Pagination
              color='primary'
              count={Math.ceil(totalDevice / limit)}
              size="small"
              onChange={handlePageChange}
              page={Number(params.page)}
              showFirstButton
              showLastButton
            />
          </Box>
        </div>
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  listDevice: PropTypes.array.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  groupAction: {
    display: 'flex',
    alignItems: 'center',
    '& .action': {
      cursor: 'pointer',
      padding: '0 5px',
      '&:hover': {
        color: '#3f51b5'
      }
    }
  },
  minWithColumn: {
    minWidth: '150px'
  },
  table: {
    minWidth: 1500
  }
}));

export default React.memo(Results);
