import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { STATUS_API } from 'src/app/constant/config';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { deviceType } from 'src/app/constant/deviceType';
import { updateDeviceVersion } from 'src/features/versionDeviceSlice';
import CustomDialog from 'src/app/components/CustomDialog';

const EditVersion = ({ open, onClose, versionDevice }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const statusCreate = useSelector(state => state.driverSlice.statusCreate);

  const dataInit = {
    device_version_id: versionDevice.id,
    device_type_id: versionDevice.deviceType.id,
    version: versionDevice.version,
    description: versionDevice.description,
  }
  const [initValue, setInitValue] = useState(dataInit);

  const handleSubmit = data => {
    const newData = {
      device_type_id: +data.device_type_id,
      description: data.description,
      version: data.version
    };

    const id = data.device_version_id;
    dispatch(updateDeviceVersion({ id, body: newData }));
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog title="Chỉnh sửa phiên bản" open={open} onClose={handleClose} >
      <div>
        <Formik
          initialValues={initValue}
          validationSchema={Yup.object().shape({
            device_type_id: Yup.number(),
            version: Yup.string()
              .max(100)
              .required('Phiên bản không được để trống'),
            description: Yup.string()
              .max(100)
              .required('Mô tả không được để trống')
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <InputLabel>Tên loại thiết bị </InputLabel>
                  <TextField
                    className="input-no-fieldset"
                    size="small"
                    fullWidth
                    select
                    helperText={
                      touched.title_device_type && errors.title_device_type
                    }
                    margin="normal"
                    name="device_type_id"
                    value={values.device_type_id || ''}
                    onBlur={handleBlur}
                    onChange={e => {
                      setFieldValue('device_type_id', e.target.value);
                      setInitValue({...dataInit, device_type_id: e.target.value});
                    }}
                    SelectProps={{ native: true }}
                    variant="outlined"
                  >
                    {deviceType &&
                      deviceType.map(type => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Phiên bản </InputLabel>
                  <TextField
                    number
                    error={Boolean(touched.version && errors.version)}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.version && errors.version}
                    margin="normal"
                    name="version"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.version}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Mô tả</InputLabel>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.description && errors.description}
                    margin="normal"
                    name="description"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      onClick={() => onClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {'Chỉnh sửa'}
                    </Button>
                    {statusCreate === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
}));

export default React.memo(EditVersion);
