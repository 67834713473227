import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import {Button, Box} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialogConfirm } from '../../features/uiSlice';
import Loading from './Loading';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogConfirm({
  title ,
  content,
  loading = false,
  textOk = 'OK',
  textCancel = 'Cancel',
  callbackOk,
  callbackCancel
}) {
  const open = useSelector(state => state.uiSlice.isShowConfirm);
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleCancel = () => {
    dispatch(closeDialogConfirm());
    callbackCancel && callbackCancel();
  };
  const handleOk = () => {
    callbackOk && callbackOk();
    setTimeout(() => {
      if (loading === false) dispatch(closeDialogConfirm());
    }, 300);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch(closeDialogConfirm())}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: { paddingBottom: '8px' } 
      }}
    >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ textAlign: 'center' }}
        >
          <span style={{ fontSize: '20px', color: '#1E2323', fontWeight: '600' }}>
            {title}
          </span>
        </DialogTitle>
        <DialogContent >
          <DialogContentText 
            id="alert-dialog-slide-description"
            style={{
              textAlign: 'center', 
              display: 'flex', 
              justifyContent: 'center',  
              alignItems: 'center',     
              maxWidth: '311px', 
              margin: '0 auto'    
            }}
          >
            <span style={{
              display: 'block',     
              whiteSpace: 'normal',  
              wordBreak: 'break-word', 
              maxWidth: '80%',      
              margin: '0 auto',   
            }}>
             {content}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display:'flex', justifyContent:'center'}}>
          <Button
            style={{ marginRight: '10px', textTranform: 'none !important' }}
            className="btn-main mx-2"
            variant="contained"
            onClick={handleOk}
            color="primary"
          >
            {textOk} {loading && <Loading />}
          </Button>
          <Button
            style={{ marginRight: '10px', textTranform: 'none !important' }}
            className="btn-main btn-plain mx-2"
            variant="contained"
            onClick={handleCancel}
            color="danger"
          >
            {textCancel}
          </Button>
        </DialogActions>
    </Dialog>
  );
}
