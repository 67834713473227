import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useNavigate } from 'react-router';
import './style.css';
import { getIconStatus } from 'src/app/utils/vehicleService';

const VehicleTableCell = props => {
  const { row, index, rowActive } = props;
  const navigate = useNavigate();
  const icon = getIconStatus(row.device_status, row.device_type);
  const iconCameraLive =
    row?.device_serial === rowActive
      ? `/static/iconSvg/video_motion_active.svg`
      : `/static/iconSvg/video_motion.svg`;

  return (
    <TableCell
      key={index}
      align="center"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        verticalAlign: 'middle',
        height: '37px'
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '3px'
        }}
      >
        <Box style={{ width: '27px', height: '27px' }}>
          <img alt="car" src={icon} style={{ width: '100%', height: '100%' }} />
        </Box>
        <Typography style={{ 
           fontSize: '12px',
           fontWeight: 'bold',
           whiteSpace: 'nowrap', 
           overflow: 'hidden', 
           textOverflow: 'ellipsis', 
           maxWidth: '80px' 
        }}>
          {row.license_plate}
        </Typography>
      </Box>

      {row.device_type === 'VGPS_CAM4G' && (
        <Box
          onClick={e => {
            e.stopPropagation();
            navigate(
              `/app/camera/streaming?device_serial=${row.device_serial}&show_menu=true`
            );
          }}
        >
          <img
            alt="car"
            src={iconCameraLive}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      )}
    </TableCell>
  );
};

export default React.memo(VehicleTableCell);
