import { createSlice } from '@reduxjs/toolkit';
import { HTTP_GETTYPE, STATUS_API } from 'src/app/constant/config';
import { MESSAGE } from 'src/app/constant/message';
import AxiosAdapter from './AxiosAdapter';

export const getListSim = AxiosAdapter.GetHttp(
  'simSlice/getListSim',
  '/sim',
  HTTP_GETTYPE.ALL_PAGINATION
);

export const createSim = AxiosAdapter.HttpPost(
  'simSlice/createSim',
  '/sim',
  MESSAGE.CREATE_SIM_SUCCESS,
  'Tạo mới sim không thành công'
);

export const importSim = AxiosAdapter.HttpPostUploadFile(
  'simSlice/importSim',
  '/sim/import',
  MESSAGE.IMPORT_SIM_SUCCESS,
  'Import sim không thành công'
);

export const deleteSim = AxiosAdapter.HttpDelete(
  'simSlice/deleteSim',
  '/sim',
  MESSAGE.DELETE_SIM_SUCCESS,
  'Xoá sim không thành công'
);

export const deleteMultiSim = AxiosAdapter.HttpDeleteMulti(
  'simSlice/deleteSim',
  'sim',
  MESSAGE.DELETE_SIM_SUCCESS,
  'Xoá sim không thành công'
);

export const updateSim = AxiosAdapter.HttpUpdateById(
  'simSlice/updateSim',
  '/sim',
  MESSAGE.UPDATE_SIM_SUCCESS,
  'Cập nhật sim thất bại'
);

export const simSlice = createSlice({
  name: 'simSlice',
  initialState: {
    listSims: [],
    totalSims: 0,
    statusGet: null,
    statusCreate: null,
    statusDelete: null,
    statusDeleteMultiSim: null,
    statusUpdate: null,
    statusImport: null,
    errors: null,
    isLoading: false
  },

  reducers: {
    resetChange: state => {
      state.statusGet = null;
      state.statusCreate = null;
      state.statusDelete = null;
      state.statusUpdate = null;
      state.statusDeleteMultiSim = null;
      state.statusImport = null;
      state.errors = null;
    }
  },

  extraReducers: {
    [getListSim.pending]: state => {
      state.statusGet = STATUS_API.PENDING;
      state.errorGetAll = null;
      state.listSims = [];
      state.isLoading = true;
    },
    [getListSim.fulfilled]: (state, action) => {
      state.statusGet = STATUS_API.SUCCESS;
      state.listSims = action.payload.payload.result;
      state.totalSims = action.payload.payload.number_of_item;
      state.isLoading = false;
      state.statusCreate = null;
      state.statusDelete = null;
      state.statusUpdate = null;
      state.statusDeleteMultiSim = null;
      state.statusImport = null;
      state.errors = null;
    },
    [getListSim.rejected]: (state, action) => {
      state.statusGet = STATUS_API.ERROR;
      state.isLoading = false;
    },
    [createSim.pending]: state => {
      state.statusCreate = STATUS_API.PENDING;
    },
    [createSim.fulfilled]: (state, action) => {
      state.statusCreate = STATUS_API.SUCCESS;
    },
    [createSim.rejected]: (state, action) => {
      state.statusCreate = STATUS_API.ERROR;
      state.errors = action.payload.message;
    },
    [deleteSim.pending]: state => {
      state.statusDelete = STATUS_API.PENDING;
    },
    [deleteSim.fulfilled]: (state, action) => {
      state.statusDelete = STATUS_API.SUCCESS;
    },
    [deleteSim.rejected]: (state, action) => {
      state.statusDelete = STATUS_API.ERROR;
      state.errors = action.payload.message;
    },
    [deleteMultiSim.pending]: state => {
      state.statusDeleteMultiSim = STATUS_API.PENDING;
    },
    [deleteMultiSim.fulfilled]: (state, action) => {
      state.statusDeleteMultiSim = STATUS_API.SUCCESS;
    },
    [deleteMultiSim.rejected]: (state, action) => {
      state.statusDeleteMultiSim = STATUS_API.ERROR;
      state.errors = action.payload.message;
    },
    [updateSim.pending]: state => {
      state.statusUpdate = STATUS_API.PENDING;
    },
    [updateSim.fulfilled]: (state, action) => {
      state.statusUpdate = STATUS_API.SUCCESS;
    },
    [updateSim.rejected]: (state, action) => {
      state.statusUpdate = STATUS_API.ERROR;
      state.errors = action.payload.message;
    },
    [importSim.pending]: state => {
      state.statusImport = STATUS_API.PENDING;
      state.err = null;
    },
    [importSim.fulfilled]: (state, action) => {
      state.statusImport = STATUS_API.SUCCESS;
      state.resultImport = action.payload.payload;
      state.err = null;
    },
    [importSim.rejected]: (state, action) => {
      state.statusImport = STATUS_API.ERROR;
      state.err = action.payload?.message || action.error;
    }
  }
});

export const { resetChange } = simSlice.actions;

export default simSlice.reducer;
