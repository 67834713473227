import {
  Box,
  Card,
  List,
  ListItem,
  makeStyles,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  withStyles,
  Fade
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import DialogConfirm from 'src/app/components/DialogConfirm';
import { MESSAGE } from 'src/app/constant/message';
import { closeDialogConfirm, showDialogConfirm } from 'src/features/uiSlice';

import { useDispatch } from 'react-redux';
import LoadingComponent from 'src/app/components/Loading';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import moment from 'moment';
import { deleteDeviceVersion } from 'src/features/versionDeviceSlice';
import EditVersion from './EditVersion';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    whiteSpace: 'nowrap'
  },
  body: {
    fontSize: 14,
    whiteSpace: 'nowrap'
  },
  root: {
    padding: '4px'
  }
}))(TableCell);

const TableVersionDevice = ({
  className,
  listDeviceVersion,
  getListVersionDeviceWithParams,
  totalVersion,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.versionDeviceSlice.isLoading);
  const classes = useStyles();
  const [limit, setLimit] = useState(PAGE_SIZE_LIST);
  const [selectedItem, setSelectedItem] = useState();
  const [itemCurrent, setItemCurrent] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);

  const [page, setPage] = useState(1);

  const [params, setParams] = useState({
    page: page,
    page_size: limit
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickMenu = (event, service) => {
    setAnchorEl(event.currentTarget);
    setItemCurrent(service);
    setSelectedItem(service.id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleEditServicePackage = () => {
    setOpenEditModal(true);
    handleCloseMenu();
  };

  const handleDeleteVersion = () => {
    dispatch(showDialogConfirm());
    handleCloseMenu();
  };
  const handleDeleteMultiVersions = () => {
    dispatch(showDialogConfirm());
    handleCloseMenu();
  };

  const confirmDelete = () => {
    const listId = rowsSelected?.length > 0 ? rowsSelected : [selectedItem];
    setRowsSelected([]);
    dispatch(closeDialogConfirm());
    dispatch(deleteDeviceVersion({ list_device_version_ids: listId }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (!getListVersionDeviceWithParams) return;
    const newparams = Object.assign({}, params, { page: newPage });
    setParams(newparams);
    getListVersionDeviceWithParams(newparams);
  };

  const closeModal = () => {
    setOpenEditModal(false);
  };

  const checked = id => {
    return rowsSelected.indexOf(id) !== -1;
  };
  const select = driver => {
    if (rowsSelected.indexOf(driver) === -1) {
      const newSelected = [driver, ...rowsSelected];
      setRowsSelected(newSelected);
    } else {
      const newSelected = rowsSelected.filter(i => i !== driver);
      setRowsSelected(newSelected);
    }
  };

  const checkAll = () => {
    if (rowsSelected.length > 0) {
      setRowsSelected([]);
    }

    if (
      listDeviceVersion.length > 0 &&
      rowsSelected.length !== listDeviceVersion.length
    ) {
      const all = listDeviceVersion.map(item => {
        return item.id;
      });
      setRowsSelected(all);
    }
  };

  return (
    <>
    {
      openEditModal && (
        <EditVersion
          open={openEditModal}
          onClose={closeModal}
          versionDevice={itemCurrent}
        />
      )
    }
      <DialogConfirm
        title={MESSAGE.CONFIRM_DELETE_VERSIONDEVICE}
        content={MESSAGE.CONTENT_DELETE_VERSIONDEVICE}
        textOk={MESSAGE.BTN_YES}
        textCancel={MESSAGE.BTN_CANCEL}
        callbackOk={() => confirmDelete()}
        callbackCancel={() => dispatch(closeDialogConfirm())}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div>
            {rowsSelected.length > 0 && (
              <Fade in={rowsSelected.length > 0}>
                <Box
                  minWidth={1050}
                  style={{ fontSize: '14px', padding: '6px 4px' }}
                >
                  <span>
                    <Checkbox
                      checked={rowsSelected.length > 0}
                      onChange={() => checkAll()}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </span>

                  <span
                    onClick={handleDeleteMultiVersions}
                    className="mx-2 btnDevice"
                    color="primary"
                    variant="outlined"
                    size="small"
                  >
                    <img
                      src={`/static/iconSvg/delete-icon.svg`}
                      alt="delete"
                      style={{ paddingRight: '5px', paddingBottom: '4px' }}
                    />
                    <span className="pt-1"> Xóa </span>
                  </span>
                </Box>
              </Fade>
            )}
            <PerfectScrollbar>
              {listDeviceVersion && listDeviceVersion.length ? (
                <Box minWidth={1050}>
                  <Table>
                    {rowsSelected.length === 0 &&
                      listDeviceVersion?.length > 0 && (
                        <TableHead>
                          <TableRow>
                            <StyledTableCell width={50}>
                              <Checkbox
                                checked={rowsSelected.length > 0}
                                onChange={() => checkAll()}
                                color="primary"
                                inputProps={{
                                  'aria-label': 'secondary checkbox'
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell>Mã thiết bị </StyledTableCell>
                            <StyledTableCell>Ngày tạo </StyledTableCell>
                            <StyledTableCell>Phiên bản</StyledTableCell>
                            <StyledTableCell>Mô tả</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                          </TableRow>
                        </TableHead>
                      )}
                    <TableBody>
                      {listDeviceVersion.map((version, index) => (
                        <TableRow hover key={version?.id}>
                          <StyledTableCell
                            style={{ maxWidth: '12px' }}
                            onClick={() => select(version?.id)}
                          >
                            <Checkbox
                              checked={checked(version?.id)}
                              onChange={() => select(version?.id)}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {version?.deviceType.name || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {version?.created_at
                              ? moment(version.created_at).format('DD-MM-yyyy')
                              : '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {version?.version || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {version?.description || '-'}
                          </StyledTableCell>

                          <StyledTableCell>
                            <span style={{ color: '#AEB1C5' }}>
                              <MoreVertIcon
                                aria-describedby={`menu-device-${version.id}`}
                                className={`cursor-pointer hover-red`}
                                onClick={e => handleClickMenu(e, version)}
                              />
                            </span>

                            <Popover
                              id={`menu-device-${version.id}`}
                              className="popover-device"
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <Typography>
                                <List>
                                  <ListItem
                                    onClick={handleEditServicePackage}
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Chỉnh sửa
                                  </ListItem>
                                  <ListItem
                                    onClick={handleDeleteVersion}
                                    className="cursor-pointer list-menu-device"
                                  >
                                    <span className="text-danger"> Xóa </span>
                                  </ListItem>
                                </List>
                              </Typography>
                            </Popover>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <div className="text-center pb-5">
                  <img
                    src="/static/empty.png"
                    className="justify-content-center"
                  />
                  <h4> Danh sách trống </h4>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Card>
      {listDeviceVersion && listDeviceVersion.length > 0 && (
        <div className="mt-3 mr-4 float-right">
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '15px'
            }}
          >
            <Typography style={{ fontSize: '14px' }}>
              Tổng: {totalVersion}
            </Typography>
            <Pagination
              count={Math.ceil(totalVersion / limit)}
              color='primary'
              size="small"
              onChange={handlePageChange}
              page={Number(params.page)}
              showFirstButton
              showLastButton
            />
          </Box>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  groupAction: {
    display: 'flex',
    alignItems: 'center',
    '& .action': {
      cursor: 'pointer',
      padding: '0 5px',
      '&:hover': {
        color: '#3f51b5'
      }
    }
  },
  minWithColumn: {
    minWidth: '150px'
  }
}));

export default TableVersionDevice;
