import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookie from 'js-cookie';
import MenuControl from './MenuControl';
import { ButtonShowMenu } from 'src/app/components/button/ButtonMenu';
import {
  getListVehicleTracking,
  getListVehicleTrackingById,
  getListVehicleTrackingByUserId
} from 'src/features/vehicleSlice';
import { useDispatch, useSelector } from 'react-redux';

const MonitorView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === 'agency';
  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === 'admin';
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;
  const isEndUser =
    dataLogin && dataLogin.role && dataLogin.role.title === 'enduser';
  const userID =
    isEndUser && dataLogin.endUser ? dataLogin.endUser.id : undefined;

  useEffect(() => {
    let intervalId;

    if (isAdmin) {
      dispatch(getListVehicleTracking(''));
      intervalId = setInterval(() => {
        dispatch(getListVehicleTracking(''));
      }, 5000);
    } else if (isAgency) {
      dispatch(getListVehicleTrackingById({ id: agencyID }));
      intervalId = setInterval(() => {
        dispatch(getListVehicleTrackingById({ id: agencyID }));
      }, 5000);
    } else if (isEndUser) {
      dispatch(getListVehicleTrackingByUserId({ id: userID }));
      intervalId = setInterval(() => {
        dispatch(getListVehicleTrackingByUserId({ id: userID }));
      }, 5000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isAdmin, isAgency, isEndUser, agencyID, userID, dispatch]);

  useEffect(() => {
    if (!Cookie.get('access-token')) navigate('/login');
  }, [navigate]);

  const [showMenu, setShowMenu] = React.useState(true);

  return (
    <div className={classes.root}>
      <ButtonShowMenu setShowMenu={setShowMenu} showMenu={showMenu} />
      <MenuControl showMenu={showMenu} setShowMenu={setShowMenu} />

      <Box
        style={{
          flex: 1,
          overflowY: 'scroll',
          zIndex: 10
        }}
      >
        <Outlet />
      </Box>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
  }
}));

export default MonitorView;
