import axios from '../app/axiosInterceptor';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';
import {
  HTTP_GETTYPE,
  messageToastType_const,
  MSG_TIMEOUT_REQUEST,
  TIMEOUT_DEFAULT
} from '../app/constant/config';
import { _convertObjectToQuery } from '../app/utils/apiService';
import { showGlobalToast } from './uiSlice';
class AxiosAdapter {
  static HttpPost(nameSlice, url, successMessage, errorMessage) {
    return createAsyncThunk(
      `${nameSlice}`,
      (payload, { dispatch, rejectWithValue }) => {
        return new Promise((resolve, reject) => {
          axios({
            url: process.env.REACT_APP_BACKEND_URL + url,
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin': true,
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: 'Bearer ' + Cookie.get('access-token')
            },
            data: payload,
            timeout: TIMEOUT_DEFAULT
          })
            .then(res => {
              if (successMessage) {
                dispatch(
                  showGlobalToast({
                    message: successMessage,
                    type: messageToastType_const.success
                  })
                );
              }
              resolve(res.data);
            })
            .catch(err => {
              if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
              if (!err.response) reject(err);
              if (errorMessage) {
                dispatch(
                  showGlobalToast({
                    message: err?.response?.data?.message || errorMessage,
                    type: messageToastType_const.error
                  })
                );
              }
              reject(rejectWithValue(err.response?.data));
            });
        });
      }
    );
  }

  static HttpPostExportFile(nameSlice, url) {
    return createAsyncThunk(`${nameSlice}`, (payload, { rejectWithValue }) => {
      return new Promise((resolve, reject) => {
        axios({
          url: process.env.REACT_APP_BACKEND_URL + url,
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': true,
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + Cookie.get('access-token')
          },
          responseType: 'blob',
          data: payload,
          timeout: TIMEOUT_DEFAULT
        })
          .then(res => {
            resolve(res.data);
            const blob = new Blob([res.data], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'statistic_data.xlsx');
            link.click();

            window.URL.revokeObjectURL(url);
          })
          .catch(err => {
            if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
            if (!err.response) reject(err);
            reject(rejectWithValue(err.response?.data));
          });
      });
    });
  }

  static HttpPostUploadFile(nameSlice, url, successMessage, errorMessage) {
    return createAsyncThunk(
      `${nameSlice}`,
      (payload, { dispatch, rejectWithValue }) => {
        return new Promise((resolve, reject) => {
          axios({
            url: process.env.REACT_APP_BACKEND_URL + url,
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin': true,
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + Cookie.get('access-token')
            },
            data: payload,
            timeout: TIMEOUT_DEFAULT
          })
            .then(res => {
              if (successMessage) {
                dispatch(
                  showGlobalToast({
                    message: successMessage,
                    type: messageToastType_const.success
                  })
                );
              }
              resolve(res.data);
            })
            .catch(err => {
              if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
              if (!err.response) reject(err);
              if (errorMessage) {
                dispatch(
                  showGlobalToast({
                    message: err?.response?.data?.message || errorMessage,
                    type: messageToastType_const.error
                  })
                );
              }
              reject(rejectWithValue(err.response?.data));
            });
        });
      }
    );
  }
  static HttpPut(nameSlice, url, successMessage, errorMessage) {
    return createAsyncThunk(
      `${nameSlice}`,
      (payload, { dispatch, rejectWithValue }) => {
        return new Promise((resolve, reject) => {
          axios({
            url: process.env.REACT_APP_BACKEND_URL + url + `${payload.id}`,
            method: 'PUT',
            headers: {
              'Access-Control-Allow-Origin': true,
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: 'Bearer ' + Cookie.get('access-token')
            },
            data: payload.body,
            timeout: TIMEOUT_DEFAULT
          })
            .then(res => {
              if (successMessage) {
                dispatch(
                  showGlobalToast({
                    message: successMessage,
                    type: messageToastType_const.success
                  })
                );
              }
              resolve(res.data);
            })
            .catch(err => {
              if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
              if (!err.response) reject(err);
              if (errorMessage) {
                dispatch(
                  showGlobalToast({
                    message: err?.response?.data?.message || errorMessage,
                    type: messageToastType_const.error
                  })
                );
              }
              reject(rejectWithValue(err.response?.data));
            });
        });
      }
    );
  }
  static HttpUpdate(nameSlice, url, successMessage, errorMessage) {
    return createAsyncThunk(
      `${nameSlice}`,
      (payload, { dispatch, rejectWithValue }) => {
        return new Promise((resolve, reject) => {
          axios({
            url: process.env.REACT_APP_BACKEND_URL + url,
            method: 'PUT',
            headers: {
              'Access-Control-Allow-Origin': true,
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: 'Bearer ' + Cookie.get('access-token')
            },
            data: payload,
            timeout: TIMEOUT_DEFAULT
          })
            .then(res => {
              if (successMessage) {
                dispatch(
                  showGlobalToast({
                    message: successMessage,
                    type: messageToastType_const.success
                  })
                );
              }
              resolve(res.data);
            })
            .catch(err => {
              if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
              if (!err.response) reject(err);
              if (errorMessage) {
                dispatch(
                  showGlobalToast({
                    message: err?.response?.data?.message || errorMessage,
                    type: messageToastType_const.error
                  })
                );
              }
              reject(rejectWithValue(err.response?.data));
            });
        });
      }
    );
  }

  static HttpUpdateById(nameSlice, url, successMessage, errorMessage) {
    return createAsyncThunk(
      `${nameSlice}`,
      (payload, { dispatch, rejectWithValue }) => {
        return new Promise((resolve, reject) => {
          axios({
            url: process.env.REACT_APP_BACKEND_URL + url + `/${payload.id}`,
            method: 'PUT',
            headers: {
              'Access-Control-Allow-Origin': true,
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: 'Bearer ' + Cookie.get('access-token')
            },
            data: payload.data,
            timeout: TIMEOUT_DEFAULT
          })
            .then(res => {
              if (successMessage) {
                dispatch(
                  showGlobalToast({
                    message: successMessage,
                    type: messageToastType_const.success
                  })
                );
              }
              resolve(res.data);
            })
            .catch(err => {
              if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
              if (!err.response) reject(err);
              if (errorMessage) {
                dispatch(
                  showGlobalToast({
                    message: err?.response?.data?.message || errorMessage,
                    type: messageToastType_const.error
                  })
                );
              }
              reject(rejectWithValue(err.response?.data));
            });
        });
      }
    );
  }
  static GetHttp(nameSlice, url, type) {
    return createAsyncThunk(nameSlice, (payload, { rejectWithValue }) => {
      return new Promise((resolve, reject) => {
        let tmp = '';
        if (type === HTTP_GETTYPE.ALL) {
          tmp = process.env.REACT_APP_BACKEND_URL + url;
        } else if (type === HTTP_GETTYPE.ALL_PAGINATION) {
          tmp =
            process.env.REACT_APP_BACKEND_URL +
            url +
            '?' +
            _convertObjectToQuery(payload);
        } else tmp = process.env.REACT_APP_BACKEND_URL + url + payload;
        axios({
          url: tmp,
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': true,
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + Cookie.get('access-token'),
            timeout: TIMEOUT_DEFAULT
          }
        })
          .then(res => resolve(res.data))
          .catch(err => {
            if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
            if (!err.response) reject(err);
            reject(rejectWithValue(err.response?.data));
          });
      });
    });
  }

  static HttpGetById(nameSlice, url) {
    return createAsyncThunk(`${nameSlice}`, (payload, { rejectWithValue }) => {
      return new Promise((resolve, reject) => {
        axios({
          url:
            process.env.REACT_APP_BACKEND_URL +
            url +
            `/${payload.id}` +
            '?' +
            _convertObjectToQuery(payload.data),
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': true,
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + Cookie.get('access-token')
          },
          timeout: TIMEOUT_DEFAULT
        })
          .then(res => resolve(res.data))
          .catch(err => {
            if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
            if (!err.response) reject(err);
            reject(rejectWithValue(err.response?.data));
          });
      });
    });
  }
  static HttpDelete(nameSlice, uri, successMessage, errorMessage) {
    return createAsyncThunk(
      nameSlice,
      (payload, { dispatch, rejectWithValue }) => {
        return new Promise((resolve, reject) => {
          axios({
            url: `${process.env.REACT_APP_BACKEND_URL}/${uri}/${payload.id}`,
            method: 'Delete',
            headers: {
              'Access-Control-Allow-Origin': true,
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: 'Bearer ' + Cookie.get('access-token'),
              timeout: TIMEOUT_DEFAULT
            }
          })
            .then(res => {
              if (successMessage) {
                dispatch(
                  showGlobalToast({
                    message: successMessage,
                    type: messageToastType_const.success
                  })
                );
              }
              resolve(res.data);
            })
            .catch(err => {
              if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
              if (!err.response) reject(err);
              if (errorMessage) {
                dispatch(
                  showGlobalToast({
                    message: err?.response?.data?.message || errorMessage,
                    type: messageToastType_const.error
                  })
                );
              }
              reject(rejectWithValue(err.response?.data));
            });
        });
      }
    );
  }

  static HttpDeleteMulti(nameSlice, uri, successMessage, errorMessage) {
    return createAsyncThunk(
      nameSlice,
      (payload, { dispatch, rejectWithValue }) => {
        return new Promise((resolve, reject) => {
          axios({
            url: `${process.env.REACT_APP_BACKEND_URL}/${uri}`,
            method: 'Delete',
            headers: {
              'Access-Control-Allow-Origin': true,
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: 'Bearer ' + Cookie.get('access-token'),
              timeout: TIMEOUT_DEFAULT
            },
            data: payload
          })
            .then(res => {
              if (successMessage) {
                dispatch(
                  showGlobalToast({
                    message: successMessage,
                    type: messageToastType_const.success
                  })
                );
              }
              resolve(res.data);
            })
            .catch(err => {
              if (err.code === 'ECONNABORTED') reject(MSG_TIMEOUT_REQUEST);
              if (!err.response) reject(err);
              if (errorMessage) {
                dispatch(
                  showGlobalToast({
                    message: err?.response?.data?.message || errorMessage,
                    type: messageToastType_const.error
                  })
                );
              }
              reject(rejectWithValue(err.response?.data));
            });
        });
      }
    );
  }
}
export default AxiosAdapter;
