import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDialog from 'src/app/components/CustomDialog';
import {
  STATUS_API
} from 'src/app/constant/config';
import { updateStaff } from 'src/features/staffSlice';
import * as Yup from 'yup';


function EditStaff({ open, onClose, staff }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statusCreate = useSelector(state => state.staffSlice.statusCreate);

  const handleSubmit = data => {
    if (!staff || !staff?.id) return;
    const newData = {
      email: data.email,
      phone: data.phone,
      full_name: data.full_name,
      address: data.address,
    }
    dispatch(updateStaff({ id: staff.id, data: newData }));
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CustomDialog title="Chỉnh sửa nhân viên" open={open} onClose={handleClose}>
      <div>
        <Formik
          initialValues={{
            full_name: staff.full_name || '',
            email: staff.email || '',
            phone: staff.phone || '',
            address: staff.address || '',
          }}
          validationSchema={Yup.object().shape({
            full_name: Yup.string()
              .max(100)
              .required('Tên không được để trống'),
            email: Yup.string()
              .email('Email chưa đúng định dạng')
              .required('email không được để trống'),
            phone: Yup.string()
              .matches(
                /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
                'Số điện thoại chưa đúng định dạng'
              )
              .required('Số điện thoại không được để trống'),
            address: Yup.string()
                .max(100)
                .required('Địa chỉ không được để trống'),
          })}
          onSubmit={handleSubmit}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <InputLabel>Số điện thoại </InputLabel>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    className="input-no-fieldset"
                    helperText={touched.phone && errors.phone}
                    margin="normal"
                    size="small"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Tên nhân viên </InputLabel>
                  <TextField
                    error={Boolean(
                      touched.full_name && errors.full_name
                    )}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.full_name && errors.full_name}
                    margin="normal"
                    name="full_name"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.full_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
                
                <Grid item md={6} xs={12}>
                  <InputLabel>Email </InputLabel>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    margin="normal"
                    className="input-no-fieldset"
                    name="email"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>{' '}
                <Grid item md={6} xs={12}>
                  <InputLabel>Địa chỉ </InputLabel>
                  <TextField
                    error={Boolean(
                      touched.address && errors.address
                    )}
                    className="input-no-fieldset"
                    fullWidth
                    helperText={touched.address && errors.address}
                    margin="normal"
                    name="address"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                        
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                  />
                </Grid>
              
              </Grid>
              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{ marginRight: '10px', textTranform: 'none !important' }}
                      onClick={() => onClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{ marginRight: '10px', textTranform: 'none !important' }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Cập nhật 
                    </Button>
                    {statusCreate === STATUS_API.PENDING && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
}));

export default React.memo(EditStaff);
