import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  Tabs,
  Tab,
  CircularProgress
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { createSim } from 'src/features/simSlice';
import { STATUS_API } from 'src/app/constant/config';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { _convertObjectToQuery } from 'src/app/utils/apiService';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getListSim, importSim } from 'src/features/simSlice';
import CustomDialog from 'src/app/components/CustomDialog';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const initStateFromValue = {
  package: '',
  phone: '',
  imei_sim: '',
  active_at: '',
  status: '',
  serial_gateway: '',
  id: ''
};

export const CreateSim = React.memo(({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [initValue, setInitValue] = useState(initStateFromValue);
  const [tabIndex, setTabIndex] = useState(0);
  const [file, setFile] = useState(null);

  const statusCreate = useSelector(state => state.simSlice.statusCreate);
  const statusImport = useSelector(state => state.simSlice.statusImport);
  const fileInput = useRef(null);
  const queryParams = useQuery();

  const params = {
    page: queryParams.get('page') || 1,
    page_size: queryParams.get('page_size') || PAGE_SIZE_LIST
  };
  const handleChangeTab = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const onFileChange = event => {
    setFile(event.target.files[0]);
  };

  const handleRemoveFile = () => {
    setFile(null);

    const fileInput = document.getElementById('importSim');
    if (fileInput) {
      fileInput.value = '';
    }
  };
  const upload = file => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      dispatch(importSim(formData));
      setFile(null);
      document.getElementById('importSim').value = '';
      dispatch(getListSim(params));
      handleClose();
    }
  };
  const handleDrop = event => {
    event.preventDefault();
    const file = event.dataTransfer.files;
    if (file) {
      setFile(file[0]);
    }
  };

  const handleDragOver = event => {
    event.preventDefault();
  };

  const handleSubmit = data => {
    const newSim = {
      package: data.package,
      imei_sim: data.imei_sim,
      phone: data.phone,
      active_at: data.active_at
    };
    setInitValue(newSim);
    dispatch(createSim(newSim));
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setInitValue(initStateFromValue);
  };

  return (
    <CustomDialog title="Thêm mới sim" open={open} onClose={handleClose}>
      <div>
        <Formik
          initialValues={initValue}
          validationSchema={Yup.object().shape({
            imei_sim: Yup.string()
              .max(100)
              .required('Imei sim không được để trống'),
            phone: Yup.string()
              .matches(
                /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
                'Số điện thoại chưa đúng định dạng'
              )
              .required('Số điện thoại không được để trống'),
            active_at: Yup.date().required('Ngày kích hoạt không được để trống')
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Tabs
                value={tabIndex}
                onChange={handleChangeTab}
                indicatorColor="primary"
                style={{ marginBottom: '28px' }}
              >
                <Tab label="Thêm thủ công" />
                <Tab label="Thêm từ file" />
              </Tabs>

              {tabIndex === 0 && (
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <InputLabel>Gói Sim</InputLabel>
                    <TextField
                      fullWidth
                      className="input-no-fieldset"
                      margin="normal"
                      size="small"
                      name="package"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Nhập gói sim"
                      value={values.package}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        )
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>
                      Số điện thoại <span style={{ color: 'red' }}>*</span>:{' '}
                    </InputLabel>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      className="input-no-fieldset"
                      helperText={touched.phone && errors.phone}
                      margin="normal"
                      size="small"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Nhập số điện thoại"
                      value={values.phone}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        )
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>
                      Imei sim <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      error={Boolean(touched.imei_sim && errors.imei_sim)}
                      fullWidth
                      className="input-no-fieldset"
                      helperText={touched.imei_sim && errors.imei_sim}
                      margin="normal"
                      size="small"
                      name="imei_sim"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Nhập imei"
                      value={values.imei_sim}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        )
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputLabel>
                      {' '}
                      Ngày kick hoạt <span style={{ color: 'red' }}>
                        *
                      </span>:{' '}
                    </InputLabel>
                    <TextField
                      error={Boolean(touched.active_at && errors.active_at)}
                      fullWidth
                      format="DD/MM/yyyy"
                      helperText={touched.active_at && errors.active_at}
                      id="date"
                      margin="normal"
                      name="active_at"
                      type="date"
                      className="input-no-fieldset"
                      defaultValue=""
                      value={values.active_at}
                      onChange={handleChange}
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              )}
              {tabIndex === 1 && (
                <Box
                  className={`${classes.uploadBox} ${file ? 'hasFile' : ''}`}
                  onClick={() => !file && fileInput.current.click()}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <input
                    name="file"
                    ref={fileInput}
                    id="importSim"
                    onChange={onFileChange}
                    type="file"
                    hidden
                  />
                  {!file ? (
                    <>
                      <Box className={classes.uploadIcon}>+</Box>
                      <Box mt={1} className={classes.uploadText}>
                        Chọn file trên thiết bị của bạn
                      </Box>
                    </>
                  ) : (
                    <Box
                      className={classes.fileContainer}
                      mt={2}
                      display="flex"
                      alignItems="center"
                      sx={{
                        background: '#f0f0f0',
                        padding: '5px 10px',
                        borderRadius: '16px',
                        display: 'inline-flex'
                      }}
                    >
                      <Box className={classes.fileName} color="black" mr={2}>
                        {file.name}
                      </Box>
                      <Box
                        className={classes.removeIcon}
                        color="red"
                        sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                        onClick={e => {
                          handleRemoveFile();
                        }}
                      >
                        x
                      </Box>
                    </Box>
                  )}
                </Box>
              )}

              <Box my={3}>
                <div className={classes.groupButtonSubmit}>
                  <div className={classes.wrapper}>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      onClick={() => handleClose()}
                      className="btn-main btn-plain mx-3"
                      color="primary"
                      size="large"
                      variant="contained"
                    >
                      Thoát
                    </Button>
                    <Button
                      style={{
                        marginRight: '10px',
                        textTranform: 'none !important'
                      }}
                      className="btn-main mx-3"
                      color="primary"
                      size="large"
                      type={tabIndex === 0 ? 'submit' : 'button'}
                      variant="contained"
                      onClick={() => {
                        if (tabIndex === 1) {
                          upload(file);
                        }
                      }}
                    >
                      Lưu lại
                    </Button>
                    {statusCreate === STATUS_API.PENDING ||
                      (statusImport === STATUS_API.PENDING && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      ))}
                  </div>
                </div>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </CustomDialog>
  );
});

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  shadowBox: {
    boxShadow: '0 2px 5px rgba(0,0,0,.18)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  formHeader: {
    padding: theme.spacing(3)
  },
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  avatar: {
    height: 100,
    width: 100
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  disableForm: {
    pointerEvents: 'none'
  },
  colorWhite: {
    color: '#fff'
  },
  uploadBox: {
    border: '2px dashed #b71c1c',
    borderRadius: '8px',
    padding: '94px 24px',
    marginTop: '16px',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '&.hasFile': {
      border: 'none',
      padding: ' 0px 24px 188px 24px',
      marginTop: '16px',
      textAlign: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      alignItems: 'flex-start'
    }
  },

  uploadIcon: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid #b71c1c',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#b71c1c'
  },
  uploadText: {
    marginTop: '8px',
    color: '#b71c1c'
  },
  fileContainer: {
    background: '#f0f0f0',
    padding: '5px 10px',
    borderRadius: '16px',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '14px'
  },
  fileName: {
    color: 'black',
    marginRight: '8px'
  },
  removeIcon: {
    color: '#888',
    cursor: 'pointer',
    fontWeight: 'bold',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ddd',
    '&:hover': {
      background: '#bbb'
    }
  }
}));
