import {
  Avatar,
  Button,
  Box,
  Divider,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  ListSubheader
} from '@material-ui/core';
import { LogoutOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const InnerProfileMenu = ({handleLogOut, goProfile, avatarImage}) => {
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  return (
    <>
      <ListSubheader key="header" style={{ display: 'flex' }}>
        <Box pr={2}>
          <Avatar src={avatarImage} />
        </Box>
        <Box style={{display: "flex", flexDirection: "column", alignItems: "left"}}>
          <Typography variant="body2">
           {dataLogin?.full_name}
          </Typography>
          <Typography variant="caption">{dataLogin?.role?.title}</Typography>
          <Box pt="6px" pb="12px">
            <Button variant="contained" color="secondary"  onClick={goProfile}
            >
              Thông tin tài khoản
            </Button>
          </Box>
        </Box>
      </ListSubheader>
      <Divider />
      <MenuItem key="logout" onClick={handleLogOut}>
        <ListItemIcon>
          <LogoutOutlined/>
        </ListItemIcon>
        <ListItemText primary="Log out" />
      </MenuItem>
    </>
  );
};

export default InnerProfileMenu;
